import { FC, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { GifLoader } from '@storybook';
import { useGetConfigDoc, useSetConfigDoc,configDocTitleState, isDocumentLoadedState, SuccessScreen} from 'views';
import { useNetwork } from 'hooks';
import { CONFIGURE_COMPLETE_MESSAGE } from 'constant';
import { isShowSuccessInvitation } from 'states';

import { AddRecipients, prepareTypeState, usePrepareOverlay } from '.';

interface IPrepareDashboard {
	from: 'envelope' | 'overlay';
	envelopeId?: string;
	templateId?: string;
}

export const PrepareDashboard: FC<IPrepareDashboard> = ({ from, envelopeId, templateId }) => {
	const isDocumentLoaded = useRecoilValue(isDocumentLoadedState);
	const configTitle = useRecoilValue(configDocTitleState);
	const setPrepareType = useSetRecoilState(prepareTypeState);
	const invitationSuccess = useRecoilValue(isShowSuccessInvitation);

	const { fetchEnvelope, fetchTabMenuItems } = useSetConfigDoc();
	const { id: paramId } = useParams();
	const { docTemplates } = useGetConfigDoc();
	const { fetchOverlayTemplate } = usePrepareOverlay();
	const { apiHostExists } = useNetwork();

	useEffect(() => {
		if (apiHostExists) {
			if (from === 'envelope') {
				if (!docTemplates?.data[0]?.images?.length && (paramId || envelopeId)) {
					fetchEnvelope(paramId ?? envelopeId);
					fetchTabMenuItems();
					setPrepareType('envelope');
				}
			} else {
				if (paramId || templateId) {
					fetchOverlayTemplate((paramId ?? templateId) as string);
					setPrepareType('overlay');
				}
			}	
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiHostExists]);

	if (!configTitle?.name || !isDocumentLoaded) {
		return (
			<div className="Esign-dashboard__loader-wrapper">
				<GifLoader type="gif" dimension={60} />
			</div>
		);
	}

	if (invitationSuccess) {
		return <SuccessScreen message={CONFIGURE_COMPLETE_MESSAGE.INVIATATION_SENT} />
	}
	return (
		<div className="prepare-container">
			<AddRecipients
				header="Upload a document and add recipients"
				className="prepare-dashboard__body"
			/>
		</div>
	);
};
