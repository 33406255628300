import type { ISideDrawer } from './type';
import type { IKeyPress } from 'types';

import { createRef, FC, useCallback, useEffect } from 'react';

import { useOutsideClick } from 'hooks';

export const SideDrawer: FC<ISideDrawer> = ({
	visible,
	setVisible,
	body,
	showClose,
}) => {
	const ref = createRef<HTMLDivElement>();

	useOutsideClick(ref, () => {
		setVisible(false);
	});

	const escFunction = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(event: IKeyPress | any) => {
			if (event.key === 'Escape') {
				setVisible(false);
			}
		},
		[setVisible]
	);

	useEffect(() => {
		document.addEventListener('keydown', escFunction, false);
		return () => {
			document.removeEventListener('keydown', escFunction, false);
		};
	}, [escFunction]);

	return (
		<div ref={ref}>
			<div
				className={`esign-overlay ${visible ? '.esign-overlay--show' : ''}`}
				onClick={() => setVisible(false)}
			/>
			<div className={`esign-drawer ${visible ? 'esign-drawer--open' : ''}`}>
				{showClose && (
					<div className="esign-drawer__close-btn" onClick={() => setVisible(false)}>
						<i className="ri-close-line" />
					</div>
				)}
				<div className="esign-drawer__body">{body}</div>
			</div>
		</div>
	);
};
