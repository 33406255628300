import { FC, useCallback } from "react";

import { ReactSwitch } from "@storybook";
import { useGetConfiguredNode, useSetConfigFieldNode } from "views";

interface IToggleComponent {
  label: string;
  name: string;
  defaultValue: boolean;
}

export const ToggleComponent: FC<IToggleComponent> = ({ label, name }) => {
  const { handleConfigureNode } = useSetConfigFieldNode();
  const { configuredNode } = useGetConfiguredNode();

  const handleSwitch = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (checked: any) => {
      handleConfigureNode(name, checked);
    },
    [handleConfigureNode, name]
  );

  const value = configuredNode(name);

  return (
    <div className="esign-toggle-switch">
      <ReactSwitch id={""} checked={value} handleChange={handleSwitch} />
      <div className="esign-toggle-switch__label">{label}</div>
    </div>
  );
};
