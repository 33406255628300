import { useCallback } from 'react';

import { useRecoilState } from 'recoil';
import { v4 } from 'uuid';
import {
	IRecipientUser,
	RecipientLocalState,
	NoRecipient,
	RecipientsDraggable,
	CustomDragLayer,
} from 'views';

export const RecipientsDragAndDrop = () => {
	const [localRecipients, setLocalRecipients] =
		useRecoilState(RecipientLocalState);
	const moveRecipients = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			setLocalRecipients((prevRecipients) => {
				const clonedRecipients = JSON.parse(
					JSON.stringify(prevRecipients)
				) as IRecipientUser[];
				clonedRecipients.splice(dragIndex, 1);
				clonedRecipients.splice(
					hoverIndex,
					0,
					prevRecipients[dragIndex] as IRecipientUser
				);
				return clonedRecipients;
			});
		},
		[setLocalRecipients]
	);

	return (
		<div className="add-recipient__recipient-list">
			<CustomDragLayer />
			<div className="add-recipient__dropContainer">
				{localRecipients.length > 0 ? (
					localRecipients.map((recipient, index) => (
						<RecipientsDraggable
							id={recipient.id || v4()}
							recipient={recipient}
							index={index}
							key={recipient.id}
							moveRecipients={moveRecipients}
						/>
					))
				) : (
					<div className="add-recipient__no-recipient">
						<NoRecipient />
					</div>
				)}
			</div>
		</div>
	);
};
