import { FC, FocusEventHandler, useCallback } from "react";

import Select, { GetOptionLabel, components } from "react-select";
import { useRecoilValue } from "recoil";
import "./dropdown.scss";
import { organizationDetailState } from "states";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type IOption = { label: string; value: any; icon?: JSX.Element };

export interface IReactDropdown {
  handleChangeSelect?: (event: IOption) => void;
  options: IOption[];
  value?: IOption;
  defaultValue?: IOption;
  isSearchable?: boolean;
  isMulti?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createStyle?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  controlStyle?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  menuStyle?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  menuListStyle?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueContainerStyle?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  indicatorsContainerStyle?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  multiValueStyle?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  multiValueRemoveStyle?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  optionsStyle?: any;
  Isdisable?: boolean;
  placeholder?: string;
  handleBlur?: FocusEventHandler<HTMLInputElement>;
  menuPlacement?: "top" | "bottom";
  id?: string;
  customBtn?: string | JSX.Element;
  customBtnHandler?: () => void;
  commonFieldBtn?: string | JSX.Element;
  commonFieldBtnHandler?: () => void;
  getOptionLabel?: GetOptionLabel<IOption>;
}

export const ReactDropdown: FC<IReactDropdown> = ({
  handleChangeSelect,
  options,
  value,
  defaultValue,
  isSearchable = false,
  isMulti = false,
  createStyle,
  controlStyle = {},
  menuStyle = {},
  menuListStyle = {},
  valueContainerStyle = {},
  indicatorsContainerStyle = {},
  multiValueStyle = {},
  multiValueRemoveStyle = {},
  optionsStyle = {},
  Isdisable,
  placeholder = "Select",
  handleBlur,
  menuPlacement = "bottom",
  id = "",
  customBtn,
  customBtnHandler = () => ({}),
  commonFieldBtn,
  commonFieldBtnHandler = () => ({}),
  getOptionLabel = (option: IOption) => option.label,
}) => {
  // white labeling background color state
  const { settings: { brandColor } } = useRecoilValue(organizationDetailState);

  const customStyle = {
    control: (styles: { [key: string]: number | string }) => ({
      ...styles,
      backgroundColor: "#f5f8ff",
      minHeight: 52,
      border: "1px solid rgba(215, 223, 245, 1)",
      borderRadius: 8,
      minWidth: "unset",
      fontWeight: "500",
      color: "#fff",
      boxShadow: "none",
      "&:hover": {
        cursor: "pointer",
        borderColor: "#ced2de",
      },
      "&:focus": {
        borderColor: "red",
      },
      ...controlStyle,
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    menu: (styles: { [key: string]: any }) => ({
      ...styles,
      background: "#f5f8ff",
      borderRadius: 8,
      border: "1px solid #ced2de",
      zIndex: 3000000,
      minWidth: "unset",
      ...menuStyle,
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    menuList: (styles: { [key: string]: any }) => ({
      ...styles,
      minWidth: "unset",
      ...menuListStyle,
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    multiValue: (styles: { [key: string]: any }) => ({
      ...styles,
      backgroundColor: "rgba(224, 233, 255, 1)",
      color: "rgba(47, 50, 61, 1)",
      borderRadius: 50,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 4,
      minWidth: "unset",
      paddingBottom: 4,
      margin: 4,
      ...multiValueStyle,
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    multiValueRemove: (styles: any) => ({
      ...styles,
      color: "rgba(163, 177, 214, 1)",
      borderRadius: 50,
      marginLeft: 2,
      fontSize: 12,
      ...multiValueRemoveStyle,
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    option: (styles: { [key: string]: any }) => {
      return {
        ...styles,
        backgroundColor: "#f5f8ff",
        color: "#000",
        height: 52,
        minWidth: "unset",
        cursor: "pointer",
        paddingTop: 12,
        paddingBottom: 12,
        borderBottom: "1px solid #ced2de",
        fontWeight: "500",
        "&:hover": {
          color: "#fff",
          backgroundColor: brandColor || '#3c65d6',
        },
        "&:last-child": {
          borderBottom: "none",
        },
        ...optionsStyle,
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    valueContainer: (provided: any) => ({
      ...provided,
      textOverflow: "ellipsis",
      overflowY: "auto",
      display: "flex",
      maxHeight: "130px",
      ...valueContainerStyle,
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    indicatorsContainer: (provided: any) => ({
      ...provided,
      display: "flex",
      ...indicatorsContainerStyle,
    }),
  };

  const handleClickCustomBtn = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (props: any) => {
      customBtnHandler();
      return props.selectProps.changeOptionsData;
    },
    [customBtnHandler]
  );

  // handler for common field button
  const handleClickCommonFieldBtn = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (props: any) => {
      commonFieldBtnHandler();
      return props.selectProps.changeOptionsData;
    },
    [commonFieldBtnHandler]
  );

// eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Menu = (props: any) => {
    return (
      <>
        <components.Menu {...props}>
          <div>
            <div>{props.children}</div>
          {/* render common field button */}
            {commonFieldBtn ? (
              <div
                className="dropdown__custom-btn"
                onClick={() => handleClickCommonFieldBtn(props)}
              >
                {commonFieldBtn}
              </div>
            ) : (
              <></>
            )}
            {customBtn ? (
              <div
                className="dropdown__custom-btn"
                onClick={() => handleClickCustomBtn(props)}
              >
                {customBtn}
              </div>
            ) : (
              <></>
            )}
          </div>
        </components.Menu>
      </>
    );
  };

  return (
    <Select
      isDisabled={Isdisable}
      onBlur={handleBlur}
      defaultValue={defaultValue}
      isMulti={isMulti}
      value={value}
      placeholder={placeholder}
      menuPlacement={menuPlacement}
      className="dropdown"
      styles={createStyle ? createStyle : customStyle}
      options={options}
      isClearable={false}
      closeMenuOnSelect={!isMulti}
      isSearchable={isSearchable}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={handleChangeSelect as any}
      getOptionLabel={getOptionLabel}
      components={{
        IndicatorSeparator: () => null,
        Menu,
      }}
      id={id}
    />
  );
};
