import type { IInput } from "./types";
import type { IInputChange } from "types";

import { useCallback, useState } from "react";

import { Button } from "@storybook";

import "./input.scss";

export const Input = ({
  label,
  inputType,
  id,
  placeholder,
  handleChange = () => ({}),
  value,
  handleKeyPress,
  allowClear = false,
  handleClearField,
  isError = false,
  errorMessage,
  isShowButton,
  handleClickBtn,
  handleBlur = () => ({}),
  prefixIcon,
  disabled = false,
  suffixIcon,
  handleSuffixIcon,
  autoComplete,
  inputName,
  maxLength,
  autoFocus = false,
  handleFocus = () => ({}),
  required = false,
  validationTrigger,
}: IInput) => {
  const [defaultError, setDefaultError] = useState("");
  const handleChangeinComponent = useCallback(
    (e: IInputChange) => {
      if (
        (validationTrigger === "change" || validationTrigger === "all") &&
        required
      ) {
        if (!e.target.value) {
          setDefaultError(errorMessage || label + " is required");
        } else if (defaultError) {
          setDefaultError("");
        }
      }
      if (typeof handleChange == "function") {
        handleChange(e);
      }
    },
    [
      defaultError,
      errorMessage,
      handleChange,
      label,
      required,
      validationTrigger,
    ]
  );
  const handleBlurinComponent = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (
        (validationTrigger === "blur" || validationTrigger === "all") &&
        required
      ) {
        if (!e.target.value) {
          setDefaultError(label + " is required");
        } else if (defaultError) {
          setDefaultError("");
        }
      }
      if (typeof handleBlur == "function") {
        handleBlur(e);
      }
    },
    [defaultError, handleBlur, label, required, validationTrigger]
  );
  return (
    <div className="input">
      {label && (
        <label htmlFor={id} className="input__label">
          {label}
          {required ? (
            <span title="required" className="input__label__required">
              *
            </span>
          ) : (
            ""
          )}
        </label>
      )}
      <div className="input__value-container">
        <div className="input__group">
          <div
            className={`input__text-field input__text-field${
              isError ? "--error" : ""
            }`}
          >
            {prefixIcon && <i className={prefixIcon} />}
            <input
              placeholder={placeholder}
              type={inputType}
              name={inputName}
              onBlur={handleBlurinComponent}
              id={id}
              onFocus={handleFocus}
              className="input__field"
              onChange={handleChangeinComponent}
              value={value}
              onKeyPress={handleKeyPress}
              disabled={disabled}
              autoComplete={autoComplete}
              maxLength={maxLength}
              autoFocus={autoFocus}
            />
            {allowClear && (
              <i
                className="ri-close-line input__delete"
                onClick={handleClearField}
              />
            )}
            {suffixIcon && (
              <i
                className={`${suffixIcon} input__view`}
                onClick={handleSuffixIcon}
              />
            )}
          </div>
          {isShowButton && (
            <Button
              label="Add"
              handleClick={handleClickBtn}
              className="esign-button__outline esign-button__large"
            />
          )}
        </div>

        {(defaultError || (isError && errorMessage)) && (
          <span className="input__error">{errorMessage || defaultError}</span>
        )}
      </div>
    </div>
  );
};
