import { DragEvent, useCallback, useMemo } from "react";

import { Button } from "@storybook";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { convertFileToBase64 } from "utils";
import { SignDocSelector } from "views";
import { useNotification } from "hooks";
import { MESSAGE } from "constant";

import { CreateSignState, UploadSignState } from "../store";

export const UploadSign = () => {
	const [images, setImage] = useRecoilState(UploadSignState);
	const setSignature = useSetRecoilState(CreateSignState);
	const { selectedNode } = useRecoilValue(SignDocSelector);
	const { errorNotification } = useNotification();
	const { SIGNATURE_UPLOAD_MESSAGE } = MESSAGE;

	const handleChange = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async (event: any, dragDrop?: boolean) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const file: string | any = !dragDrop ? event.target.files[0] : event;
			if (file.size <= 10000000) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const images: any = await convertFileToBase64(file);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				setSignature((prev: any) => {
					const prevState = JSON.parse(JSON.stringify(prev));
					if (selectedNode?.type === 'signature' || selectedNode?.fieldType === "signature") {
						prevState.upload.sign = images;
					} else {
						prevState.upload.initials = images;
					}
					return prevState;
				});
				setImage(images);
				return;
			} else {
				errorNotification(SIGNATURE_UPLOAD_MESSAGE);
			}
		},
		[SIGNATURE_UPLOAD_MESSAGE, errorNotification, selectedNode?.fieldType, selectedNode?.type, setImage, setSignature]
	);

  const renderImages = useMemo(() => {
    return (
      <img
        className=""
        id="image-upload"
        src={images}
        alt="pdfImage"
        style={{
          width: "100%",
          height: "100%",
        }}
      />
    );
  }, [images]);

	const handleRemove = useCallback(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		setSignature((prev: any) => {
			const ClonedPrev = JSON.parse(JSON.stringify(prev));
			ClonedPrev.upload = {
				sign: '',
				initials: '',
			};
			return ClonedPrev;
		});
		setImage('');
	}, [setImage, setSignature]);

  const handleDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);

	const handleDrop = useCallback(
		(e: DragEvent<HTMLDivElement>) => {
			e.preventDefault();
			e.stopPropagation();
			const files = Array.from(e.dataTransfer.files)[0];
			handleChange(files, true);
		},
		[handleChange]
	);
  
	return (
		<div className="upload-sign__wrapper" onDragOver={handleDragOver} onDrop={handleDrop}>
			{!images.length ? (
				<>
					<label className="upload-sign" htmlFor="upload-sign-image">
						<div className="upload-sign__sign-box">
							<i className="ri-file-upload-line upload-sign__icon" />
							<div className="upload-sign__upload-text">
								Drag and drop here or click to upload
							</div>
							<div className="upload-sign__upload-description">
								Supported file format: JPG, PNG Max file size 1MB.
							</div>
						</div>
					</label>
					<input
						type="file"
						accept=".png,image/png,image/jpeg"
						onChange={handleChange}
						name=""
						className="upload-sign__input"
						id="upload-sign-image"
					/>
				</>
			) : (
				<div className="upload-sign__sign-img">
					<div className="upload-sign__render-img">{renderImages}</div>
					<Button
						label={'Remove'}
						className="esign-button__filled esign-button__filled--danger esign-button__small"
						handleClick={handleRemove}
					/>
				</div>
			)}
		</div>
	);
};
