import { useCallback, useEffect, useMemo } from 'react';

import { ASSETS } from 'constant';
import { useRecoilState, useRecoilValue } from 'recoil';
import { b64ImageFromString } from 'utils';
import { IsFontsLoadedState } from 'views';

import {
	CreateSignState,
	GenerateSignImagesState,
	ICreateSignState,
} from '../store';

export const GenerateSign = () => {
	const [signature, setSignature] = useRecoilState(CreateSignState);
	const isFontsLoaded = useRecoilValue(IsFontsLoadedState);
	const signatureFamilies = useRecoilValue(GenerateSignImagesState);
	const selectedID = signature.generate.id;
	const { SIMPICI_SIGN_SVG } = ASSETS;

	useEffect(() => {
		const item = signatureFamilies[0];
		setSignature((prev: ICreateSignState) => {
			const ClonedPrev = JSON.parse(JSON.stringify(prev));
			ClonedPrev.generate = {
				...ClonedPrev.generate,
				sign: item?.name,
				initials: item?.initial,
				id: item?.id,
			};
			return ClonedPrev;
		});
	}, [setSignature, signatureFamilies]);

	const handleClick = useCallback(
		(item: { name: string; initial: string; id: string }): void => {
			setSignature((prev: ICreateSignState) => {
				const ClonedPrev = JSON.parse(JSON.stringify(prev));
				ClonedPrev.generate = {
					...ClonedPrev.generate,
					sign: item.name,
					initials: item.initial,
					id: item.id,
				};
				return ClonedPrev;
			});
		},
		[setSignature]
	);

	/**
	 * logs added for production debugging
	 * */ 
	useEffect(() => {
		if (signatureFamilies) {
			// eslint-disable-next-line no-console
			console.log("signatureFamilies", signatureFamilies);		
		}
	},[signatureFamilies])
	

	const renderGenerateData = useMemo(
		() =>
			signatureFamilies.map(({ name, initial, id }, index: number) => (
				<div
					key={`${id}-${index}`}
					className={`esign-generate-sign__sign-samples esign-generate-sign__sign-samples${
						id === selectedID ? '--active' : ''
					}`}
					onClick={() => handleClick({ initial, name, id })}
				>
					{id === selectedID && (
						<div className="esign-generate-sign__checkbox">
							<i
								style={{ color: '#33B87A' }}
								className="ri-checkbox-circle-fill"
							></i>
						</div>
					)}
					<div className="esign-generate-sign__sign-samples__name-box">
						<div className="esign-generate-sign__full_name">
							<span>
								<img src={SIMPICI_SIGN_SVG} alt="" />
							</span>
							<img
								style={{ maxWidth: 200, height: 28, objectFit: 'fill' }}
								src={b64ImageFromString(name)}
								alt="fullName"
							/>
						</div>
						<div className="esign-generate-sign__initials">
							<span>
								<img src={SIMPICI_SIGN_SVG} alt="" />
							</span>
							<img
								style={{ maxWidth: 36, height: 24, objectFit: 'contain' }}
								src={b64ImageFromString(initial)}
								alt="Initial"
							/>
						</div>
					</div>
				</div>
			)),
		[signatureFamilies, selectedID, SIMPICI_SIGN_SVG, handleClick]
	);

	const skeletonLoader = useMemo(
		() => (
			<>
				{[1, 2, 3, 4, 5, 6].map((value) => (
					<div className="create-sign__loader-light" key={value}></div>
				))}
			</>
		),
		[]
	);
	return (
		<div className="esign-generate-sign">
			<div className="esign-generate-sign__heading">Select style</div>
			<div className="esign-generate-sign__wrapper">
				<div className="esign-generate-sign__container">
					{isFontsLoaded ? renderGenerateData : skeletonLoader}
				</div>
			</div>
		</div>
	);
};
