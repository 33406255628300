import type { IAtoms } from 'types';

import { useCallback, useEffect, useRef, useState } from 'react';

import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';
import { useParams } from 'react-router-dom';
import { useNetwork, useNotification } from 'hooks';
import { API_URL } from 'constant';
import {
	CompiledSigningNodesState,
	currentClickedNodeState,
	DropItemsState,
	IDropedItems,
	IFieldNodeType,
	IInitialDetail,
	IRecipient,
	ISignature,
	ISignNodeState,
	ITab,
	PrepareDashboardState,
	SignDocSelector,
	SignDocumentState,
	useSetSignDoc,
} from 'views';
import { convertFileToBase64 } from 'utils';
import { MESSAGE } from 'constant';
import { EnvelopePurposeState, webComponentEnvelopeIdState } from 'states';
import { selfSignSelector } from 'views/self-sign/store';

import {
	activeTabState,
	CreateSignState,
	ICreateSignPayload,
	IsCreateSignModalOpen,
	PreserveCreateSignAPIState,
	selfSignNodeType,
	SignPadColorState,
	SignPadDesignState,
	TimeoutHandle,
	UploadSignState,
} from '../';

export const useTimeout = (
	callback: () => void
): ((delay: number | null) => void) => {
	const savedCallback = useRef(callback);
	const timeoutRef = useRef<TimeoutHandle | null>(null);

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	const set = (delay: number | null): void => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}

		if (delay === null) {
			return;
		}

		timeoutRef.current = setTimeout(() => {
			savedCallback.current();
		}, delay);
	};

	return set;
};

export const useCreateSign = () => {
	const [{ fullName, initials }, setCreateSign] =
		useRecoilState(CreateSignState);
	const { fetchSignFonts } = useSetSignDoc();
	const previousValue = useRef({
		fullName: fullName,
		initials: initials,
	});
	const getSigns = useCallback(() => {
		setCreateSign((prev) => {
			const ClonedPrev = JSON.parse(JSON.stringify(prev));
			ClonedPrev.generate = {
				id: '',
				sign: '',
				initials: '',
			};
			return ClonedPrev;
		});
		fetchSignFonts(fullName, initials);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fullName, initials]);
	const startGetSign = useTimeout(getSigns);
	useEffect(() => {
		if (
			fullName &&
			initials &&
			(previousValue.current.fullName !== fullName ||
				previousValue.current.initials != initials)
		) {
			startGetSign(2000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fullName, initials]);

	const handleChangeCreateSign = useCallback(
		// TODO @Manish remove this any type from here
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(name: string, value: any) => {
			const previousValue: { [key: string]: string } = {
				fullName: '',
				initials: '',
			};
			setCreateSign((prev) => {
				previousValue.fullName = prev.fullName;
				previousValue.initials = prev.initials;
				const prevState = JSON.parse(JSON.stringify(prev));
				prevState[name] = value;
				return prevState;
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return { handleChangeCreateSign };
};

export const useResetCreateSign = () => {
	const resetPenColor = useResetRecoilState(SignPadColorState);
	const resetModalOpen = useResetRecoilState(IsCreateSignModalOpen);
	const resetCreatedSignDesign = useResetRecoilState(SignPadDesignState);
	const setSignForm = useSetRecoilState(CreateSignState);
	const resetUploadImage = useResetRecoilState(UploadSignState);
	const resetActiveTab = useResetRecoilState(activeTabState);
	const resetCreateSignState = useRecoilValue(PreserveCreateSignAPIState);
	const setCreateSign = useSetRecoilState(CreateSignState);
	const { fetchSignFonts } = useSetSignDoc();

	const reset = useCallback(() => {
		resetModalOpen();
		setTimeout(() => {
			resetActiveTab();
			resetCreatedSignDesign();
			setCreateSign((prev) => ({
				...prev,
				fullName: resetCreateSignState?.fullName ?? '',
				initials: resetCreateSignState?.initials ?? '',
			}));
			fetchSignFonts(resetCreateSignState?.fullName, resetCreateSignState?.initials);
			setSignForm((prev) => ({
				...prev,
				generate: {
					id: '',
					sign: '',
					initials: '',
				},
				draw: {
					sign: '',
					initials: '',
					canvasDataCoordinates: [],
				},
				upload: {
					sign: '',
					initials: '',
				},
				consent: false,
			}));
			resetUploadImage();
			resetPenColor();
		}, 500);
	}, [resetModalOpen, resetActiveTab, resetCreatedSignDesign, setCreateSign, setSignForm, resetUploadImage, resetPenColor, resetCreateSignState?.fullName, resetCreateSignState?.initials]);

	return { reset };
};

export function hasKey<O>(obj: object, key: PropertyKey): key is keyof O {
	return key in obj;
}

export const useSetCreateSign = () => {
	const webComponentEnvelopeId = useRecoilValue(webComponentEnvelopeIdState);
	const setSignDocState = useSetRecoilState(SignDocumentState);
	const setSigningNode = useSetRecoilState(CompiledSigningNodesState);
	const { nodeId: currentNodeId } = useRecoilValue(currentClickedNodeState);
	const purpose = useRecoilValue(EnvelopePurposeState);
	const { selfSigner } = useRecoilValue(selfSignSelector);
	const fieldType = useRecoilValue(selfSignNodeType);
	const { API_ERROR_MESSAGE } = MESSAGE;

	const { isEdit } = useRecoilValue(CreateSignState);
	const { recipient: currentRecipient, currentRecipientIndex } =
		useRecoilValue(SignDocSelector);
	const setSelfSignRecipientDetails = useSetRecoilState(PrepareDashboardState);
	const setDropItems = useSetRecoilState(DropItemsState);

	const [isLoading, setIsLoading] = useState(false);

	const { post, remove, patch } = useNetwork();
	const { errorNotification, successNotification } = useNotification();

	const { id } = useParams();

	const envelopeId = webComponentEnvelopeId || id;

	const getInitialOrSignatureBase64 = useCallback(
		async (initialOrSign: IInitialDetail): Promise<IInitialDetail> => {
			try {
				const response = await fetch(initialOrSign?.document?.path);
				const image = await response.blob();
				const base64 = (await convertFileToBase64(image)) as string;
				const document = { ...initialOrSign.document, path: base64 };
				return {
					...initialOrSign,
					document,
				};
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);
				return initialOrSign;
			}
		},
		[]
	);

	const submitSign = useCallback(
		// TODO @Manish create type for payload as well and remove any from here
		async (payload: Partial<ICreateSignPayload>): Promise<boolean> => {
			setIsLoading(true);
			const { _id: signatureId } =
				purpose === 'selfsign'
					? selfSigner?.signature || {}
					: currentRecipient?.signature || {};

			const service = isEdit
				? patch(`${API_URL.SIGNATURE}/${signatureId}`, payload)
				: post(`${API_URL.SIGNATURE}`, payload);

			const resp = await service;
			const { apiData, response } = resp ?? {};
			if (response?.status === 200) {
				const { initialDetail, signDetail, _id }: ISignature =
					apiData.data ?? {};
				const [initialWithBase64, signatureWithBase64] = await Promise.all([
					getInitialOrSignatureBase64(initialDetail),
					getInitialOrSignatureBase64(signDetail),
				]);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const userBioMetrics: any = {
					initial: initialWithBase64,
					signature: signatureWithBase64,
				};
				const signature = {
					initialDetail: initialWithBase64,
					signDetail: signatureWithBase64,
					_id,
				};
				if (purpose === 'selfsign') {
					// setting the sign and initial details in the
					setSelfSignRecipientDetails((prev) => {
						const prevState = structuredClone(prev);
						if (prevState?.recipients?.[0]) {
							prevState.recipients[0].signature = signature;
						}
						return prevState;
					});
					setDropItems((prev) => {
						const prevState = structuredClone(prev);
						return prevState.map((dropItem: IDropedItems) => {
							if (dropItem.fieldType === 'signature') {
								return {
									...dropItem,
									value: {
										path: signature.signDetail?.document?.path ?? '',
										id: signature.signDetail?._id,
									},
								};
							}
							if (dropItem.fieldType === 'initial') {
								return {
									...dropItem,
									value: {
										path: signature.initialDetail?.document?.path ?? '',
										id: signature.initialDetail?._id,
									},
								};
							}
							return dropItem;
						});
					});
				} else {
					// setting the initial and signature in the master recoil state
					setSignDocState((prev) => {
						const prevState = JSON.parse(JSON.stringify(prev)) as IAtoms<
							ISignNodeState | Record<string, never>
						>;
						const { recipients = [] } = prevState.data ?? {};
						if (currentRecipient) {
							recipients.splice(currentRecipientIndex, 1, {
								...currentRecipient,
								signature: {
									...currentRecipient.signature,
									...signature,
								},
							});
						}
						return prevState;
					});
					setSigningNode((prev) => {
						const prevState = JSON.parse(JSON.stringify(prev));
						prevState.forEach((node: ITab) => {
							if (node._id === currentNodeId) {
								node.isShow = true;
								node.value = userBioMetrics[node?.type]._id;
							}
						});
						return prevState;
					});
				}
				successNotification(
					`${fieldType === 'initial' ? 'Initial' : ''} ${
						fieldType === 'signature' ? 'Signature' : ''
					} ${isEdit ? 'Updated' : 'Created'} Successfully.`
				);
				setIsLoading(false);
				return true;
			}
			errorNotification(apiData?.message ?? API_ERROR_MESSAGE);
			setIsLoading(false);
			return false;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			currentNodeId,
			currentRecipient,
			currentRecipientIndex,
			fieldType,
			getInitialOrSignatureBase64,
			isEdit,
			purpose,
			selfSigner?.signature,
		]
	);

	const handleDeleteSignature = useCallback(
		async (
			biometricType: IFieldNodeType,
			recipientId?: string
		): Promise<boolean> => {
			const type =
				biometricType === 'signature' ? 'signDetail' : 'initialDetail';
			const currentRecipientId = recipientId ?? currentRecipient._id;
			const resp = await remove(
				`${API_URL.SIGNATURE}/${envelopeId}?currentRecipientId=${currentRecipientId}&type=${type}`
			);
			const { response, apiData } = resp;
			if (response.status === 200) {
				// removing the signing/initial from the recipients details in the master recoil for the envelop
				setSignDocState((prev) => {
					const prevState = structuredClone(prev);
					if (currentRecipientIndex > -1) {
						prevState.data.recipients.splice(currentRecipientIndex, 1, {
							...currentRecipient,
							signature: {
								...currentRecipient.signature,
								[type]: null,
							},
						} as IRecipient);
					}
					return prevState;
				});

				// changing the signed status of node from isShow true => false in all the tabs(initial | signature)
				setSigningNode((prev) => {
					const prevState = [...prev].map((node) => {
						if (node.isShow !== undefined && node.type === biometricType) {
							return { ...node, isShow: false, value: '' };
						}
						return node;
					});
					return prevState;
				});
				return true;
			}
			errorNotification(apiData?.message ?? API_ERROR_MESSAGE);
			return false;
		},
		[
			API_ERROR_MESSAGE,
			currentRecipient,
			currentRecipientIndex,
			envelopeId,
			errorNotification,
			remove,
			setSignDocState,
			setSigningNode,
		]
	);

	return { submitSign, isLoading, handleDeleteSignature };
};
