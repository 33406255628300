/* eslint-disable no-console */
import { useCallback, useMemo } from 'react';

/**
 * Importing necessary dependencies and components
 * */ 
import { Button } from '@storybook';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  IsSigningOrderModalOpen,
  RecipientLocalState,
  SenderDetailsState,
  completedImage,
  isSigningOrderEnabledState,
} from "views";
import { FadeHexColor } from 'utils';

/**
 * Define the SigningOrder functional component
 * */ 
export const SigningOrder = () => {
	/**
	 * Retrieve the state and setup functions
	 * */ 
	/**
	 * Get the state for enabling/disabling the signing order
	 * */ 
	const isSigningOrderEnabled = useRecoilValue(isSigningOrderEnabledState);
	/**
	 * Set the modal state to open/close the signing order modal
	 * */ 
	const setIsModalOpen = useSetRecoilState(IsSigningOrderModalOpen);
	/**
	 * Retrieve a list of recipients
	 * */ 
	const recipientList = useRecoilValue(RecipientLocalState);
	/**
	 * Retrieve the sender's full name, with a fallback of 'SS' if not available
	 * */ 
	const { fullName: senderName } = useRecoilValue(SenderDetailsState) || {};
	/**
	 * Define a function to handle the primary button click to close the signing order modal
	 * */ 
	const handlePrimaryClick = useCallback(() => {
		setIsModalOpen(false);
	}, [setIsModalOpen]);

	/**
	 * Define a function to generate a signer name based on input data
	 * */ 

	/**
	 * This function takes the recipient's full name, index, and an optional title
	 * It returns a formatted name based on the data, with a default if necessary
	 * */
	const getSignerName = useCallback(
		(fullName: string, index: number, title?: string) => {
			if (fullName?.trim()) {
				return fullName;
			} else if (title?.trim()) {
				return title;
			} else {
				return `Signer ${index + 1}`; 
			}
		},
		[]
	);
	/**
	 * Render a list of recipients with checkboxes
	 * 
	 * This component maps through the recipientList and generates UI for each recipient
	 * It includes their number, a colored badge, and the recipient's name or a default name
	 * */ 

	const renderCheckboxRecipient = useMemo(() => {
		return recipientList.map(({ fullName, color }, index) => {
			return (
				<div className="signing-order__body-data" key={index}>
					<div className="signing-order__body-sender">{index + 1}</div>
					<div
						className="signing-order__badge"
						style={{
							background: FadeHexColor(`${color}`, 0.3),
							color: `${color || 'black'}`,
						}}
					>
						<span>{!fullName ? `Recipient-${index + 1}` : fullName}</span>
					</div>
				</div>
			);
		});
	}, [recipientList]);

	/**
	 * Render a list of recipients based on a condition
	 * 
	 * This component renders the recipients based on whether the signing order is enabled or not
	 * It includes their number, colored badge, and formatted name
	 * */ 

	const renderRecipient = useMemo(
		() => (
			<>
				<div className="signing-order__badgeData">
					<div className="signing-order__initials">
						<>
							{recipientList.map(({ color, fullName, title }, index) => {
								return (
									<div
										className="signing-order__badge"
										style={{
											background: FadeHexColor(`${color}`, 0.3),
											color: `${color || 'black'}`,
										}}
										key={index}
									>
										<span>
											{getSignerName(fullName, index, title)}
										</span>
									</div>
								);
							})}
						</>
					</div>
				</div>
			</>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[recipientList]
	);

	/**
	 * Render the main SigningOrder component
	 * 
	 * 
	 * This is the main component that displays the signing order diagram
	 * It includes the header, sender information, recipient list, completed badge, and a close button
	 * */
	
	return (
		<div className="signing-order">
			<div className="signing-order__header">Signing Order Diagram</div>
			<div className="signing-order__body">
				{/* Render sender information */}
				<div className="signing-order__body-data">
					<div className="signing-order__body-sender">Sender</div>
					<div className="signing-order__badge">
						<span>{senderName ?? 'SS'}</span>
					</div>
				</div>
				{/* Conditionally render recipients based on isSigningOrderEnabled */}
				<div className="signing-order__render-data">
					{!isSigningOrderEnabled ? renderRecipient : renderCheckboxRecipient}
				</div>
				{/* Render a completed badge */}
				<div className="signing-order__body-data signing-order__completed-badge">
					<div className="signing-order__body-sender">Completed</div>
					<img src={completedImage} alt="completed" />
				</div>
			</div>

			{/* Render a close button */}
			<div className="signing-order__footer">
				<Button
					className="esign-button__filled esign-button__filled--primary esign-button__large"
					handleClick={handlePrimaryClick}
					label="Close"
				/>
			</div>
		</div>
	);
};
