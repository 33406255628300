import { createRef, FC, useCallback, useEffect, useState } from 'react';

import { useSetRecoilState } from 'recoil';
import {
	CustomTabModal,
	CustomTabState,
	DropItemsState,
	ITabMenu,
} from 'views';

interface Props {
	item: ITabMenu;
	hovered: boolean;
}

export const HoveredMenuButtons: FC<Props> = ({ item, hovered }) => {
	const setCustomTabs = useSetRecoilState(CustomTabState);
	const setDropItems = useSetRecoilState(DropItemsState);
	const [showModal, setShowModal] = useState(false);
	const [showOption, setShowOption] = useState<boolean>(false);
	const ref = createRef<HTMLDivElement>();
	const [heightFromBody, setHeightFromBody] = useState<number>();

	//Ankur Singh: fix last two element open on top.
	useEffect(() => {
		const element = ref.current;
		if (element) {
		const rect = element.getBoundingClientRect();
		const height = rect.top + window.scrollY;
		setHeightFromBody(height);
		}
	}, [ref]);

	const handleOptions = useCallback((e: { stopPropagation: () => void }) => {
		e.stopPropagation();
		setShowOption((prev) => !prev);
		setShowModal(false);
	}, []);

	const handleDelete = useCallback(() => {
		setCustomTabs((prev) => {
			return prev.filter(({ label }) => label !== item.label);
		});
		setDropItems((prev) => {
			return prev.filter((dropItem) => dropItem.label !== item?.label);
		});
	}, [item.label, setCustomTabs, setDropItems]);

	useEffect(() => {
		if (!hovered) {
			setShowOption(false);
		}
	}, [hovered]);

	return (
		<div className="menu-tab__custom-menu-hover-container">
			{(hovered || showModal) && (
				<>
					<div ref={ref} className={'option-wrapper'}>
						<div onClick={handleOptions}>
							<i className="ri-more-2-fill"></i>
						</div>
						{showOption && (
							<div className={heightFromBody as number >= 464  ? "option-wrapper__option-box-top" : "option-wrapper__option-box" }>
								<div className="menu-tab__custom-menu-hover-container">
									<div
										className="option-wrapper__menu-item"
										onClick={() => setShowModal(true)}
									>
										<i className="ri-ball-pen-line"></i>
										Edit
									</div>
									{showModal && (
										<CustomTabModal
											closeModal={() => setShowModal(false)}
											item={item}
										/>
									)}
									<div
										className="option-wrapper__menu-item"
										onClick={handleDelete}
									>
										<i className="ri-delete-bin-5-fill"></i>
										Delete
									</div>
								</div>
							</div>
						)}
					</div>
				</>
			)}
		</div>
	);
};
