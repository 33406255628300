import { XYCoord, useDragLayer } from 'react-dnd';
import { AddRecipientForm } from 'views/reciepient-modal';

import { DragTypes, DropItem } from '..';

function getItemStyles(currentOffset: XYCoord | null) {
  if (!currentOffset) {
    return {
      display: 'none',
    };
  }
  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

export const CustomDragLayer = () => {
  const { itemType, isDragging, item, currentOffset } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),

      isDragging: monitor.isDragging(),
    })
  );

  const renderItem = () => {
    switch (itemType) {
      case DragTypes.DroppedItem:
        return (
          <DropItem
            item={item}
            index={0}
            pageId={''}
            pageWidth={0}
            pageHeight={0}
            onlyNode={true}
          />
        );

      case DragTypes.Recipient:
        return (
          <AddRecipientForm
            recipient={item?.recipient}
            key={item?.id}
            index={item?.index}
          />
        );
      default:
        return null;
    }
  };

  if (!isDragging) {
    return null;
  }

  return (
    <div
      style={{
        position: 'fixed',
        pointerEvents: 'none',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        zIndex: 1000,
      }}
    >
      <div style={getItemStyles(currentOffset)}>{renderItem()}</div>
    </div>
  );
};
