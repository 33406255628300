import { useEffect } from 'react';

import { useRecoilValue } from 'recoil';
import { GifLoader } from '@storybook';
import { EnvelopePurposeState } from 'states';
import { WebComponentMemory } from 'views/routes';

export const BackToSimplici = () => {
	const purpose = useRecoilValue(EnvelopePurposeState);

	useEffect(() => {
		setTimeout(() => {
			// eslint-disable-next-line no-console
			console.log('close the modal', purpose);
			WebComponentMemory.handleFinish(true, purpose);
		}, 1000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="Esign-back-to-simplici">
			<div className="Esign-back-to-simplici__loader">
				<GifLoader type="gif" dimension={80} />
			</div>
		</div>
	);
};
