import {
	CSSProperties,
	FC,
	KeyboardEventHandler,
	ReactElement,
	useEffect,
} from 'react';

import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { AllowedDraggableNodes, DragTypes, IDropedItems, ITabMenu } from '..';

type Props = {
	children: ReactElement;
	hideSourceOnDrag: boolean;
	data: IDropedItems | ITabMenu;
	type: DragTypes;
	styles?: CSSProperties;
	className?: string;
	tabIndex?: number;
	handleClick?: () => void;
	handleMouseEnter?: () => void;
	handleMouseLeave?: () => void;
	handleKeyDown?: KeyboardEventHandler<HTMLDivElement>;
	showEmptyPlaceholder?: boolean;
};

export const DraggableBox: FC<Props> = ({
	children,
	hideSourceOnDrag,
	data,
	type,
	showEmptyPlaceholder = false,
	styles = {},
	className = '',
	tabIndex = -1,
	handleClick = () => ({}),
	handleMouseEnter = () => ({}),
	handleMouseLeave = () => ({}),
	handleKeyDown = () => ({}),
}) => {
	const [{ isDragging }, dragRef, previewRef] = useDrag({
		type,
		item: () => {
			const draggingElement = document.getElementById(data.id);
			/**
			 * here we are restricting nodes from being dragged that do not belong certain 
			 * allowed question types.
			 * 
			 * this is done specially for the questionnaires tab. 
			 * */ 
			if (
				type === DragTypes.SideBarItem &&
				data.questionType &&
				!(data.questionType in AllowedDraggableNodes)
			) {
				return;
			}
			if (draggingElement) {
				const { offsetWidth, offsetHeight } = draggingElement;
				return { ...data, offsetWidth, offsetHeight };
			}
			return data;
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});
	useEffect(() => {
		if (showEmptyPlaceholder) {
			previewRef(getEmptyImage());
		}
	}, [previewRef, showEmptyPlaceholder]);

	if (isDragging && hideSourceOnDrag) {
		return <div id={data.id} ref={dragRef} />;
	}

	return (
		<div
			id={data.id}
			ref={dragRef}
			style={styles}
			className={className}
			onClick={handleClick}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			tabIndex={tabIndex}
			onKeyDown={handleKeyDown}
		>
			{children}
		</div>
	);
};
