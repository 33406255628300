
import type { IAtoms } from "types";
import type {
  IConfigDocument,
  IConfigItems,
  ICustomTab,
  IDropedItems,
  IMenuTab,
  IConfiguration,
  ITabMenu
} from "./types";

import { atom } from "recoil";
import { IRefsList } from "views";

export const ConfigDocumentState = atom<IAtoms<IConfigDocument[]>>({
  key: "config-doc-state-key",
  default: {
    isLoaded: true,
    isLoading: false,
    error: false,
    data: [
      {
        name: "",
        createdAt: "",
        url: "",
        path: "",
        images: [],
        _id: "",
      },
    ],
  },
});

export const EditorNodesLoadedState = atom({
  key: "editor-nodes-loaded-state-key",
  default: false,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MenuTabState = atom<any>({
	key: 'menu-tab-state-key',
	default: {},
});

export const CustomTabState = atom<ICustomTab[]>({
  key: "custom-tab-state-key",
  default: [],
});

export const QuestionaireTabState = atom<ITabMenu[]>({
  key: "questionaire-tab-state-key",
  default: [],
});

export const SearchedMenuNameState = atom<string>({
  key: "searched-menu-name-state-key",
  default: "",
});

export const ActiveMenuTabState = atom<IMenuTab>({
  key: "active-menu-tab-state-key",
  default: "custom",
});

export const DropItemsState = atom<IDropedItems[]>({
  key: "drop-items-state-key",
  default: [],
});

export const SelectedConfigNode = atom<IConfigItems | null>({
  key: "selected-config-items-state-key",
  default: null,
});

export const IsShowTandCState = atom<boolean>({
  key: "is-show-term-condition-toggle-state-key",
  default: true,
});

export const configPagesRefsState = atom<IRefsList>({
  key: "config-pages-refs-state-key",
  default: {
    current: [],
  },
});

export const undoDisabledState = atom<boolean>({
  key: "config-undo-disabled-key",
  default: true,
});
export const redoDisabledState = atom<boolean>({
  key: "config-redo-disabled-key",
  default: true,
});

export const DragStartsFromState = atom<"menu" | "config-docs">({
  key: "drag-starts-from-state-key",
  default: "menu",
});

export const IsDragEnterState = atom({
  key: "is-drag-enter-state-key",
  default: false,
});

export const SelectedPreviewPageState = atom<string>({
  key: "set-selected-preview-page-state-key",
  default: "",
});

export const TabConfigurationState = atom<IConfiguration | Record<string, boolean>>({
  key: "tab-configuration-state-key",
  default: {},
})

export const configDocTitleState = atom<{name: string, createdAt: Date | null, isEdited?: boolean}>({
  key: "config-doc-title-details-state-key",
  default: {
    name: "",
    createdAt: null,
    isEdited: false
  },
});

export const IsLoadingState = atom<boolean>({
  key: "is-save-loading-key",
  default: false,
});

export const isSearchFocused = atom<boolean>({
  key: "is-search-focused-state-key",
  default: false
})

// this state is use for get conatiner width and heigth
export const currentNodePosition = atom({
	key: 'current-node-position',
	default: {containerWidth: 0, containerHeight: 0},
});
