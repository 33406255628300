import React, {
	createRef,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';

import { Input } from '@storybook/input';
import { useOutsideClick } from 'hooks';
import { IInputChange } from 'types';
import { INameSuggestions } from 'views';
import { Skeleton } from '@storybook/skeleton-loader';

import { AutoCompleteInputProps, IEvent } from './types';

export const AutoCompleteInput: React.FC<AutoCompleteInputProps> = ({
	suggestions,
	label,
	placeholder,
	validationTrigger,
	isError,
	errorMessage,
	id,
	handleInputChange = () => ({}),
	value,
	isLoading,
	autoComplete,
	disabled = false,
}) => {
	//Ankur Singh: State hooks for managing input value, filtered suggestions, and suggestion visibility
	const [inputValue, setInputValue] = useState(value);
	const [filteredSuggestions, setFilteredSuggestions] = useState<
		INameSuggestions[]
	>([{ email: '', fullName: '' }]);
	const [showSuggestions, setShowSuggestions] = useState<boolean>(false);

	//Ankur Singh: Ref for referencing the autocomplete container 
	const ref = createRef<HTMLDivElement>();

	//Ankur Singh: State hook for storing container dimensions
	const [containerDimension, setContainerDimension] = useState({
		containerTop: 0,
		containerBottom: 0,
	});

	//Ankur Singh: Handler for input change
	const handleChange = useCallback(
		(event: IInputChange) => {
			const { value } = event.target;
			if (!value) {
				setShowSuggestions(false);
			} else {
				setShowSuggestions(true);
			}
			setInputValue(value);
			handleInputChange(event);
			
		},
		[handleInputChange]
	);

	useEffect(() => {
		const filteredSuggestions = suggestions.filter(
			(suggestion: INameSuggestions) =>
				suggestion.fullName.toLowerCase().includes(value.toLowerCase())
		);
		setFilteredSuggestions(filteredSuggestions);
	}, [suggestions, value]);

	//Ankur Singh: Effect hook to update container dimensions based on an external element
	useEffect(() => {
		const element = document.getElementById(
			'add-recipient__recipient-list-data'
		);
		if (element) {
			const { top: containerTop, bottom: containerBottom } =
				element.getBoundingClientRect();
			setContainerDimension({
				containerBottom,
				containerTop,
			});
		}
	}, []);

	//Ankur Singh: Memoized calculation for the direction of the suggestion menu (top or bottom)
	const actionElement = document.getElementById(id);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const menuTabDirection: () => 'top' | 'bottom' = () => {
		let direction: 'top' | 'bottom' = 'bottom';
		if (actionElement) {
			const { containerBottom } = containerDimension;
			const { bottom } = actionElement.getBoundingClientRect();
			if (containerBottom - bottom > 300) {
				direction = 'top';
			}
		}
		return direction;
	};

	//Ankur Singh: Handler for suggestion click
	const handleSuggestionClick = useCallback(
		(suggestion: INameSuggestions) => {
			const updatedEvent: IEvent = {
				target: {
					value: suggestion?.fullName ?? '',
					name: suggestion?.email ?? '',
				},
			};

			handleChange(updatedEvent as React.ChangeEvent<HTMLInputElement>);
			setInputValue(suggestion?.fullName);
			setShowSuggestions(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[handleChange, suggestions]
	);

	//Ankur Singh: Custom hook for detecting clicks outside the autocomplete container
	useOutsideClick(ref, () => {
		setShowSuggestions(false);
	});

	const renderSuggestions = useMemo(() => {
		if (showSuggestions) {
			return (
				<>
					{isLoading ? (
						<div
							className={`autocomplete-container__input autocomplete-container--${menuTabDirection()} suggentions-loader`}
						>
							{Array(3)
								.fill(1)
								.map((item, index: number) => {
									return (
										<div
											className="autocomplete-container__options"
											key={item + index.toString()}
										>
											<div className="autocomplete-container__options--name">
												<Skeleton width={220} height={10} />
											</div>
											<div className="autocomplete-container__options--email">
												<div className="autocomplete-container__options--email__label">
													<Skeleton width={220} height={10} />
												</div>
											</div>
										</div>
									);
								})}
						</div>
					) : (
						<div
							className={`autocomplete-container__input autocomplete-container--${menuTabDirection()}`}
							ref={ref}
						>
							<div className="autocomplete-container__box">
								{(filteredSuggestions ?? []).map(
									(suggestion: INameSuggestions) => (
										<div
											className="autocomplete-container__options"
											key={suggestion.fullName}
											onClick={() => handleSuggestionClick(suggestion)}
										>
											<div className="autocomplete-container__options--data">
												<div>
													<i className="ri-mail-line"></i>
												</div>
												<div>
													{' '}
													<div className="autocomplete-container__options--name">
														{suggestion?.fullName}
													</div>
													<div className="autocomplete-container__options--email">
														<div className="autocomplete-container__options--email__label">
															{suggestion?.email}
														</div>
													</div>
												</div>
											</div>
										</div>
									)
								)}
							</div>
						</div>
					)}
				</>
			);
		}
		return <></>;
	}, [
		filteredSuggestions,
		handleSuggestionClick,
		isLoading,
		menuTabDirection,
		ref,
		showSuggestions,
	]);

	return (
		<div className="autocomplete-container">
			<Input
				handleChange={handleChange}
				inputType="text"
				placeholder={placeholder}
				value={inputValue}
				label={label}
				validationTrigger={validationTrigger}
				isError={isError}
				errorMessage={errorMessage}
				id={id}
				autoComplete={autoComplete}
				disabled={disabled}
			/>
			{renderSuggestions}
		</div>
	);
};
