import { useCallback } from "react";

import { useRecoilValue } from "recoil";
import { Modal } from "@storybook";

import { IsCreateSignModalOpen, useResetCreateSign } from "./store";
import { CreateSign, CreateSignFooter, CreateSignHeader } from "./components";

export const CreateSignModal = () => {
  const isModalOpen = useRecoilValue(IsCreateSignModalOpen);  
  const { reset } = useResetCreateSign();

  const handleCloseModal = useCallback(() => { 
    reset();    
  }, [reset]);

  return (
    <Modal
      className="create-sign-modal"
      isOpen={isModalOpen}
      modalName=""
      showCloseBtn
      closeModal={handleCloseModal}
    >
      <CreateSignHeader />
      <CreateSign />
      <CreateSignFooter />
    </Modal>
  );
};
