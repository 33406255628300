import type { IAtoms } from 'types';

import { atom } from 'recoil';
import {
	ISignNodeState,
	SelectedSigningNode,
	IRefsList,
	ITab,
	ICurrentClickedNode,
	ISignWebComponentData,
	IUISHOWHIDE,
} from 'views';

export const selectedNodeState = atom<SelectedSigningNode>({
	key: 'selected-node-state',
	default: {
		node: null,
		index: -1,
	},
});

export const currentClickedNodeState = atom<ICurrentClickedNode>({
	key: 'current-clicked-node-key',
	default: {
		nodeId: '',
	},
});

export const SignDocumentState = atom<
	IAtoms<ISignNodeState | Record<string, never>>
>({
	key: 'sign-document-state-key',
	default: {
		isLoaded: false,
		error: false,
		data: {},
	},
});

export const CompiledSigningNodesState = atom<ITab[]>({
	key: 'compiled-signing-doc-node-state-key',
	default: [],
});

export const nodeRefsState = atom<IRefsList>({
	key: 'node-refs',
	default: { current: [] },
});

export const signingFieldsFilledState = atom<boolean>({
	key: 'signing-fields-filled',
	default: false,
});

export const signingZoomState = atom<number>({
	key: 'signing-fields-zoom',
	default: 100,
});

export const SignWebComponentDataState = atom<ISignWebComponentData>({
	key: 'sign-envelope-id',
	default: { recipientId: '', envelopeId: '', signStatus: false, isWorkFlow: false },
});
export const SignHostState = atom<string>({ key: 'sign-host', default: '' });

export const IsFontsLoadedState = atom<boolean>({
	key: 'is-sign-fonts-loaded',
	default: false,
});

export const IAuthUserAuthType = atom<string>({
	key: 'auth-user-auth-type',
	default: '',
});

export const IsFooterLoaderBtn = atom<boolean>({
	key: 'is-footer-loder-btn',
	default: true,
});


export const CurrentUserAllTabs = atom<ITab[]>({
	key: "current-user-all-tabs-key",
	default: []
})

export const completedRecipientsTabsState = atom<ITab[]>({
	key: "completed-recipients-tabs-state-key"
})

export const uiShowHideState = atom<IUISHOWHIDE>({
	key: "ui-show-hide-state-key",
	default: {
		hideLogo: false,
		hideUser: false
	}
})
