import type { IPosition } from 'views';
import type { IDateCalenderDirection } from './type';

export const getCalenderDirection = (
	pageWidth: number,
	pageHeight: number,
	position: IPosition
): IDateCalenderDirection => {
	const modalWidth = 280;
	const modalHeight = 380;
	const { xLeft, xRight, yBottom, yTop } = position;
	const availableSpaceAtRight = pageWidth - pageWidth * xRight;
	const availableSpaceAtLeft = pageWidth * xLeft;
	const availableSpaceAtTop = pageHeight * yTop;
	const availableSpaceAtBottom = pageHeight - pageHeight * yBottom;
	const centerHorizontalSpace = pageWidth / 2 - modalWidth / 2;
	const centerVerticalSpace = pageHeight / 2 - modalHeight / 2;

	if (availableSpaceAtTop > modalHeight && availableSpaceAtRight > modalWidth) {
		return 'top-right';
	}
	if (availableSpaceAtTop > modalHeight && availableSpaceAtLeft > modalWidth) {
		return 'top-left';
	}
	if (
		availableSpaceAtBottom > modalHeight &&
		availableSpaceAtRight > modalWidth
	) {
		return 'bottom-right';
	}
	if (
		availableSpaceAtBottom > modalHeight &&
		availableSpaceAtLeft > modalWidth
	) {
		return 'bottom-left';
	}
	if (availableSpaceAtTop > modalHeight && availableSpaceAtLeft > centerHorizontalSpace && availableSpaceAtRight > centerHorizontalSpace) {
		return 'center-top';
	}
	if (availableSpaceAtBottom > modalHeight && availableSpaceAtLeft > centerHorizontalSpace && availableSpaceAtRight > centerHorizontalSpace) {
		return 'center-bottom';
	}
	if (availableSpaceAtLeft > modalWidth && availableSpaceAtTop > centerVerticalSpace && availableSpaceAtBottom > centerVerticalSpace) {
		return 'center-left';
	}
	if (availableSpaceAtRight > modalWidth && availableSpaceAtTop > centerVerticalSpace && availableSpaceAtBottom > centerVerticalSpace) {
		return 'center-right';
	}
	return 'top-right';
};
