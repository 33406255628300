import { atom } from "recoil";

export const webComponentEnvelopeIdState = atom<string>({
    key: "web-component-envelope-id-state-key",
    default: "",
})

export const webComponentRecipientIdState = atom<string>({
    key: "web-component-recipient-id-state-key",
    default: "",
})

export const webComponentTemplateIdState = atom<string>({
    key: "web-component-template-id-state-key",
    default: "",
})

export const isShowConfigDocScreen = atom<boolean>({
    key: "is-show-config-doc-screen-state-key",
    default: false,
})

export const isShowSelfSignDocScreen = atom<boolean>({
    key: "is-show-self-sign-doc-screen-state-key",
    default: false,
})

export const IsViewOnlyConfigState = atom<boolean>({
    key: "is-view-only-config-screen",
    default: false
})

export const isShowSuccessInvitation = atom<boolean>({
    key: "is-show-success-invitation-state-key",
    default: false
})