import { useCallback, useEffect, useMemo } from 'react';

import { useRecoilState, useRecoilValue } from "recoil";
import { Collapsible } from "@storybook";
import { ASSETS } from "constant";
import { IsViewOnlyConfigState } from "states";

import {
  DropItemsState,
  IConfigDocument,
  SelectedPreviewPageState,
  useGetConfigDoc,
  useSetConfigDoc,
} from "..";

export const PreviewDocument = () => {
  const dropItems = useRecoilValue(DropItemsState);
  const [selectedPage, setSelectedPage] = useRecoilState(
    SelectedPreviewPageState
  );

  const { docTemplates } = useGetConfigDoc();
  const { handleDeletePage } = useSetConfigDoc();
  const {DOC_MISSING_SVG} = ASSETS;
  const readOnly = useRecoilValue(IsViewOnlyConfigState);


  useEffect(() => {
    const defaultPreviewSelectedPage = docTemplates.data[0]?.images[0]?._id;
    if (defaultPreviewSelectedPage) {
      setSelectedPage(defaultPreviewSelectedPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemoveImage = useCallback(
    (docId: string, docIndex: number, pageIndex: number, pageNumber: number) => {
      handleDeletePage(docId, docIndex, pageIndex, pageNumber);
    },
    [handleDeletePage]
  );

  const handlePreviewClick = useCallback(
    (pageId: string) => {
      const configDocuments = document.getElementById(pageId);
      configDocuments?.scrollIntoView({ behavior: "smooth" });
      setSelectedPage(pageId);
    },
    [setSelectedPage]
  );

  const getCountPerPage = useCallback(
    (_id: string, docPageNo: number) => {
      let countsPerPage = 0;
      dropItems.forEach(({ documentId, pageNumber }) => {
        if (documentId === _id && pageNumber === docPageNo) {
          countsPerPage += 1;
        }
      });
      return countsPerPage;
    },
    [dropItems]
  );

  const renderLayouts = useCallback(
    (doc: IConfigDocument, docIndex: number) => {
      return doc.images.map(({ path, _id, pageIndex }, index: number) => (
        <div
          className={`preview-document__item ${
            selectedPage === _id ? "active" : ""
          }`}
          key={_id}
          onClick={() => handlePreviewClick(_id)}
        >
          <img
            src={path}
            alt="images"
            className="preview-document__img"
            draggable={false}
            loading="lazy"
          />
          <div className="preview-document__layout-details">
            <span className="preview-document__page-number">
              Page {index + 1}
            </span>
            {!readOnly && doc.images.length > 1 && <span
              onClick={() => handleRemoveImage(doc._id, docIndex, index, pageIndex+1)}
              className="preview-document__delete"
            >
              <i className="ri-delete-bin-5-line" />
            </span>}
            <span className="preview-document__page-number">
              Tabs {getCountPerPage(doc._id, index + 1)}
            </span>
          </div>
        </div>
      ));
    },
    [getCountPerPage, handlePreviewClick, handleRemoveImage, selectedPage, readOnly]
  );

  const PreviewDocList = useMemo(() => {
    return docTemplates.data.length ? (
      docTemplates.data.map((document: IConfigDocument, index: number) => {
        return (
          <Collapsible header={document.name} key={index}>
            {renderLayouts(document, index)}
          </Collapsible>
        );
      })
    ) : (
      <div className="preview-document__placeholder">
        <img src={DOC_MISSING_SVG} alt="" loading="lazy" />
        <span>No documents to show preview</span>
      </div>
    );
  }, [DOC_MISSING_SVG, docTemplates.data, renderLayouts]);
  
  return <div className="preview-document">{PreviewDocList}</div>;
};