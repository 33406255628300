import env from "@beam-australia/react-env";

export const REACT_APP_API_HOST = env("ESIGN_API_HOST");

export const REACT_APP_HOST_URL = env("ESIGN_HOST_URL");

// const HOST = window.location.origin + "/";

// const isStage = /stage/gi.test(HOST) || /localhost/gi.test(HOST)
// const isPP = /pp/gi.test(HOST);
// const isProd = //gi.test(HIST);

// export const host = () => {
//     if (isStage) return "https://api.stage.satschel.com/v2/esign";
//     if (isPP) return "https://api.pp.satschel.com/v2/esign";
//     return "https://api.satschel.com/v2/esign";
// }

//export const REACT_APP_API_HOST = "https://api.stage.satschel.com/v2/esign";
