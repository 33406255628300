import type { IRecipientTab, ITabObject } from './types';

import { FC, Fragment, useCallback } from 'react';

import { Tooltip } from '@storybook';
import { useRecoilState } from 'recoil';

export const RecipientTabs: FC<IRecipientTab> = ({
	children,
	state,
	totalTabs,
}) => {
	const [activeTab, setActiveTab] = useRecoilState(state);

	const handleSelect = useCallback((tab: ITabObject) => {
		const { name, disabled } = tab;
		if (!disabled) {
			setActiveTab(name);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="recipient-tab">
			<div className="recipient-tab__tabs">
				{totalTabs.map((tab: ITabObject) => (
					<Fragment key={tab.name}>
						<div
							className={`recipient-tab__item recipient-tab__item--${
								activeTab === tab.name ? 'active' : ''
							} recipient-tab__item--${tab.disabled ? 'disabled' : ''}`}
							onClick={() => handleSelect(tab)}
							role="button"
							tabIndex={0}
							id={`recipient-tab-${tab.name}-${tab.description}`}
						>
							<div className="recipient-tab__item-title">{tab.title}</div>
							<div className="recipient-tab__item-description">
								{tab.description}
							</div>
						</div>
						{tab.disabled && (
							<Tooltip
								id={`recipient-tab-${tab.name}-${tab.description}`}
								place="top"
								content="Coming soon"
							/>
						)}
					</Fragment>
				))}
			</div>
			<div className="recipient-tab__children-wrapper">{children}</div>
		</div>
	);
};
