const COLORS_AVAILABLE: string[] = [
	'#daa520',
	'#fa5ce1',
	'#9acd32',
	'#00ff00',
	'#db7093',
	'#0000ff',
	'#990066',
	'#da70d6',
	'#ff4500',
	'#ffd700',
	'#32cd32',
	'#40e0d0',
	'#00bfff',
	'#8a2be2',
	'#bc8f8f',
	'#ff6600',
	'#B5CB99',
	'#ffa07a',
	'#663333',
	'#00ff00',
	'#9370db',
	'#ee82ee',
	'#66cdaa',
	'#6495ed',
	'#FF6F61',
	'#008080'
];

let COLORS_SELECTED: string[] = [];
export const resetColorsSelected = () => {
	COLORS_SELECTED = [];
};

export const getColor = (alreadyExistColor?: string[]): string => {
	if (alreadyExistColor) {
		const getAlreadyExistColor = alreadyExistColor.filter(
			(color) => color !== 'white'
		);
		COLORS_SELECTED.length = 0; // Clearing the array for adding new color codes
		COLORS_SELECTED.push(...getAlreadyExistColor);
	}
	const color = COLORS_AVAILABLE.find(
		(_color) => !COLORS_SELECTED.includes(_color)
	);
	if (color) {
		COLORS_SELECTED.push(color);
		return color;
	}
	return 'white';
};

export const removeColor = (color: string): void => {
	const index = COLORS_SELECTED.indexOf(color);
	if (index !== -1) {
		COLORS_SELECTED.splice(index, 1);
	}
};
