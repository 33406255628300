import { useCallback } from "react";

import { useRecoilState, useSetRecoilState } from "recoil";
import { IconBtn, Tooltip, EditableTitle } from "@storybook";
import { getDate, getTime } from "utils";

import {
  DropItemsState,
  IDropedItems,
  IRefProps,
  configDocTitleState,
  redoDisabledState,
  undoDisabledState,
} from "..";

export const ConfigHeader = ({ refs }: IRefProps) => {
  const [redoDisabled, setRedoDisabled] = useRecoilState(redoDisabledState);
  const [undoDisabled, setUndoDisabled] = useRecoilState(undoDisabledState);
  const [configDocTitle, setConfigDocTitle] =
    useRecoilState(configDocTitleState);
  const setDropItems = useSetRecoilState(DropItemsState);

  const { undoPointer, changes, isUndoRedo, readOnly } = refs;

  const handleRedoClick = useCallback(() => {
    //  check is next undo pointer is less than or equal to the length
    //   of array and the value of the index isan array to set the drop items
    if (
      changes.current.length >= undoPointer.current + 1 &&
      Array.isArray(changes.current[undoPointer.current + 1])
    ) {
      isUndoRedo.current = true;
      const newDropItems = changes.current[
        undoPointer.current + 1
      ] as IDropedItems[];
      setDropItems(newDropItems);
      undoPointer.current = undoPointer.current + 1;
      //enable undo if it is disabled
      if (undoDisabled && undoPointer.current !== 0) {
        setUndoDisabled(false);
      }
      /*disable redo if current pointer points to last item in the array */
      if (!redoDisabled && undoPointer.current + 1 === changes.current.length) {
        setRedoDisabled(true);
      }
    } else {
      setRedoDisabled(true);
    }
  }, [
    changes,
    isUndoRedo,
    redoDisabled,
    setDropItems,
    setRedoDisabled,
    setUndoDisabled,
    undoDisabled,
    undoPointer,
  ]);
  const handleUndoClick = useCallback(() => {
    //  check is previous undo pointer is less than or equal to the length
    //   of array and the value of the index is an array to set the drop items
    if (
      changes.current.length >= undoPointer.current - 1 &&
      Array.isArray(changes.current[undoPointer.current - 1])
    ) {
      isUndoRedo.current = true;
      const newDropItems = changes.current[
        undoPointer.current - 1
      ] as IDropedItems[];
      setDropItems(newDropItems);
      undoPointer.current = undoPointer.current - 1;
      //enable redo if it is disabled
      if (redoDisabled) {
        setRedoDisabled(false);
      }
      //disable undo if pointer is 0
      if (!undoDisabled && undoPointer.current === 0) {
        setUndoDisabled(true);
      }
    } else {
      if (!undoDisabled) {
        setUndoDisabled(true);
      }
    }
  }, [
    changes,
    isUndoRedo,
    redoDisabled,
    setDropItems,
    setRedoDisabled,
    setUndoDisabled,
    undoDisabled,
    undoPointer,
  ]);

  const handleEditableChange = useCallback((title: string) => {
    setConfigDocTitle((prev) => ({
      ...prev,
      name: title,
      isEdited: true
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
		<div className="config-doc-header">
			<div className="config-doc-header__content">
				<EditableTitle
          readOnly={!!readOnly}
					title={configDocTitle.name}
					handleChange={handleEditableChange}
				/>
				<div className="config-doc-header__time-frame">
					<div className="config-doc-header__date">
						{getDate(configDocTitle.createdAt ?? '')}
					</div>
					<div className="config-doc-header__time">
						{getTime(configDocTitle.createdAt ?? '')}
					</div>
				</div>
			</div>
			{!readOnly && <div className="config-doc-header-toolkit">
				<div
					className={!undoDisabled ? 'config-doc-header-button-container' : ''}
				>
					<IconBtn
						btnClassName="ri-reply-fill"
						className={`${!undoDisabled ? 'enabled' : ''}`}
						isDisabled={undoDisabled}
						handleClickIcon={handleUndoClick}
						id="config-doc-header-undo-btn"
					/>
					<Tooltip
						id="config-doc-header-undo-btn"
						place="bottom"
						content="Undo"
					/>
				</div>
				<div
					className={!redoDisabled ? 'config-doc-header-button-container' : ''}
				>
					<IconBtn
						btnClassName="ri-share-forward-fill"
						className={`${!redoDisabled ? 'enabled' : ''}`}
						isDisabled={redoDisabled}
						handleClickIcon={handleRedoClick}
						id="config-doc-header-redo-btn"
					/>
					<Tooltip
						id="config-doc-header-redo-btn"
						place="bottom"
						content="Redo"
					/>
				</div>
			</div>}
		</div>
	) 
		
};
