import type { IMessageBanner } from './types';

import { FC, useMemo } from 'react';

export const MessageBanner: FC<IMessageBanner> = ({ message, varient }) => {
	const varientIcon = useMemo(() => {
		switch (varient) {
			case 'warning':
				return 'ri-error-warning-line';
			case 'error':
				return 'ri-close-circle-line';
			default:
				return 'ri-information-line';
		}
	}, [varient]);

	return (
		<div className={`message-banner message-banner--${varient}`}>
			<i className={`${varientIcon} message-banner__icon message-banner__icon--${varient}`}></i>
			<div className="message-banner__message-wrapper">
				<span className="message-banner__note">Note :</span>
				<span className="message-banner__message">{message}</span>
			</div>
		</div>
	);
};
