import { CSSProperties, FC, FocusEvent, MouseEvent, ReactNode } from "react";

import { IFieldNodeType } from "views/editor-dashboard";

type ScaledDivProps = {
  id: string;
  styles?: CSSProperties;
  className?: string;
  children: ReactNode;
  nodeType: IFieldNodeType;
  width: string;
  height: string;
  onClick?: (
    event:
      | MouseEvent<HTMLDivElement>
      | FocusEvent<HTMLInputElement>
      | MouseEvent<HTMLAnchorElement>
  ) => void;
};

export const ScaledDiv: FC<ScaledDivProps> = ({
  styles = {},
  className = "",
  children,
  width,
  height,
  id,
  onClick = () => ({}),
}) => {
  return (
    <div
      onClick={onClick}
      id={id}
      style={{
        width,
        height,
        ...styles,
      }}
      className={className}
    >
      {children}
    </div>
  );
};
