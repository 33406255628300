/* eslint-disable no-console */
export const IsBiomentricSupported = async (): Promise<boolean> => {
    console.log("IsBiomentricSupported function started");
    try {
        const checkCredentials =
            navigator.credentials &&
            (!!navigator.credentials.create || !!navigator.credentials.get);
        if (!(checkCredentials || window.PublicKeyCredential)) {
            console.log("Credentials not supported. Biometric authentication is not available.");
            return false;
        }

        const isSupported = await PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
        console.log("Biometric authentication support status:", isSupported);
        return isSupported;
    } catch (error) {
        console.warn("An error occurred:", error);
        return false;
    } finally {
        console.log("IsBiomentricSupported function finished");
    }
};
