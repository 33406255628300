// another function to go from string to ByteArray, but we first encode the

import { IRegisteredCredentials } from '../types';

// string as base64 - note the use of the atob() function
export const strToBin = (str: string) => {
	return Uint8Array.from(atob(str), c => c.charCodeAt(0));
};

// function to encode raw binary to string, which is subsequently
// encoded to base64 - note the use of the btoa() function

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const binToStr = (bin: any) => {
	return btoa(
		new Uint8Array(bin).reduce((s, byte) => s + String.fromCharCode(byte), '')
	);
};

export const getRegistrations = () => {
	const registrations = JSON.parse(
		localStorage.onboardedAIRegistrations || '[]'
	);
	if (registrations?.length > 20) {
		localStorage.setItem('onboardedAIRegistrations', '[]');
		return [];
	}
	return registrations;
};

export const setRegistrations = (registrations: IRegisteredCredentials) => {
	localStorage.onboardedAIRegistrations = JSON.stringify(
		registrations,
		null,
		'  '
	);
};

export const saveNewCredentials = (registration: IRegisteredCredentials) => {
	const registrations = getRegistrations();
	registrations.push(registration);
	setRegistrations(registrations);
};
