import { atom } from 'recoil';

interface IKYCPrepareUrlData {
	link: string;
	nodeId: string;
	status: string;
}

export const KYCPrepareUrl = atom<IKYCPrepareUrlData | Record<string, null>>({
	key: 'kyc-prepare-url-state-key',
	default: {},
});

export const KYCPrepareUrlLoadingState = atom<boolean>({
    key: "kyc-prepare-url-loading-state-Key",
    default: true
})

export const UserKYCFailed = atom<boolean>({
	key: "notarized-user-kyc-failed",
	default: false
})

export const UserKYCModalState = atom<boolean>({
	key: "user-kyc-modal-state-key",
	default: false
})