import { atom } from "recoil";

export const IsConfigDrawerOpen = atom<boolean>({
    key: "is-config-drawer-open",
    default: false,
})

export const IsShowMessage = atom<boolean>({
    key: "is-show-message",
    default: false,
})
