import { useCallback, useMemo } from 'react';

import { API_URL } from 'constant';
import { DeviceInfoState, useNetwork } from 'hooks';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
	IAuthUserAuthType,
	IClient,
	IRecipient,
	SenderDetailsState,
	SignDocSelector,
} from 'views';
import {
	GoogleLocation,
	LocationInfo,
	UserLocationState,
} from 'states/use-location';
import { FacialUserImageState } from 'views/facial-biometric/store';

export const useClientUser = () => {
	const locationInfo = useRecoilValue(UserLocationState);
	const googleLocation = useRecoilValue(GoogleLocation);

	const setDeviceInfo = useSetRecoilState(DeviceInfoState);
	const { get } = useNetwork();

	const getIpAddress = useCallback(async () => {
		try {
			const res = await get(API_URL.IP_ADDRESS);
			const { apiData, response } = res;
			if (response?.status === 200) {
				const { ip } = apiData;
				const userIp = ip?.split(', ')?.[0];
				return { ip: userIp };
			}
			return { ip: null };
		} catch (error) {
			return { ip: null };
		}
	}, [get]);

	const getTimeZone = useMemo(() => {
		return Intl.DateTimeFormat().resolvedOptions().timeZone;
	}, []);

	const getClient = useCallback(async () => {
		const { ip } = googleLocation ?? ({} as LocationInfo);
		const client: IClient = {
			userAgent: navigator.userAgent,
			ip: ip,
			location: locationInfo ?? undefined,
			timeZone: getTimeZone,
		};
		setDeviceInfo(client);
		return client;
	}, [getTimeZone, googleLocation, locationInfo, setDeviceInfo]);

	return { getIpAddress, getClient };
};

export const useUpdateDeviceInfo = () => {
	const deviceInfo = useRecoilValue(DeviceInfoState);
	const envelope = useRecoilValue(SignDocSelector);
	const sender = useRecoilValue(SenderDetailsState);
	const facialImg = useRecoilValue(FacialUserImageState);
	const authUserAuthType = useRecoilValue(IAuthUserAuthType);

	const { envelopeId: id, recipient = {} as IRecipient } = envelope;
	const { post } = useNetwork();

	const patchDeviceInfo = useCallback(
		async (
			userType: 'sender' | 'signer',
			envId?: string,
			recipientId?: string
		) => {
			const envelopeId =
				envId ?? (userType === 'signer' ? id : sender?.envelopeId);
			const userId =
				recipientId ?? (userType === 'signer' ? recipient._id : sender?._id);
			const biometricPayload = {
				...deviceInfo,
				envelopeId,
				userId,
			};
			if (authUserAuthType === 'facial' && userType === 'signer') {
				biometricPayload.image = facialImg;
			}
			await post(`${API_URL.BIOMETRICS}`, biometricPayload);
		},
		[
			authUserAuthType,
			deviceInfo,
			facialImg,
			id,
			post,
			recipient._id,
			sender?._id,
			sender?.envelopeId,
		]
	);
	return { patchDeviceInfo };
};
