import { FC, useMemo } from 'react';

import { IPosition } from '../store';

// Define the props expected by the EditOptions component.
interface IEditOptions {
  handleSignatureChange: () => void;    // Function to handle the change of a signature
  handleSignatureDelete: () => void;   // Function to handle the deletion of a signature
  pageWidth: number;                   // Width of the page
  pageHeight: number;                  // Height of the page
  position: IPosition;                 // Position of the signature on the page
  isDeleteOption?:boolean; 
}

// Define a type for the popup direction.
type IPopupDirection = 'default' | 'top' | 'left' | 'topLeft' | 'topRight';

// Create the EditOptions functional component.
export const EditOptions: FC<IEditOptions> = ({
  handleSignatureChange,
  handleSignatureDelete,
  pageHeight,
  pageWidth,
  position,
  isDeleteOption=true,
}) => {
  // Calculate the popup direction based on available space around the signature.
  const popupDirection = useMemo((): IPopupDirection => {
    const { xLeft, yTop } = position;
    const availableSpaceAtRight = pageWidth - xLeft * pageWidth;
    const availableSpaceAtBottom = pageHeight - yTop * pageHeight;

    // Determine the optimal direction based on available space
    if (availableSpaceAtRight < 200 && availableSpaceAtBottom > 200) {
      return 'left';
    }

    if (availableSpaceAtRight < -200 && availableSpaceAtBottom < -200) {
      return 'topLeft';
    }

    if (availableSpaceAtRight > 200 && availableSpaceAtBottom < 200) {
      return 'topRight';
    }

    if (availableSpaceAtBottom < 200) {
      return 'top';
    }

    // Default direction when no specific condition is met
    return 'default';
  }, [pageHeight, pageWidth, position]);

  // Render the EditOptions component with the determined popup direction.
  return (
    <ul className={`Esign-options-box Esign-options-box--${popupDirection}`}>
      <li
        onClick={handleSignatureChange}
        className="Esign-options-box__menu-item"
      >
        <i className="ri-edit-2-line space" />
        Edit
      </li>
     {isDeleteOption && <li
        onClick={handleSignatureDelete}
        className="Esign-options-box__menu-item"
      >
        <i className="ri-delete-bin-6-line space delete-icon" />
        Delete
      </li>}
    </ul>
  );
};
