import { FC, useCallback, useRef, useState } from "react";

import { SketchColorPicker } from "@storybook";
import { useGetConfiguredNode, useSetConfigFieldNode } from "views";

interface IProps {
  name: string;
  defaultValue: string;
  label: string;
}

export const ColorPickerComponent: FC<IProps> = ({
  defaultValue,
  label,
  name,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const colorPickerRef = useRef(null);
  const { handleConfigureNode } = useSetConfigFieldNode();
  const { configuredNode } = useGetConfiguredNode();

  const handleChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ({ hex }: any) => {
      handleConfigureNode(name, hex);
    },
    [handleConfigureNode, name]
  );

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const value = configuredNode(name) ?? defaultValue;

  // const parentNode = document.getElementById("config-component-body");

  // const manageModalPosition = useMemo(() => {

  // },[])

  return (
    <div className="color-picker">
      <div className="color-picker__label">{label}</div>
      <div
        className="color-picker__input"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <span
          className="color-picker__active-color"
          style={{ backgroundColor: value }}
        />
        <div className="color-picker__hex-code">{value}</div>
      </div>
      {isOpen && (
        <div className="color-picker__modal" ref={colorPickerRef}>
          <SketchColorPicker
            closeOutside={handleClose}
            currentColor={value}
            handleOnChange={handleChange}
          />
        </div>
      )}
    </div>
  );
};
