import { useRecoilValue } from "recoil";
import { Modal } from "@storybook";

import { IsAddRecipientModalOpen, useRecipient } from "./store";
import { AddRecipientBody } from "./components";

export const RecipientModal = () => {
  const isOpen = useRecoilValue(IsAddRecipientModalOpen);

  const { resetAddRecipientsModal } = useRecipient();

  return (
    <Modal
      isOpen={isOpen}
      modalName=""
      className="add-recipient"
      closeOnEscBtn
      closeModal={resetAddRecipientsModal}
    >
      <div className="add-recipient-modal">
        <AddRecipientBody header="Add Recipients" />
      </div>
    </Modal>
  );
};
