import { useCallback, useEffect, useRef, useState } from "react";

import SignatureCanvas from "react-signature-canvas";
import { useRecoilState, useRecoilValue } from "recoil";
import { SketchColorPicker } from "@storybook";
import {
  CreateSignState,
  PointObject,
  SignDocSelector,
  SignPadColorState,
} from "views";

export const DrawSign = () => {
  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const signPad: any = useRef();

  const [color, setColor] = useRecoilState(SignPadColorState);
  const [isOpen, setIsOpen] = useState(false);
  const [signature, setSignature] = useRecoilState(CreateSignState);
  const colorPickerRef = useRef(null);
  const { selectedNode } = useRecoilValue(SignDocSelector);

  const handleDelete = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setSignature((prev: any) => {
      const clonedPrev = JSON.parse(JSON.stringify(prev));
      clonedPrev.draw = {
        canvasDataCoordinates: [],
        sign: "",
        initials: "",
      };
      return clonedPrev;
    });
    signPad.current.clear();
  }, [setSignature]);

  const replacePenColor = (
    arr: PointObject[][],
    newColor: string
  ): PointObject[][] => {
    const pointsArr: PointObject[][] = JSON.parse(JSON.stringify(arr));
    pointsArr.forEach((subArr) => {
      subArr.forEach((obj) => {
        obj.color = newColor;
      });
    });
    return pointsArr;
  };

  const removeDuplicatePoints = (arr: PointObject[][]): PointObject[][] =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Array.from(new Set(arr.map(JSON.stringify as any))).map(JSON.parse as any);

  const handleChange = useCallback(
    (updatedData?: PointObject[][]) => {
      const prevCloneD = removeDuplicatePoints(
        updatedData || [
          ...signature.draw.canvasDataCoordinates,
          ...signPad.current.toData(),
        ]
      );
      const data = signPad.current.getTrimmedCanvas().toDataURL("image/png");
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setSignature((prev: any) => {
        const ClonedPrev = JSON.parse(JSON.stringify(prev));
        if (selectedNode?.type === "signature" || selectedNode?.fieldType === "signature") {
          ClonedPrev.draw.sign = data;
        } else {
          ClonedPrev.draw.initials = data;
        }
        ClonedPrev.draw.canvasDataCoordinates = prevCloneD;
        return ClonedPrev;
      });
    },
    [selectedNode?.fieldType, selectedNode?.type, setSignature, signature.draw.canvasDataCoordinates]
  );

  const handleChangePenColor = useCallback(
    ({ hex }: { hex: string }) => {
      setColor(hex);
      const updatedArr = replacePenColor(signPad.current._sigPad._data, hex);
      signPad.current._sigPad.fromData(updatedArr);
      signPad.current._sigPad._data = JSON.parse(JSON.stringify(updatedArr));
      handleChange(updatedArr);
    },
    [handleChange, setColor]
  );

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (signature.draw?.canvasDataCoordinates?.length > 0) {
      signPad?.current._sigPad.fromData(signature.draw.canvasDataCoordinates);
      signPad.current._sigPad._data = JSON.parse(
        JSON.stringify(signPad.current._sigPad._data)
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="draw-sign">
      <div className="draw-sign__format">
        <div className="draw-sign__format-color">
          <div
            className="draw-sign__format-color-btn"
            onClick={() => setIsOpen((prev) => !prev)}
          >
            Change color
            <span
              className="draw-sign__active-color"
              style={{ backgroundColor: color }}
            />
          </div>
          {isOpen && (
            <div className="draw-sign__format-color-modal" ref={colorPickerRef}>
              <SketchColorPicker
                closeOutside={handleClose}
                currentColor={color}
                handleOnChange={handleChangePenColor}
              />
            </div>
          )}
        </div>
      </div>
      <div className="draw-sign__header">
        <div className="draw-sign__header-label">Draw</div>
        <div className="draw-sign__clear-btn" onClick={handleDelete}>
          Clear
        </div>
      </div>
      <SignatureCanvas
        penColor={color}
        onEnd={() => handleChange()}
        ref={signPad}
        clearOnResize={false}
        canvasProps={{ className: "draw-sign__sign-pad" }}
      />
    </div>
  );
};
