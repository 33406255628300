import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';

/**
 * Recoil for state management
 * */
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
/**
 * Custom Hooks for various functionalities
 * */
import { useNotification, useUpdateDeviceInfo } from 'hooks';
/**
 * Views and Components used in the application
 * */
import {
	APP_ROUTES,
	AddRecipientFooter,
	AddRecipientHeader,
	IsEnableFacialAuth,
	IRecipientUser,
	RecipientLocalState,
	RecipientsDragAndDrop,
	SigningOrderCheckBox,
	UploadDocuments,
	UploadedEnvelopeDocsState,
	isSigningOrderEnabledState,
	prepareTypeState,
	resetColorsSelected,
	templateTypeState,
	useInvitePayload,
	usePrepareOverlay,
	useRecipient,
	useScrollToLastRecipient,
	useSetConfigDoc,
	newUploadFile,
	IsUndoModal,
	counterSecond,
	IsOriginalCopy,
	ActiveRecipientTabState,
	totalTabs,
	multiPeopleTabs,
	prepareDashboardSelector,
	addRecipientMessage,
	IsEnableENotaryToggle,
	IsEnableMerchantFlow,
	TemplateSourceState,
	recipientsSelector,
} from 'views';
/**
 * Constants and Assets for the application
 * */
import { MESSAGE, ASSETS, ENVELOPE_PURPOSE, TEMPLATE_SOURCE } from 'constant';
/**
 * Storybook components for UI elements
 * */
import { ReactSwitch } from '@storybook';
import { IMessageBanner, MessageBanner } from '@storybook/banner';
import { useNavigate, useParams } from 'react-router-dom';
/**
 * Custom Component for managing recipient tabs
 * */
import { RecipientTabs } from 'components';
import { UndoModal } from 'views/undo-modal';
import { isShowSuccessInvitation, webComponentEnvelopeIdState } from 'states/web-component-state';
import { ENV_STATE, EnvState, EnvelopePurposeState } from 'states';

import { AdditionalFlag } from '../additional-flag';
interface IProps {
	isFirstDisabled?: boolean;
	header?: string;
	className?: string;
}
const { ONE_FILE_REQUIRED, ONE_NEED_TO_SIGN_REQUIRED } = MESSAGE;

export const AddRecipients: FC<IProps> = ({ header = '', className = '' }) => {
	const { id } = useParams();
	// envelopeId state when there is web component
	const webComponentEnvelopeId = useRecoilValue(webComponentEnvelopeIdState);

	const paramId = id ?? webComponentEnvelopeId;
	const showNotary = false;

	const envelopeData = useRecoilValue(UploadedEnvelopeDocsState);
	const prepareType = useRecoilValue(prepareTypeState);
	const templateType = useRecoilValue(templateTypeState);
	const localRecipients = useRecoilValue(RecipientLocalState);
	const setInvitationSuccess = useSetRecoilState(isShowSuccessInvitation);
	const newUploadData = useRecoilValue(newUploadFile);
	const env = useRecoilValue(EnvState);
	const { errorNotification } = useNotification();
	const { saveLocalRecipients, patchRecipients, setOriginalCopyData } =
		useRecipient();
	useScrollToLastRecipient();
	//paras : add facial toggle
	const [enableFacialAuth, setEnableFacialAuth] =
		useRecoilState(IsEnableFacialAuth);

	const [isENotaryToggle, setIsENotaryToggle] = useRecoilState(
		IsEnableENotaryToggle
	);
	const [isMerchantFlow, setIsMerchantFlow] = useRecoilState(IsEnableMerchantFlow);
	const [isOriginalCopy, setIsOriginalCopy] = useRecoilState(IsOriginalCopy);
	const { patchOverlaytemplate } = usePrepareOverlay();
	const { patchDeviceInfo } = useUpdateDeviceInfo();
	const { handleSaveEnvelope } = useSetConfigDoc();
	const { saveTemplatePayload } = useInvitePayload({ envelopeId: paramId });
	const [isLoading, setIsLoading] = useState(false);
	const signOrder = useRecoilValue(isSigningOrderEnabledState);
	const { SINGLE_FILE_SVG } = ASSETS;

	const activeTab = useRecoilValue(ActiveRecipientTabState);
	const [isUndoModal, setIsUndoModal] = useRecoilState(IsUndoModal);
	const { isEditable } = useRecoilValue(prepareDashboardSelector);
	const [purpose, setPurpose] = useRecoilState(EnvelopePurposeState);
	const templateSource = useRecoilValue(TemplateSourceState)
	const { isNeedToSignRequired } = useRecoilValue(recipientsSelector);

	// local state
	const [timeoutApiCall, setTimeoutApiCall] = useState(false);

	const { data: uploadDocList } = envelopeData;
	const navigate = useNavigate();

	const isReconfigureRequired = useMemo(() => {
		return newUploadData.length > 0;
	}, [newUploadData]);

	useEffect(() => {
		if (activeTab) {
			setPurpose((prev) => {
				if (prev === ENVELOPE_PURPOSE.MULTISIGNAGREEMENT) {
					return prev;
				}else {
					return activeTab;
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeTab]);	

	const getPrimaryButtonLabel = useMemo(() => {
		if (templateType === 'overlay') {
			if (purpose === ENVELOPE_PURPOSE.MULTISIGNAGREEMENT && !isReconfigureRequired) {
				return 'Save & Close';
			}
			
			if (!isReconfigureRequired && prepareType === 'envelope' && purpose !== ENVELOPE_PURPOSE.MULTISIGNAGREEMENT) {
				return 'Send Invite';
			}
		}
	
		return 'Continue';
	}, [isReconfigureRequired, prepareType, purpose, templateType]);

	const patchOverlayRecipientsPayload = useMemo(() => {
		const tempRecipient = structuredClone(localRecipients).map(
			({ message, action, id, title, color, originalCopy, _id }: IRecipientUser) => {
				const data: { [key: string]: string | boolean | undefined } = {
					action,
					title: title?.trim(),
					colorCode: color,
					originalCopy: isOriginalCopy ? originalCopy : true,
					_id: _id,
				};
				if (id && !id.startsWith('temp')) {
					data._id = id;
				}
				if (message) {
					data.message = message.trim();
				}
				return data;
			}
		);
		const documentList = uploadDocList.map(({ id, originalTabs, tabs }) => ({
			document: id,
			tabs: originalTabs ?? tabs
		}));

		return {
			recipients: tempRecipient,
			documents: documentList,
			reviewSign: isMerchantFlow,
			signOrder,
		};
	}, [localRecipients, uploadDocList, isMerchantFlow, signOrder, isOriginalCopy]);

	const handleSubmit = useCallback(
		/**
		 * validate and save the recipients
		 * @returns a promise if needed to use for loading symbol
		 */
		async (): Promise<void> => {
			/**
			 * Step 1: Check if at least one document has been uploaded
			 * */
			if (uploadDocList.length < 1) {
				setTimeoutApiCall(false);
				errorNotification(ONE_FILE_REQUIRED);
				return Promise.resolve();
			} else if (activeTab === 'selfsign') {
				/**
				 * Step 2: Check if the active tab is 'self' (Self-signing mode)
				 * */
				setIsLoading(true);
				await patchRecipients(true, 'selfsign');
				setIsLoading(false);
				return; // Exit the function
			}

			/**
			 * Step 3: Only for correct screen: check that at least one pending recipient has need to sign action
			 * */
			if (!isNeedToSignRequired && isEditable === true) {
				setTimeoutApiCall(false);
				errorNotification(ONE_NEED_TO_SIGN_REQUIRED);
				return Promise.resolve();
			}

			const isSaveLocalRecipients =
				templateType !== 'overlay' ? saveLocalRecipients() : true;
			if (isSaveLocalRecipients) {
				setIsLoading(true);
				// over lay template create flow
				if (prepareType === 'overlay' && templateType === 'overlay') {
					return patchOverlaytemplate(
						patchOverlayRecipientsPayload,
						paramId ?? ''
					).then((status) => {
						if (status) {
							if (webComponentEnvelopeId) {
								return;
							}
							navigate(`/${APP_ROUTES.CONFIG_DOC}?templateId=${paramId}`);
						} else {
							setIsLoading(false);
							setTimeoutApiCall(false);
						}
					});
				} else if (templateType === 'basic') {
					// basic multisigner flow
					patchRecipients(true).then((successful) => {
						if (!successful) {
							setIsLoading(false);
							setTimeoutApiCall(false);
						}
					});
				} else {
					// overlay invite flow
					const shouldNavigate = isReconfigureRequired;
					const status = await patchRecipients(shouldNavigate);
					if (!shouldNavigate && status) {
						patchDeviceInfo('sender');
						await handleSaveEnvelope(saveTemplatePayload, paramId, true).then(
							(saveSuccessful) => {
								if (saveSuccessful) {
									setInvitationSuccess(true);
									resetColorsSelected();
								} else {
									setIsLoading(false);
									setTimeoutApiCall(false);
								}
							});
					} else {
						setIsLoading(false);
						setTimeoutApiCall(false);
					}
				}
			} else {
				setTimeoutApiCall(false);
			}
			return Promise.resolve();
		},
		[uploadDocList.length, activeTab, isNeedToSignRequired, isEditable, templateType, saveLocalRecipients, errorNotification, patchRecipients, prepareType, patchOverlaytemplate, patchOverlayRecipientsPayload, paramId, webComponentEnvelopeId, navigate, isReconfigureRequired, patchDeviceInfo, handleSaveEnvelope, saveTemplatePayload, setInvitationSuccess]
	);

	const handleSubmitting = useCallback(() => {
		const isSaveLocalRecipients =
			templateType === 'overlay' ? saveLocalRecipients() : true;
		setTimeoutApiCall(false);
		if (
			uploadDocList.length > 0 &&
			isSaveLocalRecipients &&
			!isReconfigureRequired &&
			prepareType === 'envelope' &&
			templateType === 'overlay'
		) {
			if (purpose == ENVELOPE_PURPOSE.MULTISIGNAGREEMENT) {
				handleSubmit()
			}else {
				setIsUndoModal(true);
				setIsLoading(true);
				setTimeout(() => {
					if (timeoutApiCall) handleSubmit();
				}, counterSecond * 1000);
			}
		} else if (isSaveLocalRecipients) {
			setTimeoutApiCall(true);
		}
	}, [templateType, saveLocalRecipients, uploadDocList.length, isReconfigureRequired, prepareType, purpose, handleSubmit, setIsUndoModal, timeoutApiCall]);

	useEffect(() => {
		if (!isUndoModal && isLoading) {
			setTimeoutApiCall(true);
			setIsLoading(false);
		} else if (timeoutApiCall) {
			handleSubmit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isUndoModal, timeoutApiCall]);

	//paras : add method for facial toggle
	const handleFacialSwitch = useCallback((checked: boolean) => {
		setEnableFacialAuth(checked);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleEnableENotary = useCallback((value: boolean) => {
		setIsENotaryToggle(value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const selfSignerScreen = useMemo(() => {
		return (
			<Fragment>
				{(env === 'stage' || env === "preprod") && showNotary && (
					<AdditionalFlag
						handleChange={handleEnableENotary}
						value={isENotaryToggle}
						description={addRecipientMessage.eNotary.description}
						heading={addRecipientMessage.eNotary.heading}
						warning={addRecipientMessage.eNotary?.warning as IMessageBanner}
					/>
				)}
				<div className="self-sign__image-wrapper">
					<div>
						<img
							src={SINGLE_FILE_SVG}
							alt=""
							loading="lazy"
							width={120}
							height={120}
						/>
					</div>
					<div className="self-sign__image-heading">
						You are the only signer for the document
					</div>
					<div className="self-sign__image-desc">
						You will complete the entire signing process yourself.
					</div>
				</div>
			</Fragment>
		);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [SINGLE_FILE_SVG, env, handleEnableENotary, isENotaryToggle]);

	const handleOriginalCopySwitch = useCallback(
		(checked: boolean) => {
			setIsOriginalCopy(checked);
			if (checked) {
				const defaultSelectedId =
					localRecipients[0]?.id ?? localRecipients[0]?._id;
				setOriginalCopyData(defaultSelectedId ?? '');
			} else {
				setOriginalCopyData('');
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[localRecipients]
	);

	const handleEnableMerchantFlow = useCallback((checked: boolean) => {
		setIsMerchantFlow(checked)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	const renderActiveTabChild = useMemo(() => {
		switch (activeTab) {
			case 'workflow':
				return (
					<Fragment>
						<div className="add-recipient-container__flags-wrapper">
							<AdditionalFlag
								handleChange={handleOriginalCopySwitch}
								value={isOriginalCopy}
								description={addRecipientMessage.originalCopy.description}
								heading={addRecipientMessage.originalCopy.heading}
								warning={addRecipientMessage.originalCopy?.warning}
							/>
							{(purpose === ENVELOPE_PURPOSE.MULTISIGNAGREEMENT || templateSource === TEMPLATE_SOURCE.ONBOARDING ) &&
								<AdditionalFlag
								handleChange={handleEnableMerchantFlow}
								value={isMerchantFlow}
								description={addRecipientMessage.reviewSigning.description}
								heading={addRecipientMessage.reviewSigning.heading}
								warning={addRecipientMessage.reviewSigning?.warning}
							/>}
							{(env === ENV_STATE.STAGE || env === ENV_STATE.PREPROD) && purpose !== ENVELOPE_PURPOSE.MULTISIGNAGREEMENT && (
								<AdditionalFlag
									handleChange={handleEnableENotary}
									value={isENotaryToggle}
									description={addRecipientMessage.eNotary.description}
									heading={addRecipientMessage.eNotary.heading}
									warning={addRecipientMessage.eNotary?.warning as IMessageBanner}
								/>
							)}
						</div>
						<SigningOrderCheckBox />
						<RecipientsDragAndDrop />
					</Fragment>
				);
			case 'selfsign':
				return selfSignerScreen;
			default:
				return (
					<Fragment>
						<SigningOrderCheckBox />
						<RecipientsDragAndDrop />
					</Fragment>
				);
		}
	}, [activeTab, env, handleEnableENotary, handleEnableMerchantFlow, handleOriginalCopySwitch, isENotaryToggle, isMerchantFlow, isOriginalCopy, purpose, selfSignerScreen, templateSource]);

	const allowedTabs = useMemo(() => {
		if (
			templateType === 'basic' &&
			prepareType === 'envelope' &&
			!isEditable &&
			purpose !== ENVELOPE_PURPOSE.MULTISIGNAGREEMENT
		) {
			return totalTabs;
		}
		return multiPeopleTabs;
	}, [isEditable, prepareType, purpose, templateType]);
	
	const renderRecipientSection = useMemo(() => {
		return (
			<RecipientTabs state={ActiveRecipientTabState} totalTabs={allowedTabs}>
				{renderActiveTabChild}
			</RecipientTabs>
		);
	}, [allowedTabs, renderActiveTabChild]);

	return (
		<div
			className="add-recipient-container"
			id="add-recipient__recipient-list-data"
		>
			<AddRecipientHeader header={header} />
			<div className="add-recipient-data-container">
				<UploadDocuments />
				{prepareType === 'envelope' && templateType === 'overlay' && (
					<div className="upload-documents">
						<MessageBanner
							message="For document swap both documents must have the same number of 
					pages for this action to proceed successfully."
							varient="warning"
						/>
					</div>
				)}
				<div
					className={`add-recipient__body ${className}`}
					id="add-recipient-body"
				>
					<div className="add-recipient__form-header-text">
						{'Add Recipients'}
						{/* this modal is hidden for now @Manish */}
						{(templateType === 'basic' ||
							(templateType === 'overlay' && prepareType === 'envelope')) && (
							<div className="add-recipient__form-header-toogle-wrapper">
								{/* paras : add facial toggle 	 */}
								<span>Enable Biometric Identification</span>
								<ReactSwitch
									id={''}
									checked={enableFacialAuth}
									handleChange={handleFacialSwitch}
								/>
							</div>
						)}
					</div>
					{renderRecipientSection}
				</div>
			</div>
			<UndoModal width={'960px'} setLoading={setIsLoading} />
			<AddRecipientFooter
				primaryBtnLabel={getPrimaryButtonLabel}
				handlePrimaryClick={handleSubmitting}
				loading={isLoading}
				isDisabled={uploadDocList.length < 1}
			/>
		</div>
	);
};
