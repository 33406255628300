import { Loader } from '@storybook';
import { ASSETS } from 'constant';

export const LoadingSection = () => {
	const { BIOMETRIC_SVG } = ASSETS;

	return (
		<>
			<div className="Esign-facial__loading-wrapper">
				<img src={BIOMETRIC_SVG} alt="" className="server-error__logo" />
				<p className="Esign-facial__loading-heading">
					We are preparing to initiate facial authentication.
				</p>

				<div className="Esign-facial__loading-instructions">
					Ensure you are in a well-lit environment. Stay close to the camera,
					but not too close.
				</div>
				<Loader className="loader-blue" dimension={30} />
			</div>
		</>
	);
};
