/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react';

import * as vision from '@mediapipe/tasks-vision';
import { useSetRecoilState } from 'recoil';

import { ICameraPermissionONState, IsFaceMeshLoadedState } from '../store';

const { FaceLandmarker, FilesetResolver, DrawingUtils } = vision;
interface IScreenResolution {
	width: { ideal: number };
	height: { ideal: number };
}
export const useFace = () => {
	const faceLandmarker = useRef<vision.FaceLandmarker>();
	const setLoaded = useSetRecoilState(IsFaceMeshLoadedState);
	const webcamRunning = useRef(false);
	// ref for video size 
	const initialSizeRef = useRef<IScreenResolution>({
		width: { ideal: 600 },
		height: { ideal: 400 },
	});
	const isFaceVisibleRef = useRef(false);
	const [isFaceVisible, setIsFaceVisible] = useState(false);
	const videoWidth = 450;
	const lastVideoTime = useRef(-1);
	const results = useRef<vision.FaceLandmarkerResult>();
	const canvasElement = useRef<HTMLCanvasElement>();
	const videoElement = useRef<HTMLVideoElement>();
	const [isCameraStart, setCameraStart] = useState(false);
	const setCameraPermission = useSetRecoilState(ICameraPermissionONState);

	//Arun Kumar : Update screen width
	const updateScreenWidth = useCallback(() => {
		if (window.innerWidth > videoWidth) {
			initialSizeRef.current = {
				width: { ideal: 600 },
				height: { ideal: 400 },
			};
		} else {
			initialSizeRef.current = {
				width: { ideal: window.innerWidth },
				height: { ideal: window.innerHeight / 2 + 50 },
			};
		}
	}, []);

	const updateFaceVisible = useCallback((value: boolean) => {
		if (value === isFaceVisibleRef.current) {
			return
		}
		isFaceVisibleRef.current = value;
		setIsFaceVisible(value)
	}, [])

	useEffect(() => {
		//Arun Kumar : Add an event listener to update the screenWidth whenever the window is resized.
		updateScreenWidth();
		window.addEventListener('resize', updateScreenWidth);

		//Arun Kumar : Clean up the event listener when the component unmounts.
		return () => {
			window.removeEventListener('resize', updateScreenWidth);
		};
	}, [updateScreenWidth]);

	useEffect(() => {
		// Feature detection
		if (navigator?.permissions && navigator?.permissions.query) {
			// Cast "camera" to PermissionName
			const permissionName = 'camera';

			const handlePermissionChange = (permissionStatus: PermissionStatus) => {
				if (permissionStatus.state === 'granted') {
					// setActiveScreen('liveness-verification');
				}
			};

			// Use the casted permissionName to query for camera permission
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			navigator.permissions
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				.query({ name: permissionName as any })
				.then((permissionStatus) => {
					// Event handling using addEventListener
					permissionStatus.addEventListener('change', () => {
						handlePermissionChange(permissionStatus);

						// eslint-disable-next-line no-console
						console.log(
							`Camera permission changed to ${permissionStatus.state}`
						);
					});
				})
				.catch((error) => {
					// eslint-disable-next-line no-console
					console.error('Error querying camera permission:', error);
				});
		} else {
			// eslint-disable-next-line no-console
			console.error('navigator.permissions.query is not supported');
		}
	}, []); // Empty dependency array means this useEffect will run once on mount

	// Face mask color for connectors
	const faceMaskConnectorColor = '#C0C0C070';

	// eslint-disable-next-line @typescript-eslint/ban-types
	const currentStreamRef = useRef<Function>();

	// Check if webcam access is supported.
	const isGetingUserMedia = () => {
		return !!(navigator?.mediaDevices && navigator?.mediaDevices.getUserMedia);
	};

	// Drawing video streaming on canvas with mask
	const drawCanvas = useCallback(async () => {
		const canvasCtx = canvasElement?.current?.getContext('2d');
		const canvas = canvasElement?.current;
		const video = videoElement?.current;

		try {
			if (!canvasCtx || !video || !canvas) {
				return;
			}
			// Check if video dimensions are valid
			if (video.videoWidth > 0 && video.videoHeight > 0) {
				const drawingUtils = new DrawingUtils(canvasCtx);
				const getVideoWidth = initialSizeRef.current?.width.ideal;
				const getVideoHeight = initialSizeRef.current?.height.ideal;
				video.style.width = getVideoWidth + 'px';
				video.style.height = getVideoHeight + 'px';
				video.style.marginLeft = window.innerWidth > 450 ? '0px' : '-10px';
				canvas.style.width = getVideoWidth + 'px';
				canvas.style.height = getVideoHeight + 'px';
				canvas.width = video.videoWidth;
				canvas.height = video.videoHeight;
				canvas.style.marginLeft = window.innerWidth > 450 ? '0px' : '-10px';

				const startTimeMs = performance.now();
				if (lastVideoTime.current !== video.currentTime) {
					lastVideoTime.current = video.currentTime;
					results.current = faceLandmarker.current?.detectForVideo(
						video,
						startTimeMs
					);
				}
				
				const face = !!results.current?.faceLandmarks.length && !(results.current?.faceLandmarks?.[0]?.filter(landmark => {
					return landmark.y >= 1 || landmark.y <= 0 || landmark.x >= 1 || landmark.x <= 0 
				})?.length);

				updateFaceVisible(face);

				if (results.current?.faceLandmarks) {
					for (const landmarks of results.current.faceLandmarks) {
						drawingUtils.drawConnectors(
							landmarks,
							FaceLandmarker.FACE_LANDMARKS_TESSELATION,
							{ color: faceMaskConnectorColor, lineWidth: 1 }
						);
						drawingUtils.drawConnectors(
							landmarks,
							FaceLandmarker.FACE_LANDMARKS_RIGHT_EYE,
							// { color: "#FF3030" }
							{ color: faceMaskConnectorColor }
						);
						drawingUtils.drawConnectors(
							landmarks,
							FaceLandmarker.FACE_LANDMARKS_RIGHT_EYEBROW,
							// { color: "#FF3030" }
							{ color: faceMaskConnectorColor }
						);
						drawingUtils.drawConnectors(
							landmarks,
							FaceLandmarker.FACE_LANDMARKS_LEFT_EYE,
							// { color: "#30FF30" }
							{ color: faceMaskConnectorColor }
						);
						drawingUtils.drawConnectors(
							landmarks,
							FaceLandmarker.FACE_LANDMARKS_LEFT_EYEBROW,
							// { color: "#30FF30" }
							{ color: faceMaskConnectorColor }
						);
						drawingUtils.drawConnectors(
							landmarks,
							FaceLandmarker.FACE_LANDMARKS_FACE_OVAL,
							// { color: "#E0E0E0" }
							{ color: faceMaskConnectorColor }
						);
						drawingUtils.drawConnectors(
							landmarks,
							FaceLandmarker.FACE_LANDMARKS_LIPS,
							// { color: "#E0E0E0" }
							{ color: faceMaskConnectorColor }
						);
						drawingUtils.drawConnectors(
							landmarks,
							FaceLandmarker.FACE_LANDMARKS_RIGHT_IRIS,
							// { color: "#FF3030" }
							{ color: faceMaskConnectorColor }
						);
						drawingUtils.drawConnectors(
							landmarks,
							FaceLandmarker.FACE_LANDMARKS_LEFT_IRIS,
							// { color: "#30FF30" }
							{ color: faceMaskConnectorColor }
						);
					}
				}

				currentStreamRef.current?.(results.current);

				// Call this function again to keep predicting when the browser is ready.
				if (webcamRunning.current === true) {
					window.requestAnimationFrame(drawCanvas);
				}
			}
		} catch (error) {
			// it helpful to get error in canvas draw
			// eslint-disable-next-line no-console
			console.warn('error', error);
		}
	}, [initialSizeRef]);

	const updatefunction = useCallback(() => {
		setLoaded(true);
	}, []);

	const enableCam = useCallback(
		async (draw?: boolean) => {
			if (!faceLandmarker.current) {
				// eslint-disable-next-line no-console
				console.log('Wait! faceLandmarker not loaded yet.');
			}

			if (!isGetingUserMedia) {
				// eslint-disable-next-line no-console
				console.error('No camera access');
			}

			const devices = await navigator?.mediaDevices?.enumerateDevices();
			let frontCamera = devices?.find(
				(device) =>
					device?.kind === 'videoinput' &&
					device?.label.toLowerCase().includes('front')
			);

			if (!frontCamera) {
				// If front camera is not found, use any available video input device
				frontCamera = devices?.find((device) => device?.kind === 'videoinput');
			}

			const idealSize = {
				width: {
					ideal:
						window.innerWidth > videoWidth
							? initialSizeRef.current?.width.ideal
							: initialSizeRef.current?.height.ideal,
				},
				height: {
					ideal:
						window.innerWidth > videoWidth
							? initialSizeRef.current.height.ideal
							: initialSizeRef.current.width.ideal,
				},
			};

			const videoStream = {
				video: {
					deviceId: frontCamera?.deviceId,
					facingMode: 'user', // Use "user" for front camera
					...idealSize,
				},
			};

			// Activate the webcam stream.
			navigator?.mediaDevices
				?.getUserMedia(videoStream)
				.then((stream) => {
					if (videoElement?.current) {
						videoElement.current.srcObject = stream;
						webcamRunning.current = true;
						if (draw) {
							videoElement.current.addEventListener('loadeddata', drawCanvas);
							videoElement.current.addEventListener(
								'loadeddata',
								updatefunction
							);
						}
					}
				})
				.catch(() => {
					setCameraPermission(false);
					// setActiveScreen('camera-permission-required');
				});
		},
		[drawCanvas]
	);

	// Before we can use HandLandmarker class we must wait for it to finish
	// loading. Machine Learning models can be large and take a moment to
	// get everything needed to run.
	const start = useCallback(
		async ({
			video,
			canvas,
			blendShapes,
			draw = true,
			onResults,
		}: {
			video: HTMLVideoElement;
			canvas: HTMLCanvasElement;
			blendShapes?: HTMLElement | null;
			draw?: boolean;
			onResults?: (results?: vision.FaceLandmarkerResult) => void;
		}) => {
			videoElement.current = video;
			canvasElement.current = canvas;
			// eslint-disable-next-line no-console
			console.log('blendShapes--testing purpose only', blendShapes);

			currentStreamRef.current = onResults;
			const filesetResolver = await FilesetResolver.forVisionTasks(
				'https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm'
			);

			// if(!IsStartFunctionCalled){
			faceLandmarker.current = await FaceLandmarker.createFromOptions(
				filesetResolver,
				{
					baseOptions: {
						modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
						delegate: 'GPU',
					},
					outputFaceBlendshapes: true,
					runningMode: 'VIDEO',
					numFaces: 1,
				}
			);
			setCameraStart(true);
			enableCam(draw);
		},
		[enableCam, isCameraStart]
	);

	// function for stop the camera
	const stopCamera = useCallback((video: HTMLVideoElement) => {
		videoElement.current = video;
		if (video) {
			const stream = video.srcObject;
			if (stream instanceof MediaStream) {
				const tracks = stream.getTracks();
				tracks.forEach((track) => {
					track.stop();
				});
				video.srcObject = null;
			}
		}
	}, []);

	const draw = useCallback(async () => {
		await drawCanvas();
	}, [drawCanvas]);

	return { isFaceVisible, start, draw, stopCamera };
};
