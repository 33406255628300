import type { IPropsEditableInput } from './type';
import type { IInputChange, IKeyPress } from 'types';

import { useCallback, useEffect, useRef, useState } from 'react';

import { useRecoilValue } from 'recoil';
import { IsLoadingState } from 'views';

export const EditableTitle = ({
	title,
	handleChange,
	readOnly,
}: IPropsEditableInput) => {
	const isProcessing = useRecoilValue(IsLoadingState);

	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [text, setText] = useState<string>(title);
	const [errorText, setErrorText] = useState<string | null>(null);

	const inputRef = useRef(null);

	useEffect(() => {
		setText(title);
	}, [title]);

	const handleIconClick = useCallback(() => {
		if (!isProcessing) {
			setIsEditing(true);
		}
	}, [isProcessing]);

	const handleChangeText = useCallback((e: IInputChange): void => {
		const { value } = e.target;
		setText(value);
		if (value.length >= 4 && value.length <= 100) {
			setErrorText(null);
		} else {
			setErrorText(
				value.length < 4
					? 'Text must be at least 4 characters long'
					: 'Text must be less than 100 characters'
			);
		}
	}, []);

	const handleSuccess = useCallback(() => {
		const textValue = text.trim();
		if (!textValue) {
			handleChange('Untitled');
			setText('Untitled');
			setIsEditing(false);
			return;
		}
		if (textValue.length >= 4 && textValue.length <=100 ) {
			handleChange(textValue);
			setText(textValue);
			setIsEditing(false);
			return;
		}
	}, [handleChange, text]);

	const handleClose = useCallback(() => {
		setIsEditing(false);
		setText(title);
	}, [title]);

	const handleKeyPress = useCallback(
		(event: IKeyPress): void => {
			const { key } = event;
			if (key === 'Enter') {
				handleSuccess();
			}
			if (key === 'Escape') {
				handleClose();
			}
		},
		[handleClose, handleSuccess]
	);

	return (
				<div className="editable-header__title">
					{!isEditing ? (
						<div
							className={`editable-header__${
								!readOnly ? 'text-wrapper' : 'text-wrapper-read'
							}`}
							onClick={handleIconClick}
						>
							<div className="editable-header__text">{text}</div>
							{!readOnly && (
								<i className="ri-edit-line editable-header__edit-icon" />
							)}
						</div>
					) : (
						<div
							className="editable-header__input-wrapper"
							onKeyDown={handleKeyPress}
							ref={inputRef}
						>
								<input
									type="text"
									value={text}
									onChange={handleChangeText}
									autoFocus
									className={`editable-header__input editable-header__input`}
									disabled={isProcessing}
								/>

								<div
									className="editable-header__submit-wrapper"
									onClick={handleSuccess}
								>
									<i className="ri-check-fill editable-header__submit-icon" />
								</div>
							{errorText && <div className="editable-header__iserror">{errorText}</div>}
						</div>
					)}
				</div>
	);
};
