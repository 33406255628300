import { useCallback } from 'react';

import domToImage from 'dom-to-image';
import { ITab } from 'views';

export const useDomToImg = () => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const elemModifier = (node: any) => {
		if (node.tagName !== 'i') {
			if (
				node.nodeType === 1 &&
				node.tagName === 'INPUT' &&
				('' + node.type).toLowerCase() === 'checkbox'
			) {
				if (node.checked) {
					node.setAttribute('checked', true);
				} else {
					node.removeAttribute('checked');
				}
			}
			if (
				(node.tagName === 'INPUT' &&
					(('' + node.type).toLowerCase() === 'text' ||
						('' + node.type).toLowerCase() === 'date')) ||
				node.tagName === 'TEXTAREA'
			) {
				node.style.setProperty('border', 'none', 'important');
				node.style.setProperty('outline', 'none', 'important');
				node.addEventListener('focus', () => {
					node.style.setProperty('border', 'none', 'important');
					node.style.setProperty('outline', 'none', 'important');
				});
			}
		}

		return true;
	};

	const convertNodeToImg = useCallback((compiledNodes: ITab[]) => {
		const nodes = [...compiledNodes].map(async (target) => {
			const { type } = target;
			const targetNode = document.getElementById(target._id);
			const scale = 10;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const targetNodeChild: any = targetNode?.querySelector('.signing-node');
			const textSignNode: HTMLElement | null | undefined =
				targetNode?.querySelector('.signing-text-node');

			if (textSignNode) {
				textSignNode.style?.setProperty('border-width', '0px', 'important');
			}
			if (type === 'date') {
				targetNodeChild.type = 'text';
			}

			try {
				let nodeImage: string | undefined = '';
				if (
					type === 'checkbox' ||
					type === 'date' ||
					type === 'email' ||
					type === 'initial' ||
					type === 'signature' ||
					type === 'hyperlink' ||
					type === 'name'
				) {
					nodeImage = undefined;
				}
				// else if (type === 'initial' || type === 'signature') {
				// 	// eslint-disable-next-line @typescript-eslint/no-explicit-any
				// 	const canvas = await html2canvas(targetNode as any, {
				// 		allowTaint: true,
				// 		useCORS: true,
				// 		scale,
				// 		backgroundColor: null,
				// 	});
				// 	const image = canvas.toDataURL('image/png', 1);
				// 	nodeImage = image;
				// }
				else {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					const image = await domToImage.toPng(targetNode as any, {
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						height: (targetNode as any)?.offsetHeight * scale,
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						width: (targetNode as any)?.offsetWidth * scale,
						filter: (targetNode) => elemModifier(targetNode),
						quality: 1,
						style: {
							transform: 'scale(' + scale + ')',
							transformOrigin: 'top left',
							width: targetNode?.offsetWidth + 'px',
							height: targetNode?.offsetHeight + 'px',
						},
					});
					nodeImage = image;
				}
				if (textSignNode) {
					textSignNode.style.borderWidth = `1px`;
				}
				if (targetNodeChild) {
					if (type === 'date') {
						targetNodeChild.type = 'date';
					}
				}
				return { ...target, file: nodeImage };
			} catch (error) {
				if (targetNodeChild) {
					if (type === 'date') {
						targetNodeChild.type = 'date';
					}
				}
				// eslint-disable-next-line no-console
				console.log('error', error);
				// eslint-disable-next-line no-console
				console.log('targetNode', targetNode, target._id, target);
				throw error;
			}
		});
		return nodes;
	}, []);

	return { convertNodeToImg };
};
