import type { IButtonClick } from "types";

import { useRef, useCallback, Dispatch, SetStateAction } from "react";

import { IconBtn } from "@storybook";

type Props = {
  header: string | JSX.Element;
  isOpen: boolean;
  children: string | JSX.Element;
  initialOpen?: boolean;
  actionIconButton?: string;
  handleActionClick?: (e?: IButtonClick) => void;
  showActionOnClose?: boolean;
  isError?: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>
};

export const Accordion = ({
  header,
  isOpen,
  children,
  actionIconButton,
  handleActionClick,
  showActionOnClose = false,
  isError = false,
  setIsOpen
}: Props) => {

  const containerRef = useRef<HTMLDivElement>(null);
  const handleActionClickInComponent = useCallback(
    (e: IButtonClick) => {
      if (handleActionClick) {
        handleActionClick(e);
      }
    },
    [handleActionClick]
  );


  return (
    <div ref={containerRef} className="esign-accordion-container">
      <h6 className="esign-accordion-header">
        <div className="esign-accordion-header-content">{header}</div>
        <div className="esign-accordion-header-button-container">
          {(showActionOnClose || isOpen) && actionIconButton && (
            <>
              <IconBtn
                btnClassName={actionIconButton}
                handleClickIcon={handleActionClickInComponent}
              />
            </>
          )}
          {
            !isError &&  <IconBtn
            handleClickIcon={() => setIsOpen((open) => !open)}
            btnClassName={`${ !isOpen ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line"} esign-accordion-header-down-arrow`}
          />
          }          
        </div>
      </h6>
      {isOpen && <div className="esign-accordion-body">{children}</div>}
    </div>
  );
};
