import type { IInputChange, ITextAreaChange } from 'types';

import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AutoCompleteInput, Input } from '@storybook';
import { ASSETS, ENVELOPE_PURPOSE, RECIPIENT_TYPE } from 'constant';
import { AdditionalFlag, addRecipientMessage, prepareTypeState, templateTypeState } from 'views/prepare-dashboard';
import { GetQueryParams } from 'utils';
import { SenderDetailsState } from 'views/signing-dashboard';
import { EnvelopePurposeState } from 'states';

import {
	IRecipientUser,
	IsEnableMerchantFlow,
	NameSuggestionLoadingState,
	RecipientErrorsState,
	isSigningOrderEnabledState,
	useRecipient,
} from '../store';

import { RecipientFormAction } from './recipient-form-action';
import { PrivateMessage } from './private-message';

import './recipient-form.scss';
const { DRAG_IMG } = ASSETS;

/**
 * Define the props that the AddRecipientForm component accepts
 * */
interface IProps {
	recipient: IRecipientUser;
	index: number;
	isDisabled?: boolean;
}

/**
 * Create the AddRecipientForm functional component
 * */
export const AddRecipientForm: FC<IProps> = ({ recipient, index }) => {
	/**
	 * Get recipient errors from Recoil state
	 * */
	const errors = useRecoilValue(RecipientErrorsState);

	/**
	 * State variables to track various error states
	 * */
	const [isEmailError, setIsEmailError] = useState(false);
	const [isFullNameError, setIsFullNameError] = useState(false);
	const [isMessageError, setIsMessageError] = useState(false);
	const [isTitleError, setIsTitleError] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const sender = useRecoilValue(SenderDetailsState);

	/**
	 * Get the signing order status and prepare type from Recoil state
	 * */
	const isSigningOrderEnabled = useRecoilValue(isSigningOrderEnabledState);
	const prepareType = useRecoilValue(prepareTypeState);
	const templateType = useRecoilValue(templateTypeState);
	const purpose = useRecoilValue(EnvelopePurposeState);
	const setErrors = useSetRecoilState(RecipientErrorsState);
	const isMerchantFlow = useRecoilValue(IsEnableMerchantFlow);

	/**
	 * Get the setRecipientsWithValue function from the custom useRecipient hook
	 * */
	const { setRecipientsWithValue, getRecipientsDetails, nameSuggestion } =
		useRecipient();
	const isNameSuggestionLoading = useRecoilValue(NameSuggestionLoadingState);
	const templateId = GetQueryParams('templateId');
	const { fullName: recipientFullName = '', type } = recipient ?? {};
	const { email: senderEmail = '' } = sender ?? {};


	useEffect(() => {
		setIsOpen(isMessageError);
	}, [isMessageError]);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			if (recipientFullName) {
				getRecipientsDetails(recipientFullName, senderEmail);
			}
		}, 500);

		return () => clearTimeout(timeoutId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [recipientFullName,senderEmail]);

	/**
	 * Update error state variables based on Recoil errors
	 * */
	useEffect(() => {
		if (recipient.id) {
			const error = errors[recipient.id];
			if (error?.includes('email')) {
				setIsEmailError(true);
			} else {
				setIsEmailError(false);
			}
			if (error?.includes('fullName')) {
				setIsFullNameError(true);
			} else {
				setIsFullNameError(false);
			}
			if (error?.includes('message')) {
				setIsMessageError(true);
			} else {
				setIsMessageError(false);
			}
			if (error?.includes('title')) {
				setIsTitleError(true);
			} else {
				setIsTitleError(false);
			}
		}
	}, [errors, index, recipient.id]);

	/**
	 * Handle changes to input fields (name: email, fullName, title)
	 * */
	const handleChange = useCallback(
		(event: IInputChange | ITextAreaChange, name: keyof IRecipientUser) => {
			const { value, name:recipientEmail='' } = event.target;
			
			/**
			 * Handle email field
			 * */
			if (name === 'email') {
				setIsEmailError(false);
				setRecipientsWithValue('email', value.toLowerCase(), index);
			}

			/**
			 * Handle fullName field
			 * */
			if (name === 'fullName') {
				setIsFullNameError(false);
			}

			/**
			 * Handle message field
			 * */
			if (name === 'message') {
				setIsMessageError(false);
			}

			/**
			 * Handle title field
			 * */
			if (name === 'title') {
				setIsTitleError(false);
			}

			if (name === 'fullName' && recipientEmail) {
				setIsEmailError(false);
				setRecipientsWithValue('email', recipientEmail.toLowerCase(), index);
			}

			/**
			 * Update recipient values in Recoil state
			 * */
			setRecipientsWithValue(name, value, index);

			if(errors && recipient.id) {
				setErrors((prev) => {
					const allErrors = structuredClone(prev);
					const errorData = recipient && recipient.id ? allErrors[recipient.id] : [];
					if(errorData && errorData?.includes(name) && recipient.id) {
						const index = errorData.indexOf(name);
						errorData.splice(index, 1);
						allErrors[recipient.id] = errorData;
					}
					return allErrors;
				}
				);
			}
			
		},
		[index, setRecipientsWithValue, errors, setErrors, recipient]
	);

	const disableForm = useMemo(() => {
		return purpose === ENVELOPE_PURPOSE.MULTISIGNAGREEMENT && type === RECIPIENT_TYPE.ONBOARDING
	},[purpose, type])

	const renderRecipientTitle = useMemo(() => {
		/**
		 * Condition 1: Check if templateId is falsy (not available).
		 * Condition 2: Check if prepareType is not "overlay" and templateType is not "overlay".
		 * Condition 3: Check if templateType is not "basic".
		 * */
		if (
			!templateId &&
			!(prepareType === 'overlay' && templateType === 'overlay') &&
			templateType !== 'basic'
		) {
			/**
			 * If all conditions are met, render recipient title information.
			 * */
			return (
				<div className="add-recipient__form-fields--prefilled">
					<div className="add-recipient__form-fields--label">
						Title/role/description
					</div>
					<div className="add-recipient__form-fields--value">
						{recipient.title}
					</div>
				</div>
			);
		}
		/**
		 * If any of the conditions is not met, don't render anything.
		 * */
		return <></>;
	}, [prepareType, recipient.title, templateId, templateType]);

	const renderFormFields = useMemo(() => {
		/**
		 *  Condition 1: Check if prepareType is "overlay" and templateType is "overlay" or if templateId exists.
		 * */
		if (
			(prepareType === 'overlay' && templateType === 'overlay') ||
			templateId
		) {
			/**
			 * If the condition is met, render the title input field.
			 * */
			return (
				<Input
					handleChange={(event) => handleChange(event, 'title')}
					inputType="text"
					placeholder="Enter Title/Role/Description"
					label="Title/Role/Description"
					value={recipient.title ?? ''}
					validationTrigger="change"
					isError={isTitleError}
					errorMessage={
						!recipient.title?.trim()
							? 'Please enter title/role/description'
							: 'Invalid name'
					}
					id={`${recipient.id}-title`}
					autoFocus={false}
					autoComplete="title"
					inputName="title"
				/>
			);
		}
		/**
		 * If the condition is not met, render the name and email input fields.
		 * */
		return (
			<>
				<AutoCompleteInput
					handleInputChange={(event: IInputChange) => handleChange(event, 'fullName')}
					placeholder="Enter Name"
					label="Name"
					value={recipientFullName}
					validationTrigger="change"
					isError={isFullNameError}
					errorMessage={
						!recipientFullName?.trim() ? 'Please enter name' : 'Invalid name'
					}
					id={`${recipient.id}-fullName`}
					suggestions={nameSuggestion}
					isLoading={isNameSuggestionLoading}
					suggetionKey="email"
					autoComplete = 'off'					
					disabled={disableForm}
				/>
				<Input
					handleChange={(event) => handleChange(event, 'email')}
					inputType="text"
					placeholder="Enter Email"
					label="Email"
					value={recipient.email}
					validationTrigger="blur"
					isError={isEmailError}
					errorMessage={
						!recipient.email?.trim() ? 'Please enter email' : 'Invalid email id'
					}
					id={`${recipient.id}-email`}
					autoComplete="off"
					inputName="email"
					disabled={disableForm}
				/>
			</>
		);
	}, [
		handleChange,
		isEmailError,
		disableForm,
		isFullNameError,
		isNameSuggestionLoading,
		isTitleError,
		nameSuggestion,
		prepareType,
		recipient.email,
		recipient.id,
		recipient.title,
		recipientFullName,
		templateId,
		templateType,
	]);

	const handleMandatoryOnboardingFlow = useCallback((checked: boolean) => {
		const value = checked ? "onboarding" : "default"
		setRecipientsWithValue("recipientLocalType", value, index);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[index])

	const showOnboardingUserToggle = false;

	return (
		<div className="add-recipient__form-container">
			{isSigningOrderEnabled && (
				<div className="add-recipient__recipient-list_number">{index + 1}</div>
			)}
			<div className="add-recipient__form">
				<div
					className="add-recipient__card-color"
					style={{ borderLeftColor: recipient.color }}
				>
					<div className="add-recipient__handle-container">
						{!(
							prepareType === 'envelope' &&
							templateType === 'overlay' &&
							purpose !== 'multisignAgreement'
						) ? (
							<img src={DRAG_IMG} alt="drag-handle" />
						) : (
							<div />
						)}
						<div className="add-recipient__controls-container">
							<div className="add-recipient__form-actions-wrapper">
								<div className="add-recipient__signer">{`Signer ${
									index + 1
								}`}</div>
								<RecipientFormAction
									index={index}
									recipient={recipient}
									setPrivateMessageOpen={setIsOpen}
								/>
							</div>
							{renderRecipientTitle}
							<div className="add-recipient__form-fields">
								{renderFormFields}
							</div>
							{isMerchantFlow && showOnboardingUserToggle && (
								<div style={{ marginTop: '8px' }}>
									<AdditionalFlag
										handleChange={handleMandatoryOnboardingFlow}
										isDisabled={recipient?.type === 'onboarding'}
										value={recipient?.recipientLocalType === 'onboarding'}
										description={
											addRecipientMessage.mandatoryOnboarding.description
										}
										heading={addRecipientMessage.mandatoryOnboarding.heading}
										warning={addRecipientMessage.mandatoryOnboarding?.warning}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
				{recipient?.isPrivateMessageEnabled && (
					/**
					 * Render PrivateMessage component if private message is enabled
					 * */
					<PrivateMessage
						handleChange={handleChange}
						index={index}
						isMessageError={isMessageError}
						recipient={recipient}
						isOpen={isOpen}
						setIsOpen={setIsOpen}
					/>
				)}
			</div>
		</div>
	);
};
