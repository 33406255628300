import { useMemo } from 'react';

import { ASSETS } from 'constant';
import { useRecoilValue } from 'recoil';
import { organizationDetailState } from 'states';

import { SignDocumentState } from '../store';

export const VoidScreen = () => {
	const getSignDocumentState = useRecoilValue(SignDocumentState);
	const organization = useRecoilValue(organizationDetailState);

	const { VOIDED_ERROR_SVG, BRAND_LOGO } = ASSETS;

	const renderOrganizatonLogo = useMemo(() => {
		if (organization?.logo) {
			return organization?.logo;
		} else {
			return BRAND_LOGO;
		}
	}, [BRAND_LOGO, organization]);

	return (
		<>
			<img src={renderOrganizatonLogo} alt="" className="simplici-logo"/>
			<div className="void-screen">
				<img src={VOIDED_ERROR_SVG} alt="" />
				<div className="void-screen__details">
					<div className="void-screen__title">Document voided</div>
					<div className="void-screen__description">
						We regret to inform you that the document titled{' '}
						{getSignDocumentState?.data?.name} has been voided.
					</div>
					<div className="void-screen__support">
						<div className="void-screen__disclaimer">
							The reason for voiding the document is{' '}
							{getSignDocumentState?.data?.statusMessage}
						</div>
						<div className="void-screen__footer">
							<div>
								<i className="ri-information-line void-screen__footer_icon"></i>
							</div>
							<div className="void-screen__footer_text">
								<span className="void-screen__footer_text-note">Note: </span>
								<span>
									This document is no longer valid, and any previous signatures
									or actions associated with it are no longer in effect.
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
