import type { IKeyPress } from 'types';

import { useCallback, useEffect, useMemo, useRef } from 'react';

import { useSetRecoilState, useRecoilValue } from 'recoil';
import { CreateSignModal } from 'views';

import {
	CompiledSigningNodesState,
	IPage,
	ITab,
	SignDocSelector,
	completedRecipientsTabsState,
	nodeRefsState,
	selectedNodeState,
} from '../store';
import { SignNode } from '../sign-node';

export const PdfSigner = () => {
	const nodeRefs = useRef<HTMLDivElement[]>([]);

	const setNodeRefList = useSetRecoilState(nodeRefsState);
	const selectedNode = useRecoilValue(selectedNodeState);
	const nodes = useRecoilValue(CompiledSigningNodesState);
	const completedRecipientsNodes = useRecoilValue(completedRecipientsTabsState);

	const { compiledDocs, recipient } = useRecoilValue(SignDocSelector);

	const totalNodes = useMemo(
		() => {
			const isSignComplete = /complete/gi.test(recipient.status);
			return !isSignComplete ? nodes.concat(completedRecipientsNodes) : completedRecipientsNodes;
		}, 
		[completedRecipientsNodes, nodes, recipient]
	);
	const renderedItems = useCallback(
		(pageNumber: number, docId: string, page: IPage): JSX.Element => {
			const { _id: pageId, height, width } = page;
			return (
				<>
					{totalNodes.map((node: ITab, index: number) => {
						return (
							<SignNode
								key={`${docId}-${index}`}
								index={index}
								node={node}
								nodeRefs={nodeRefs}
								docId={docId}
								pageNumber={pageNumber}
								pageId={pageId}
								pageHeight={height}
								pageWidth={width}
							/>
						);
					})}
				</>
			);
		},
		[totalNodes]
	);

	const renderImages = useMemo(() => {
		return compiledDocs.length ? (
			compiledDocs.map((doc) => {
				return doc.pages.map((page, index) => {
					return (
						<div key={page._id} id={page._id}>
							<div
								className="pdf-signer-container__image-wrapper"
								style={{ height: page.height, width: page.width }}
							>
								<>
									<img
										className="pdf-signer-container__pdf-img"
										src={page.path}
										alt={index + 'image'}
										key={index}
									/>
									{renderedItems(index + 1, doc._id, page)}
								</>
							</div>
							<div className="pdf-signer-container__doc-description">
								<span>{doc.name}</span>
								<span>{`Page ${index + 1} of ${doc.pages.length}`}</span>
							</div>
						</div>
					);
				});
			})
		) : (
			<div className="pdf-signer-container__placeholder">
				failed to load the document
			</div>
		);
	}, [compiledDocs, renderedItems]);

	const handleKeyDown = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(event: IKeyPress | any) => {
			if (event.keyCode === 13) {
				// handleSelectNode(selectedNode);
				// eslint-disable-next-line no-console
				console.log(selectedNode);
			}
		},
		[selectedNode]
	);

	useEffect(() => {
		setNodeRefList({ current: [...nodeRefs.current] });
	}, [nodeRefs, setNodeRefList, nodes]);

	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedNode]);

	return (
		<div className="pdf-signer-container" id="random-pdf-random-container">
			<div className="pdf-signer-container__content-wrapper" id="pdf-wrapper">
				{renderImages}
			</div>
			<CreateSignModal />
		</div>
	);
};
