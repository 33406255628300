import { InvestorColumnTabs } from "./constants";
import { IFieldNodeType, IMenuTab, ITabMenu } from "./types";

export const menuItemIcon = (nodeType: IFieldNodeType) => {
  if (!nodeType) {
    return "ri-align-right"
  }
  switch (nodeType) {
    case "checkbox":
      return "ri-checkbox-line";
    case "date":
      return "ri-calendar-line";
    case "email":
      return "ri-mail-line";      
    case "initial":
      return "ri-font-size";
    case "hyperlink":
      return "ri-link-m";
    case "name":
      return "ri-user-line";
    case "signature":
      return "ri-admin-line";
    case "text":
      return "ri-align-right";
    default:
      return "ri-align-right";
  }
};


export const handleInvestorsQuestionnair = (tabs: ITabMenu[]) => {
  const newTabs:ITabMenu[]  = []
  tabs.forEach((tab) => {
    if (tab.questionType === "investors-detail") {
      const investorTabs = InvestorColumnTabs(tab.source as IMenuTab)
      newTabs.push(...investorTabs)
    }else {
      newTabs.push(tab)
    }
  });
  return newTabs;
}