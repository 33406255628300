/* eslint-disable no-console */
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Loader } from '@storybook';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	IFacialBioMetricDoneState,
	IsFacialModalOpen,
} from 'views/facial-biometric/store';
import { IsBiomentricSupported } from 'utils';
import { useUpdateDeviceInfo, useWebAuthentication } from 'hooks';
import {
	ENV_STATE,
	EnvState,
	isShowConfigDocScreen,
	isShowSelfSignDocScreen,
	webComponentEnvelopeIdState,
} from 'states';
import { IAuthUserAuthType } from 'views/signing-dashboard';

import { useCompleteSign } from '../store/use-complete-sign';
import { SelfSignSuccessState, selfSignSelector } from '../store';

// Footer component responsible for rendering buttons and handling actions
export const Footer = () => {
	// Hooks for handling state and navigation
	const navigate = useNavigate();
	const { completeSign, isCompleteDisabled } = useCompleteSign();
	const setSuccess = useSetRecoilState(SelfSignSuccessState);
	const setFacialModal = useSetRecoilState(IsFacialModalOpen);
	const setShowEditorScreen = useSetRecoilState(isShowConfigDocScreen);
	const [showSelfSign, setShowSelfSign] = useRecoilState(
		isShowSelfSignDocScreen
	);
	const facialBiometricStatus = useRecoilValue(IFacialBioMetricDoneState);
	const envelopeId = useRecoilValue(webComponentEnvelopeIdState);
	const userAuthType = useRecoilValue(IAuthUserAuthType);
	const env = useRecoilValue(EnvState);
	
	const [isLoaded, setIsLoaded] = useState(true);
	const { registerNewCredentials, authenticate,getRegistrations } = useWebAuthentication();
	const { selfSigner, sender } = useRecoilValue(selfSignSelector);
	const { patchDeviceInfo } = useUpdateDeviceInfo();

	// Destructuring necessary properties
	const { fullName, email, _id } = selfSigner ?? {};
	const { _id: senderId } = sender ?? {};

	// Callback to handle success event after authentication
	const handleSuccess = useCallback(() => {
		patchDeviceInfo('sender', envelopeId, senderId);
		patchDeviceInfo('signer', envelopeId, _id);
	}, [_id, envelopeId, patchDeviceInfo, senderId]);

	// Callback to authenticate user using biometric or fallback
	const handleAuthenticateUser = useCallback(async () => {
		try {

			// Check environment and handle success if not staging
			if (!(env === ENV_STATE.STAGE || env === ENV_STATE.PREPROD)) {
				console.log("Environment is not staging or preprod. web authen by-passed");
				handleSuccess();
				return { success: true };
			}

			const isBiometricAvailable = await IsBiomentricSupported();
			if (
				window.self !== window.top ||
				!isBiometricAvailable
			) {
				// eslint-disable-next-line no-console
				console.warn('web authentication is not supported in iframe');
				handleSuccess();
				return {success: true};
			}
				const isAlreadyExist = getRegistrations().find(
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					(item: any) => item.email === email
				);

				if (!isAlreadyExist) {
					const registerPayload = {
						email: email || '123456****',
						id: _id || '123456****',
						displayName: fullName || '123456****',
					};
					const resp = await registerNewCredentials(handleSuccess, registerPayload);
					return resp
				}
				const resp = await authenticate(handleSuccess, () => ({}));
				return resp;
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('error', error);
			return;
		}
	}, [_id, authenticate, email, env, fullName, getRegistrations, handleSuccess, registerNewCredentials]);

	// Callback to complete sign after user authentication
	const handleFinish = useCallback(async () => {
		const resp = await handleAuthenticateUser();
		if (!resp) {
			return setIsLoaded(true);
		}
		const status = await completeSign();
		setIsLoaded(true)
		if (status) {
			setSuccess(status);
		}
	}, [completeSign, handleAuthenticateUser, setSuccess]);

	// Callback to initiate facial biometric verification
	const handleCompleteSign = useCallback(async () => {
		if (!isLoaded) {
			return;
		}
		setIsLoaded(false)
		if (userAuthType === 'facial') {
			setFacialModal(true);
		}else {
			handleFinish();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleFinish, userAuthType]);

	// Callback to navigate back
	const handleBack = useCallback(() => {
		if (showSelfSign) {
			setShowEditorScreen(false);
			setShowSelfSign(false);
		} else {
			navigate(-1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate, showSelfSign]);

	// Use effect to handle completion after facial biometric verification
	useEffect(() => {
		async function FacialFinalSubmit() {
			if (facialBiometricStatus) {
				setTimeout(() => {
					handleFinish();
				}, 1000);
			}
		}
		FacialFinalSubmit();
	}, [facialBiometricStatus, handleFinish]);

	// Button label based on loading state
	const PrimaryBtnLabel = useMemo(() => {
		if (!isLoaded) {
			return <Loader className="loader-white" dimension={20} />;
		}
		return 'Finish Sign';
	}, [isLoaded]);

	// Rendering Back and Finish Sign buttons
	return (
		<Fragment>
			<div className="self-sign__footer-btn-wrapper">
				<Button
					className={`esign-button__filled esign-button__filled--secondary esign-button__large esign-button__block`}
					label="Back"
					handleClick={handleBack}
				/>
			</div>
			<div className="self-sign__footer-btn-wrapper">
				<Button
					className={`esign-button__filled esign-button__filled--primary esign-button__large esign-button__block`}
					label={PrimaryBtnLabel}
					handleClick={handleCompleteSign}
					disabled={isCompleteDisabled}
				/>
			</div>
		</Fragment>
	);
};
