import type { ICreateSignState, IGenerateSignatures, ISignType } from "./types";

import { atom } from "recoil";

export const IsCreateSignModalOpen = atom<boolean>({
  key: "is-create-sign-modal-open",
  default: false,
});

// these are for the state of the for the signature pad
export const SignPadColorState = atom<string>({
  key: "draw-sign-color-code",
  default: "#000000",
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SignPadDesignState = atom<any>({
  key: "sign-pad-design-state-key",
  default: null,
});

export const CreateSignState = atom<ICreateSignState>({
  key: "create-sign-state-key",
  default: {
    fullName: "",
    initials: "",
    generate: {
      id: "",
      sign: "",
      initials: "",
    },
    draw: {
      sign: "",
      initials: "",
      canvasDataCoordinates: [],
    },
    upload: {
      sign: "",
      initials: "",
    },
    consent: false,
    isEdit: false,
  },
});

export const PreserveCreateSignAPIState = atom<ICreateSignState>({
  key: "create-preserve-sign-state-key",
  default: {
    fullName: "",
    initials: "",
    generate: {
      id: "",
      sign: "",
      initials: "",
    },
    draw: {
      sign: "",
      initials: "",
      canvasDataCoordinates: [],
    },
    upload: {
      sign: "",
      initials: "",
    },
    consent: false,
    isEdit: false,
  },
});



export const UploadSignState = atom<string>({
  key: "upload-sign-state-key",
  default: "",
});

export const GenerateSignImagesState = atom<IGenerateSignatures[]>({
  key: "generate-sign-image-state-key",
  default: [],
});

export const activeTabState = atom<ISignType>({
  key: "active-tab",
  default: "generate",
});

export const selfSignNodeType = atom<string>({
  key: "self-sign-drop-node-type",
  default: "",
});