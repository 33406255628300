export const FadeHexColor = (color: string, opacity: number): string => {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };
  
  export const DarkenHexColor = (color: string, opacity: number): string => {
    const hex = color.replace('#', '');
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Decrease RGB values to darken the color
    const darknessFactor = 0.7; // Adjust this value to control darkness (0 to 1)
    r = Math.floor(r * darknessFactor);
    g = Math.floor(g * darknessFactor);
    b = Math.floor(b * darknessFactor);

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};  