import type { ITextAreaFocusEvent, ITextAreaChange } from "types";

import { FC, useRef, useEffect } from "react";

import "./text-area.scss";

interface ITextArea {
  label: string;
  handleChange?: (e: ITextAreaChange) => void;
  value: string;
  placeholder: string;
  isError?: boolean;
  row?: number;
  handleBlur?: (e: ITextAreaFocusEvent) => void;
  maxLength?: number;
  autoFocus?: boolean;
  id?: string;
}

export const TextArea: FC<ITextArea> = ({
  label,
  handleChange,
  value,
  placeholder,
  isError = false,
  row = 6,
  handleBlur,
  maxLength,
  autoFocus,
  id,
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = value;
    }
  }, [value]);
  return (
    <div className="input">
      <div className="input__label">{label}</div>
      <div
        className={`input__text-field input__text-field${
          isError ? "--error" : ""
        }`}
      >
        <textarea
          id={id}
          autoFocus={autoFocus}
          name="some area"
          typeof="text"
          className="input__field text-area-field"
          rows={row}
          placeholder={placeholder}
          onChange={typeof handleChange == "function" ? handleChange : () => ({})}
          value={value}
          maxLength={maxLength}
          onBlur={typeof handleBlur == "function" ? handleBlur : () => ({})}
        />
      </div>
    </div>
  );
};
