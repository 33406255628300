import { FC } from 'react'

import { Button, Modal } from '@storybook'
import { TEMPLATE_NAME_WANRING_STRING } from 'views/editor-dashboard/store'

interface IWarningModal {
    showModal: boolean,
    onClose: () => void,
    onProceed: () => void,
}

export const WarningModal: FC<IWarningModal> = ({
    onClose,
    onProceed,
    showModal
}) => {

    return(
        <Modal
            isOpen={showModal}
            modalName="Warning Modal"
            closeModal={onClose}
            className="warning-modal"
            showCloseBtn={true}
            isStopOutsideClick={false}
        >
            <div className="warning-modal__header">Note</div>
			<div className="warning-modal__body">

                <p>{TEMPLATE_NAME_WANRING_STRING.LABEL}</p>
            </div>
        
            {/* Render a close button */}
			<div className="warning-modal__footer">
				<Button
					className="esign-button__filled esign-button__filled--secondary esign-button__large"
					handleClick={onClose}
					label="Close"
				/>
                <Button
					className="esign-button__filled esign-button__filled--primary esign-button__large"
					handleClick={onProceed}
					label="Proceed"
				/>
			</div>
			
        </Modal>

    )
}