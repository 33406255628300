import type { IInputChange } from 'types';

import { FC, KeyboardEvent, useCallback, useState } from 'react';

import { useRecoilState, useSetRecoilState } from 'recoil';
import { v4 } from 'uuid';
import { Button, Input, Modal } from '@storybook';
import { CustomTabState, DropItemsState, ITabMenu } from 'views';

type Props = {
	closeModal: () => void;
	item?: ITabMenu;
};

export const CustomTabModal: FC<Props> = ({ closeModal, item }) => {
	const [label, setLabel] = useState(item?.label ?? '');
	const [customTabs, setCustomTabs] = useRecoilState(CustomTabState);
	const [customError, setCustomError] = useState('');
	const setDropItems = useSetRecoilState(DropItemsState);
	const handleSave = useCallback(() => {
		/**trimmed value of label */
		const trimmedLabel = label.trim();
		let customFiledExists = false;
		if (trimmedLabel) {
			if (item) {
				customFiledExists = !!customTabs.find(
					({ label, _id }) =>
						label.toLowerCase() === trimmedLabel.toLowerCase() &&
						_id !== item.id
				);
			} else {
				customFiledExists = !!customTabs.find(
					({ label }) => label.toLowerCase() === trimmedLabel.toLowerCase()
				);
			}
			if (customFiledExists) {
				setCustomError(`${trimmedLabel} already exists`);
				return;
			}
			const input = document.querySelector('#custom-menu-tab-heading');
			if (input) {
				(input as HTMLDivElement).focus();
			}
			//if update update name and label of dropped items
			if (item) {
				setDropItems((prev) =>
					prev.map((dropItem) =>
						dropItem.label === item?.label && dropItem.key === item.label
							? {
								...dropItem,
								label: trimmedLabel,
								key: trimmedLabel,
								metadata: {
									...dropItem.metadata,
									editText: trimmedLabel,
								},
							}
							: dropItem
					)
				);
			}
			setCustomTabs((prev) => {
				//if it is an update
				if (item) {
					return prev.map((customTab) =>
						item.id === customTab._id
							? { ...customTab, label: trimmedLabel, name: trimmedLabel }
							: customTab
					);
				}
				// when new item is added
				return [
					...prev,
					{
						_id: v4(),
						name: trimmedLabel,
						label: trimmedLabel,
						type: 'text',
						source: 'custom',
						isEditable: false,
						icon: 'ri-admin-right',
						recipientId: null,
						index: null,
						createdAt: new Date().toDateString(),
						isNew: true,
					},
				];
			});
			closeModal();
		}
	}, [closeModal, customTabs, item, label, setCustomTabs, setDropItems]);

	const handleChange = (e: IInputChange) => {
		setCustomError('');
		setLabel(e.target.value);
	};

	const handleClose = useCallback(() => {
		const input = document.querySelector('#custom-menu-tab-heading');
		if (input) {
			(input as HTMLDivElement).focus();
		}
		closeModal();
	}, [closeModal]);

	return (
		<Modal
			isOpen={true}
			modalName=""
			closeModal={handleClose}
			title=""
			isStopOutsideClick={true}
			className="create-custom-tab__modal"
		>
			<div className="create-custom-tab__header">
				{item ? 'Edit' : 'Create'} Field
			</div>
			<div className="create-custom-tab__body">
				<Input
					label={'Name'}
					inputType={'text'}
					placeholder={'Field name'}
					maxLength={12}
					required={true}
					handleChange={(e: IInputChange) => {
						handleChange(e);
					}}
					handleKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
						if (e.key === 'Enter') {
							handleSave();
						}
					}}
					value={label}
					autoFocus={true}
					isError={!!customError}
					errorMessage={customError}
				/>
			</div>
			<div className="create-custom-tab__footer">
				<Button
					label={item ? 'Save' : 'Add'}
					className="esign-button__filled esign-button__filled--primary esign-button__large"
					disabled={!label || !label.trim()}
					handleClick={handleSave}
				/>
			</div>
		</Modal>
	);
};
