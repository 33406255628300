import { selector } from 'recoil';
import { IWorkflow, PrepareDashboardState, Recipient } from 'views/prepare-dashboard';
import { IDocument } from 'views/signing-dashboard';

export const selfSignSelector = selector({
	key: 'self-sign-selector-state-key',
	get: ({ get }) => {
		const envelope = get(PrepareDashboardState) as IWorkflow;
		const { recipients, sender, documents, name } = envelope ?? {};
		const selfSigner = recipients?.[0] ?? {} as Recipient;
		const document = documents?.[0] ?? {} as IDocument;
		let documentName = name
		if('document' in document){
			documentName = document?.document?.name.replace(".pdf", "") || name;
		}
		// const documentName = document?.document.name.replace(".pdf", "");
		return { selfSigner, sender, envelopeId: envelope?._id, documentName };
	},
});
