import { FC, useEffect } from 'react';

import { ASSETS } from 'constant';
import { useRecoilValue } from 'recoil';
import { EnvelopePurposeState } from 'states';
import { GifLoader } from '@storybook';
import { WebComponentMemory } from 'views/routes';
interface SuccessType {
	message: string;
	description?: string;
	showLoader?: boolean;
}

export const SuccessScreen: FC<SuccessType> = ({
	message,
	description = '',
	showLoader,
}) => {
	const purpose = useRecoilValue(EnvelopePurposeState);
	const { SUCESS_IMG } = ASSETS;
	
	useEffect(() => {
		setTimeout(() => {
			// eslint-disable-next-line no-console
			WebComponentMemory.handleFinish(true, purpose)			
		}, 1000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (showLoader) {
		return (
			<div className="Esign-signing-dashboard__loader">
				<GifLoader />
			</div>
		);
	}

	return (
		<div className="verification__success">
			<img src={SUCESS_IMG} alt="" />
			<div className="verification__success-message">{message}</div>
			<div className="verification__success-description">{description}</div>
		</div>
	);
};
