/* eslint-disable @typescript-eslint/no-explicit-any */

import { CSSProperties, FC, useCallback, useMemo } from 'react';

import { DateCalender } from '@storybook';
import { DateTime } from 'luxon';
import { DayValue } from '@hassanmojab/react-modern-calendar-datepicker';
import { formatDate, getDate } from 'utils';
import { getCalenderDirection } from '@storybook/react-date-picker/utils';
import { IDropedItems } from 'views/editor-dashboard';

interface IInputField {
	value: string | undefined; // The current value of the input field
	handleChange: (id: string, e: string) => void; // Function to handle the change event
	id: string; // The unique identifier for the input field
	className: string; // The CSS class for the input field
	name: string; // The name attribute for the input field
	pageWidth: number;
	pageHeight: number;
	node: IDropedItems;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	style?: CSSProperties | any; // Optional custom styles for the input field
	format?: string
}

/**
 * DateField component renders a date input field with custom styling and functionality.
 *
 * @param {IInputField} props - The props for the DateField component.
 * @param {string | undefined} props.value - The current value of the input field.
 * @param {(id: string, e: IInputChange) => void} props.handleChange - Function to handle the change event.
 * @param {string} props.id - The unique identifier for the input field.
 * @param {string} props.className - The CSS class for the input field.
 * @param {string} props.name - The name attribute for the input field.
 * @param {CSSProperties | any} [props.style] - Optional custom styles for the input field.
 *
 * @returns {JSX.Element} The rendered date input field.
 */
export const DateField: FC<IInputField> = ({
	value,
	handleChange,
	id,
	className,
	style,
	name,
	pageHeight,
	pageWidth,
	node,
	format
}) => {
	const { fontSize, fontFamily, color } = style ?? {};
	// Set minimum and maximum dates for the input field

	// useMemo to calculate minimum height based on font size, runs only when fontSize changes
	const getMinHeight = useMemo(() => {
		const { value } = fontSize ?? {};
		if (value) {
			// Parse font size value, remove 'px' if present, and add additional 4px for padding
			const fontSizeNumber = parseInt(`${value}`.replace('px', '')) + 4;
			return fontSizeNumber;
		}
		return 12; // Default minimum height
	}, [fontSize]);

	// Determine if the input should be disabled based on the name prop
	const isDisabled = /signedDate/.test(name);

	const customStyle: CSSProperties = {
		fontSize: fontSize?.value, // Apply font size from style prop
		color, // Apply color from style prop
		fontFamily: fontFamily?.value, // Apply font family from style prop
		minHeight: getMinHeight,
	};

	const formattedDate: DayValue = useMemo(() => {
		const date = formatDate(value as string, 'mm/dd/yyyy');
		const now = DateTime.fromFormat(date, 'MM/dd/yyyy');
		if(isNaN(now?.month)){
			return null;
		}
		const currentMonth = now?.month; // Month (1-12);
		const currentYear = now?.year; // Year (e.g., 2024);
		const currentDay = now?.day; // Day of the month (1-31);
		const data = {
			year: currentYear,
			month: currentMonth,
			day: currentDay,
		};
		return data;
	}, [value]);

	const handleDateChange = useCallback(
		(event: any) => {
			const { value } = event.target;
			if (value) {
				const { day, year, month } = value;
				const dateValue = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
				const newValue = !isNaN(day) ? dateValue : getDate(new Date());
				handleChange(id, newValue);
			}
		},
		[handleChange, id]
	);

	const direction = useMemo(
		() => getCalenderDirection(pageWidth, pageHeight, node.position),
		[node, pageHeight, pageWidth]
	);
	return (
		<DateCalender
			label=""
			optionalClassName={className}
			onChange={handleDateChange}
			value={formattedDate}
			id={id}
			formate={format === '' ? undefined : format}
			direction={direction}
			inputStyle={customStyle}
			isDisabled={isDisabled}
			background="transparent"
		/>
	);
};
