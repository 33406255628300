import { useCallback, useMemo } from 'react';

import { ASSETS } from 'constant';
import { useRecoilValue } from 'recoil';
import { IsCommonField } from 'views/reciepient-modal';
import classNames from 'classnames';

import {
	ConfigTabMenuState,
	MenuItems,
	SearchedMenuNameState,
	TabConfigurationState,
} from '../..';

interface SearchedItemsProps {
	isFocus: boolean;
}

export const SearchedItems: React.FC<SearchedItemsProps> = ({ isFocus }) => {
	const { menuTabs } = useRecoilValue(ConfigTabMenuState);
	const searchedText = useRecoilValue(SearchedMenuNameState);
	const tabConfiguration = useRecoilValue(TabConfigurationState);
	const isCommonField = useRecoilValue(IsCommonField);
	const { EMPTY_DATA_SVG } = ASSETS;
	const {questionnaire} = tabConfiguration;

	/**
	 * This code uses the !(condition) syntax to check the negation of the conditions,
	 * making it more concise. It excludes "compliance" if kyc is false and excludes "questionnaire"
	 * if questionnaire is false. The rest of the buttons are included in the result.
	 * This solution is both shorter and functionally equivalent to the previous one.
	 * */
	const getSearchedTabs = useCallback(
		(key: string) => {
			const { kyc, questionnaire, kyb } = tabConfiguration;
			// when common tab is active return only common tabs in search list
			if (isCommonField) {
				return key?.toLowerCase() === 'common';
			} else {
				return (
					!(key?.toLowerCase() === 'compliance' && !kyc) &&
					!(key?.toLowerCase() === 'questionnaire' && !questionnaire) &&
					!(key?.toLowerCase() === 'kyb' && !kyb) &&
					!(key?.toLowerCase() === 'common' && !isCommonField)
				);
			}
		},
		[isCommonField, tabConfiguration]
	);

	const filteredMenuTabs = useMemo(() => {
		const tabs = JSON.parse(JSON.stringify({ ...menuTabs }));
		const keys = Object.keys(tabs);
		const filterredKeys = keys.filter((key) => getSearchedTabs(key));
		let tabsLength = 0;
		filterredKeys.forEach((key) => {
			const { items = [] } = tabs[key] ?? {};
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const filtered = items.filter((item: any) => {
				return (
					item.label.toLowerCase().includes(searchedText.toLowerCase()) ||
					item.key.toLowerCase().includes(searchedText.toLowerCase())
				);
			});
			tabsLength += filtered.length;
			tabs[key].items = filtered;
		});
		return { tabs, tabsLength };
	}, [getSearchedTabs, menuTabs, searchedText]);
	
	const renderedMenuTabs = useMemo(() => {
		const keys = Object.keys(filteredMenuTabs.tabs);
		const filterredKeys = keys.filter((key) => getSearchedTabs(key));
		return filterredKeys.map((key) =>
			filteredMenuTabs.tabs[key].items.length ? (
				<MenuItems menu={filteredMenuTabs.tabs[key]} key={key} />
			) : (
				<></>
			)
		);
	}, [filteredMenuTabs.tabs, getSearchedTabs]);

	const searchItemsData = useMemo(() => classNames('search-items__data', {
		'isSearchItemWithQuestionaire': isFocus && questionnaire,
	}), [isFocus, questionnaire]);

	return (
		<>
			<div className="search-items">
				<div className="search-items__header">
					<div className="search-items__header-text">Search Results :</div>
					<span className="search-items__header-badge">
						{filteredMenuTabs.tabsLength}
					</span>
				</div>
				{!filteredMenuTabs.tabsLength ? (
					<div className="search-items__empty-state">
						<img src={EMPTY_DATA_SVG} alt="" />
					</div>
				) : (
					<div className={searchItemsData}>{renderedMenuTabs}</div>
				)}
			</div>
		</>
	);
};