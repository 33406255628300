import React from "react";

import { IRecipientUser, IUndoModalMessages } from "./types";

export const regexNeedsToSign = /needs\sto\ssign/i;

export const newRecipient: IRecipientUser = {
  fullName: "",
  email: "",
  action: "Needs to sign",
  role: "signer",
  title: "",
  originalCopy: false,
  status: "pending"
};

export const ACTION_DROP_DOWN_OPTIONS = [
  {
    label: "Needs to Sign",
    value: "Needs to sign",
    icon: React.createElement("i", {
      style: { color: "#747E99" },
      className: "ri-ball-pen-line",
    }),
  },
  {
    label: "Receives a Copy",
    value: "Receives a copy",
    icon: React.createElement("i", {
      style: { color: "#747E99" },
      className: "ri-creative-commons-fill",
    }),
  },
];

export const dragHandleImage =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABwSURBVHgB7ZFBDYAwDEX/rwIkcEUNErCAhEmYhUnACwKQMANQemAZYVk47rJ36U9e89OkxEPY9kVUvAJRSbfMU8CLmpe0QBVnYyAwil4eH2o+F5jMmbEoqPhcQK62dFiMp51YFPz4djCF/oWG9C8AN65jbEaE+iFyAAAAAElFTkSuQmCC";

export const ActionMenuEntries = [
  {
    label: "Add private message",
    value: "add-private-message",
  },
];
export const completedImage =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAN3SURBVHgBzZnPSxRRHMC/782bH7vqriLqQZMQoRAVog5BFzsFkdBBOnXoHxA6RARdtkMQ5EHoXpcOHboEEeilbhEUpUJ08BQJiYjuD3d3fr3X+47Ournj7sw6++MDurMz83Y+fN/Md9/3uwSaZPLDoq6kEloypxjcpZqrAVUdoHjMZsD1gnB4QjhU5VbWLpY3b74woQlIpLMzGToxVepLplnKlwkL5+CYNi//Wtez8nOcsOPCCUqxmSvlNCNKCmKgZPJCWNGGgpOvF1PGUCodNWKNwIgKzdxfv7F0UO+8uoIz7x8NxBW109gVbu73rWd7px0PFpRTOn3JGlIZMaAN0LKwvm1o2/K6vOZY0IDLM9ZIu+QQbhANAxJ0rEYQpxUHQJvBgIzLa5/c/5/g7Mrznlbfc/UYlNe+8O5hX/W+Y8FMhhFrvx86jCrUfnwG/PeVjYuzZppSYNBhVBXouMy5/vtDQRm9hE57oUvAqfaj6P3D6EGXMXGt5N2LnqCu0rallLAkD5j3sJK5V/eM7PDoCLQaIhYogauckwdhh+TJyF+WHxvRwYLWgnIUlrxNha8Kl66GGabYfzTGbdrapFwtx8kyFySUHJJUFYNSSlqXWk7IuQKWowzHhTA1SURBIqaovHCI884kh7guUBZlnUeBpoC6b+RminIBcrreQovkEEza0ZbtwHMEyMsj26XASMYk50OxwIkyQIZ9WUouB0rGLGfbwJliSUEabTmPkopCQIC470nidKNvjHLedQzhMKpwCwSN/CTXSB7tj0sOMbgsW/NQaKpe9SUr0x2zHII1NRvbHrSywzY0ix9JnOM45ZDs+dGyVzRNrzw+F3dZeVawLF2bf7rlSbHiQR66DD3By/jqCf5Y689Bl5FUZOcBjlfU3JEFNHQJqs4Ln64ftkUq993GVyMbNWm3Arz3/Ogh1VUd79lhWegwdmkv60cPqWl9tKMfcxp4m22c6NPUpBY8QXNEGdoM9mc2AppIgbnvy3dthyqi1YVABU0X5XlsHgXQ8fZb0LRWU/fbAweaB+ouPlkQM5gx8ntsr54c0vDr7eedTGGgV93G3AQxgVFb+Kxubd590jD3Rmqiz33MsKJrps0SNaL2cTBirKjmb69BLhPQqIxFsBopa+Sdko5lK1aGWHxV/wyhyD9qcguXc2OJQUvmtqYywz8fFozGWQyjsQAAAABJRU5ErkJggg==";

  export const UndoModalMessages: IUndoModalMessages = {
		header: 'Sending invite for document signing',
		footer: 'Sending invite in ',
	};

  export const counterSecond = 10;
