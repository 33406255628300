import { selector } from "recoil";

import { RecipientLocalState, RecipientsState } from "./state";
import { regexNeedsToSign } from "./constant";

export const signerRecipientsState = selector({
  key: "signer-recipients",
  get: ({ get }) => {
    const state = get(RecipientsState);
    return state.filter(({ action, status }) => {
      return !!action.match(regexNeedsToSign) && status !== "completed" 

    });
  },
});

export const recipientsSelector = selector({
	key: 'recipients-selector',
	get: ({ get }) => {
		const localRecipients = get(RecipientLocalState);

		const isNeedToSignRequired = localRecipients.some(
			(rec) => !!rec.action.match(regexNeedsToSign) && rec.status === 'pending'
		);

		return { isNeedToSignRequired };
	},
});
