import { useMemo } from 'react';

import { useDrop } from 'react-dnd';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { IsConfigDrawerOpen, IsShowMessage } from 'views/config-items';
import {
	ConfigTabMenuState,
	DeleteNodesZone,
	DragTypes,
	DropItemsState,
	ITabFields,
	ITabMenu,
	MenuItems,
} from 'views/editor-dashboard';

export const FieldsSidebar = () => {
	const setDroptItems = useSetRecoilState(DropItemsState);
	const { menuTabs } = useRecoilValue(ConfigTabMenuState);
	const setIsDrawerOpen = useSetRecoilState(IsConfigDrawerOpen);
	const setShowMessage = useSetRecoilState(IsShowMessage);

	const [{ isOver }, dropRef] = useDrop({
		accept: DragTypes.DroppedItem,
		collect: (monitor) => ({
			isOver: monitor.isOver() && monitor.canDrop(),
		}),
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		drop: (item: any) => {
			if (item.id) {
				setDroptItems((dropItems) =>
					dropItems.filter(({ id }) => id !== item.id)
				);
				setIsDrawerOpen(false);
				setShowMessage(false);
			}
		},
	});

	const statndardMenuTabs = useMemo((): ITabFields => {
		const tabItems = (menuTabs.standard?.items.filter(
			(tab) => tab.fieldType !== 'hyperlink'
		) ?? []) as ITabMenu[];
		return { heading: 'Fields', items: tabItems };
	}, [menuTabs.standard]);

	return (
		<div className="fields-sidebar__wrapper" ref={dropRef}>
			{isOver ? <DeleteNodesZone /> : <MenuItems menu={statndardMenuTabs} />}
		</div>
	);
};
