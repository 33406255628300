import { CSSProperties, useMemo } from 'react';

import { IOption, ReactDropdown } from '@storybook/dropdown';

import './style.scss';

/**
 * styles for the inline css
 * */ 
type IStyle = { [key in string]: CSSProperties}
type IInlineStyle = { [key in string]: IStyle | CSSProperties}

const controlStyle: CSSProperties = {
	minHeight: 36,
	height: 36,
	borderRadius: 8,
	backgroundColor: 'var(--color-input-background)',
	fontWeight: 500,
	fontSize: 14,
};

const optionStyle: CSSProperties | IInlineStyle = {
	height: 32,
	minHeight: 32,
	paddingTop: 6,
	fontSize: 14,
	'&:hover': {
		backgroundColor: 'var(--color-primary-dark-blue)',
		color: 'var(--color-input-background)',
		i: { color: 'var(--color-input-background) !important' },
	},
};

const menuStyle: CSSProperties = {
	borderRadius: 4,
};
const valueContainerStyle: CSSProperties = { paddingRight: 0 };
const DropDownWithLabel = ({
	label,
	handleDropdownChange,
	value,
	defaultValue,
	options,
	id = '',
	getOptionLabel,
}: {
	label: string;
	handleDropdownChange: (event: IOption) => void;
	value?: IOption;
	defaultValue?: IOption;
	options: IOption[];
	id: string;
	getOptionLabel?: (option: IOption) => JSX.Element;
}) => {
	const customProps = useMemo(() => {
		if (getOptionLabel) {
			return { getOptionLabel };
		}
		return {};
	}, [getOptionLabel]);

	return (
		<div className="drop-down-label-container">
			{label && <label htmlFor={`select-${id}`}>{label}</label>}
			<span
				contentEditable="true"
				className="drop-down-label-dropdown-container"
				tabIndex={-1}
				suppressContentEditableWarning={true}
			>
				<ReactDropdown
					options={options}
					handleChangeSelect={handleDropdownChange}
					value={value}
					valueContainerStyle={valueContainerStyle}
					id={`select-${id}`}
					controlStyle={controlStyle}
					defaultValue={defaultValue}
					optionsStyle={optionStyle}
					menuStyle={menuStyle}
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					{...(customProps as any)}
				/>
			</span>
		</div>
	);
};

export default DropDownWithLabel;
