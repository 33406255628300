import { useCallback } from "react";

import { MESSAGE } from "constant";
import { useNotification } from "hooks";
import { useRecoilValue } from "recoil";

import { RecipientLocalState, useRecipient } from "../store";

export const AddRecipientFormBtn = () => {
  const localRecipients = useRecoilValue(RecipientLocalState);
  const { errorNotification } = useNotification();
  const { AddRecipients } = useRecipient();
  const { MAX_RECIPIENT_LIMIT_MESSAGE } = MESSAGE;
  const handleClick = useCallback(() => {
    if (localRecipients.length <= 24) {
      AddRecipients();
    } else {
      errorNotification(MAX_RECIPIENT_LIMIT_MESSAGE);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    AddRecipients,
    localRecipients.length,
  ]);

  return (
    <div className="add-recipient__form-header">
      <div
        className="add-recipient__add-btn"
        role="button"
        tabIndex={0}
        onClick={handleClick}
      >
        <i className="ri-user-add-fill" />
        <span>Add Recipient</span>
      </div>
    </div>
  );
};
