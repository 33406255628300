import { MutableRefObject, useEffect, useMemo, useRef } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { GifLoader } from '@storybook';
import { DocumentProcessingState } from 'states';
import { ConfigDocumentDrop, ConfigNodeDrawer, RecipientsState } from 'views';
import { ASSETS } from 'constant';

import {
	DropItemsState,
	IRefProps,
	configPagesRefsState,
	useGetConfigDoc,
	useSetConfigDoc,
	useUndoRedo,
} from '../../store';

export const ConfigDocument = ({ refs, showZindex = true }: IRefProps) => {
	const setConfigPagesRef = useSetRecoilState(configPagesRefsState);
	const processingDoc = useRecoilValue(DocumentProcessingState);
	const {handleUpdateRecipientsAndTabs} = useSetConfigDoc()
	const dropItems = useRecoilValue(DropItemsState)
	const recipients = useRecoilValue(RecipientsState);

	const  dropContainerDimension: MutableRefObject<{width: number, height: number}> = useRef<{width: number, height: number}>({width: 0, height: 0});
	const { DOC_MISSING_SVG } = ASSETS;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const configPagesRefList = useRef<any>([]);

	const { docTemplates } = useGetConfigDoc(); // for the doc template


	useEffect(() => {
		if (recipients.length) {
			handleUpdateRecipientsAndTabs(dropItems, recipients)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[recipients])

	/**
	 * event handler to handle resize end
	 * */

	const renderImages = useMemo(() => {
		if (!processingDoc) {
			return (
				<div
					style={{
						height: '100%',
					}}
				>
					<GifLoader dimension={60} text="Document is being processed." />
				</div>
			);
		}

		return docTemplates.data.length ? (
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			docTemplates.data.map((doc: any) => {
				return doc?.images?.map(
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					({ path, _id, height, width, pageIndex }: any) => (
						<>
							<ConfigDocumentDrop
								key={_id}
								path={path}
								_id={_id}
								height={height}
								width={width}
								idx={pageIndex}
								doc={doc}
								dropContainerDimension={dropContainerDimension}
								showZindex={showZindex}
							/>
						</>
					)
				);
			})
		) : (
			<div className="config-document__placeholder">
				<img src={DOC_MISSING_SVG} alt="" loading="lazy" />
				<span>No documents to show</span>
			</div>
		);
	}, [DOC_MISSING_SVG, docTemplates.data, processingDoc, showZindex]);

	useUndoRedo(refs);

	useEffect(() => {
		setConfigPagesRef({ current: [...configPagesRefList.current] });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [configPagesRefList]);

	return (
		<div className="config-document" id="config-doc-id">
			{renderImages}
			<ConfigNodeDrawer dropContainerDimension={dropContainerDimension} />
		</div>
	);
};