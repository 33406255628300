import { ReactNode, FC, KeyboardEvent } from "react";

import classNames from "classnames";
import ReactModal, { Styles } from "react-modal";

import "./modal.scss";

type Props = {
  className?: string;
  isOpen: boolean;
  closeModal?: () => void;
  overlayStyles?: Styles;
  modalName: string;
  children: ReactNode;
  showCloseBtn?: boolean;
  title?: string | JSX.Element;
  isStopOutsideClick?: boolean;
  closeOnEscBtn?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ariaHideApp?: any;
  wrapperWidth?: number | string
};

export const Modal_styles = {
  overlay: {
    position: "fixed",
    top: "0px",
    left: "0px",
    right: "0px",
    bottom: "0px",
    backgroundColor: "rgba(44, 48, 57, 0.8)",
    zIndex: 1000000000,
    overflowY: "auto",
  },
};

export const Modal: FC<Props> = ({
  className,
  isOpen,
  closeModal = () => ({}),
  overlayStyles,
  modalName,
  children,
  showCloseBtn = true,
  title,
  isStopOutsideClick = true,
  closeOnEscBtn = true,
  wrapperWidth,
}) => {
  const classes = classNames("esign-Modal", className);

  const handleClose = (event: KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      closeModal();
    }
  };

  const modalCombinedStyles = {
    ...Modal_styles,
    overlay: { ...Modal_styles.overlay, ...overlayStyles },
  };

  return (
    <ReactModal
      closeTimeoutMS={500}
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel={modalName}
      style={modalCombinedStyles as Styles}
      className={classes}
      shouldCloseOnOverlayClick={isStopOutsideClick}
      shouldCloseOnEsc={closeOnEscBtn}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      parentSelector={() => document.querySelector("body") as any}
      ariaHideApp={false}
    >
      <div style={{ width : wrapperWidth}} className="esign-Modal__wrapper">
        <div>{title}</div>
        {showCloseBtn && (
          <div
            className="esign-Modal__closeButton"
            onClick={closeModal}
            onKeyPress={handleClose}
            role="button"
            tabIndex={0}
          >
            <i className="ri-close-line esign-Modal__closeIcon" />
          </div>
        )}
        {children}
      </div>
    </ReactModal>
  );
};

export default Modal;