import { FC } from "react";

import "./loader.scss";
import { useRecoilValue } from "recoil";
import { organizationDetailState } from "states";

interface ILoaderDimension {
  dimension?: number;
  className?: "loader-blue" | "loader-white" | "loader-pink";
}

export const Loader: FC<ILoaderDimension> = ({ dimension, className="loader-blue" }) => {
  let _dimension = dimension;
  if (!dimension) {
    _dimension = 54
  }
  const organizationalData = useRecoilValue(organizationDetailState);
 
  return (
    <div className="esign-circle-loader">
        {
          organizationalData?.loader ? 
            <img
              style={{ width: _dimension, height: _dimension }}
              src={organizationalData?.loader}
              alt="Gif Loader"
            />
          :
            <div
              style={{ width: _dimension, height: _dimension }}
              className={`loader ${className}`}
            />
        }
    </div>
  );
};
