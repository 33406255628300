import type { ICollapsible } from './types';

import { FC, useState } from 'react';

/**
 * Collapsible component to show and hide content with a toggle.
 *
 * @component
 * @example
 * const header = 'Click to toggle'
 * const children = <div>Content to show or hide</div>
 * return (
 *   <Collapsible header={header}>
 *     {children}
 *   </Collapsible>
 * )
 *
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Content to show or hide
 * @param {string} props.header - Header text for the collapsible component
 * @returns {JSX.Element} The Collapsible component
 */
export const Collapsible: FC<ICollapsible> = ({ children, header }) => {
  
	const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="collapsible">
      <div
        className="collapsible__header"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <span>{header}</span>
        {isOpen ? (
          <i className="ri-arrow-up-s-line" />
        ) : (
          <i className="ri-arrow-down-s-line" />
        )}
      </div>
      {isOpen && <div className="collapsible__children">{children}</div>}
    </div>
  );
};
