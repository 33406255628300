import { FC } from "react";

import "./loader.scss";

interface ILoaderDimension {
  dimension?: number;
  className?: "loader-blue" | "loader-white" | "loader-pink";
}

export const Loader: FC<ILoaderDimension> = ({ dimension, className="loader-blue" }) => {
  let _dimension = dimension;
  if (!dimension) {
    _dimension = 54
  }
  return (
    <div className="esign-circle-loader">
      <div
        style={{ width: _dimension, height: _dimension }}
        className={`loader ${className}`}
      />
    </div>
  );
};
