import { useMemo } from "react";

import { IUploadDocHeader, IUploadRowData } from "views";

interface Props {
  column: IUploadDocHeader[];
  rows: IUploadRowData[];
}

export const DocumentTable = ({ column, rows = [] }: Props) => {
  const renderColumn = useMemo(
    () =>
      column.map(({ label, key, width }: IUploadDocHeader, index: number) => (
        <th
          className="table__head-data"
          key={`renderColumns__${label}_${index}-${key}`}
          style={{ width }}
        >
          <div className="table__head-data--label">
            <span>{label} </span>
          </div>
        </th>
      )),
    [column]
  );

  const renderRows = useMemo(() => {
    const renderingRows = rows;
    return renderingRows.map((rowData: IUploadRowData, index: number) => (
      <tr key={`renderRows_${rowData.documentName}__${index}`}>
        {column.map(({ key, width }: IUploadDocHeader, idx: number) => {
          const value = rowData[key as keyof IUploadRowData];
          return (
            <td key={`renderColumn_${index}__${idx}__${key}`} style={{ width }}>
              <div>{value}</div>
            </td>
          );
        })}
      </tr>
    ));
  }, [column, rows]);

  return (
    <>
      <div className="react-table-responsive-container">
        <div className="react-table-responsive-wrapper">
          <table className={`react-table-responsive fl-table`}>
            <thead className="react-table-responsive__head">
              <tr
                className="react-table-responsive__head-row"
                style={{ backgroundColor: "#d9e1f2" }}
              >
                {renderColumn}
              </tr>
            </thead>
            {!!rows.length && !!renderRows.length && (
              <tbody className="react-table-responsive__body ">
                {renderRows}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
};
