import type { IRecipientUser } from "./types";

import { atom } from "recoil";


export const IsAddRecipientModalOpen = atom({
  key: "is-add-recipient-modal-open-key",
  default: false,
});

export const RecipientsState = atom<IRecipientUser[]>({
  key: "invited-recipients-list-state-key",
  default: [],
});

export const SelectedRecipientState = atom<IRecipientUser[]>({
  key: "selected-recipients-user-state-key",
  default: [],
});

export const RecipientLocalState = atom<IRecipientUser[]>({
  key: "selected-recipients-user-local-state-key",
  default: [],
});

export const RecipientErrorsState = atom<{ [key: string]: string[] }>({
  key: "recipients-error-state",
  default: {},
});

export const isSigningOrderEnabledState = atom<boolean>({
  key: "recipient-order-enabled",
  default: false,
});

export const IsSigningOrderModalOpen = atom<boolean>({
  key: "is-signing-modal-open-key",
  default: false,
});

 //paras : add facial toggle in document upload 
export const IsEnableFacialAuth = atom<boolean>({
  key: "is-enable-facial-auth",
  default: false,
});

export const IsEnableENotaryToggle = atom<boolean>({
  key: "is-enable-e-notary-toggle",
  default: false,
});

export const IsEnableMerchantFlow = atom<boolean>({
  key: "is-enable-merchant-flow-state-key",
  default: false,
})

export const IsOriginalCopy = atom<boolean>({
  key: "is-original-copy",
  default: false,
});

export const NameSuggestionLoadingState = atom<boolean>({
  key: "NameSuggestionLoadingState",
  default: false,
});

export const IsCommonField = atom<boolean>({
  key: "is-common-field",
  default: false,
});
