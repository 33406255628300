/* eslint-disable no-console */
import { useCallback } from 'react';

import { useSetRecoilState } from 'recoil';
import { WebAuthCounter } from 'views';

import { CHALLENGE } from './constants';
import { IRegisteredCredentials, IUserInfo } from './types';
import {
	binToStr,
	getRegistrations,
	saveNewCredentials,
	strToBin,
} from './utils';

/**
 * Hook for handling Web Authentication operations such as registration and authentication.
 * @returns Object containing functions for authentication and registering new credentials.
 */
export const useWebAuthentication = () => {
	const setCounter = useSetRecoilState(WebAuthCounter)
	/**
	 * Registers new credentials for the user.
	 * @param callback - Callback function to execute after successful registration.
	 * @param userInfo - Information about the user.
	 */
	const registerNewCredentials = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async (callback: any, userInfo: IUserInfo) => {
			console.log("registerNewCredentials function started");
	
			try {
				const enc = new TextEncoder();
				const publicKey: PublicKeyCredentialCreationOptions = {
					rp: { name: window.location.host ?? 'secure.satschel.com' },
					user: {
						name: userInfo.email ?? '',
						id: enc.encode(userInfo.id ?? ''),
						displayName: userInfo.displayName ?? '',
					},
					pubKeyCredParams: [{ type: 'public-key', alg: -7 }],
					challenge: enc.encode(CHALLENGE),
					authenticatorSelection: { userVerification: 'required' },
				};
				const res = (await navigator.credentials.create({
					publicKey: publicKey,
				})) as PublicKeyCredential;
	
				console.log("New credentials created:", res);
	
				const payload = {
					rawId: binToStr(res?.rawId),
					id: res.id,
					email: userInfo.email ?? '',
					userId: userInfo.id,
					displayName: userInfo.displayName ?? '',
				};
	
				console.log("Saving new credentials ");
				saveNewCredentials(payload);
				
				console.log("Calling callback function...");
				callback();
	
				return res;
			} catch (error) {
				console.error("An error occurred:", error);
				setCounter(prev => prev + 1);
				return Promise.reject(error);
			} finally {
				console.log("registerNewCredentials function finished");
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);
	

	/**
	 * Retrieves registered credentials for the user.
	 * @returns Array of registered credentials.
	 */
	const registeredCredentials = () => {
		return getRegistrations().map((reg: IRegisteredCredentials) => ({
			id: strToBin(reg.rawId),
			type: 'public-key',
			transports: ['internal'],
		}));
	};

	/**
	 * Authenticates the user using Web Authentication.
	 * @param onSuccess - Callback function to execute upon successful authentication.
	 * @param onFailed - Callback function to execute upon failed authentication.
	 */
	const authenticate = useCallback(
		async (onSuccess: () => void, onFailed: () => void) => {
			console.log("authenticate function started");
	
			try {
				const enc = new TextEncoder();
	
				const publicKey = {
					challenge: enc.encode(CHALLENGE),
					allowCredentials: registeredCredentials(),
				};
				// Browser receives the publicKey object and passes it to WebAuthn "get" API
				const res = (await navigator.credentials.get({
					publicKey: publicKey,
				})) as PublicKeyCredential;
	
				console.log("Authentication successful. Result:", res);
	
				// Build an object containing the results to be sent to the server
				const extractedData = {
					id: res.id,
					rawId: binToStr(res?.rawId),
					clientDataJSON: binToStr(res.response?.clientDataJSON),
				};
	
				// Perform authentication checks
				const dataFromClient = JSON.parse(atob(extractedData.clientDataJSON));
				const retrievedChallenge = atob(dataFromClient.challenge);
	
				if (retrievedChallenge === CHALLENGE) {
					console.log("Authentication challenge matches. Calling onSuccess callback.");
					onSuccess();
					return res;
				} else {
					console.log("Authentication challenge does not match. Calling onFailed callback.");
					onFailed();
					return;
				}
			} catch (error) {
				console.error("An error occurred:", error);
				setCounter(prev => prev + 1);
				return Promise.reject(error);
			} finally {
				console.log("authenticate function finished");
			}
		},
		[setCounter]
	);
	

	return { authenticate, registerNewCredentials, getRegistrations };
};
