import { FC, useCallback } from "react";

import { Input } from "@storybook";
import { IInputChange } from "types";
import { useGetConfiguredNode, useSetConfigFieldNode } from "views";

interface INumberInputComponent {
  label: string;
  placeholder: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any;
}

export const NumberInputComponent: FC<INumberInputComponent> = ({
  label,
  placeholder,
  name,
  defaultValue,
}) => {
  const { handleConfigureNode } = useSetConfigFieldNode();
  const { configuredNode } = useGetConfiguredNode();
  // const [data, setData] = useState(configuredNode(name));

  // useEffect(() => {
  //   setData(configuredNode(name));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [configuredNode(name)]);

  const boundedValues = { maxVal: 4000, minVal: 1 };
  const { maxVal, minVal } = boundedValues;
  const handleChange = useCallback(
    (event: IInputChange) => {
      const { value } = event.target;
      handleConfigureNode(name, value);
    },
    [handleConfigureNode, name]
  );

  const handleOnBlur = useCallback(
    (event: IInputChange) => {
      const { value } = event.target;
      const modifiedLen = Math.max(
        Math.min(Number(value), maxVal),
        minVal
      ).toString();
      const prevEditTextVal = configuredNode("editText");
      const finalStr =
        prevEditTextVal.length > modifiedLen
          ? prevEditTextVal.slice(0, modifiedLen)
          : prevEditTextVal;
      handleConfigureNode(name, modifiedLen);
      handleConfigureNode("editText", finalStr);
    },
    [configuredNode, handleConfigureNode, maxVal, minVal, name]
  );

  const value = parseInt(configuredNode(name) ?? defaultValue);
  return (
    <div className="number-input">
      <Input
        label=""
        inputType="number"
        placeholder={placeholder}
        value={value}
        handleChange={handleChange}
        handleBlur={handleOnBlur}
      />
      <div className="number-input__label">{label}</div>
    </div>
  );
};
