export const signerNodes = [
	{
		_id: '64216cc6dc7f3d40f203b173',
		name: 'First Name',
		label: 'First Name',
		position: {
			xLeft: 0.32575757575757575,
			yTop: 0.24590437921163522,
		},
		type: 'text',
		source: 'custom',
		icon: 'fa fa-user',
		value: '',
		pageNumber: 3,
		metadata: {
			tooltip: 'value',
			fontFamily: 'value',
			_id: '64216cc6dc7f3d40f203b175',
		},
		createdAt: '2023-03-27T10:15:34.017Z',
		updatedAt: '2023-03-27T10:15:34.017Z',
	},
	{
		_id: '64216cc6dc7f3d40f203b176',
		name: 'Email',
		label: 'Email',
		position: {
			xLeft: 0.5409090909090909,
			yTop: 0.38609960350621414,
		},
		type: 'text',
		source: 'custom',
		icon: 'fa fa-user',
		value: '',
		pageNumber: 2,
		metadata: {
			tooltip: 'value',
			fontFamily: 'value',
			_id: '64216cc6dc7f3d40f203b178',
		},
		createdAt: '2023-03-27T10:15:34.018Z',
		updatedAt: '2023-03-27T10:15:34.018Z',
	},
];

export const docStatus = ['created', 'completed', 'declined', 'expired'];

export const zoomContentSelector = '#pdf-wrapper';
export const zoomContentSelectorParent = '#random-pdf-random-container';
