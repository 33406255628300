import { ASSETS } from "constant";
import { COMMON_CONST } from "views";

export const NoRecipient = () => {

  const { NO_RECIPIENTS_ADDED } = COMMON_CONST;
  const {NO_RECIPIENT} = ASSETS;
  
  return (
    <div className="no-recipient-container">
      <img src={NO_RECIPIENT} alt="no recipient" />
      <p>{NO_RECIPIENTS_ADDED}</p>
    </div>
  );
};
