export const SolidHexColor = (rgba: string) => {
    const rgbaValues = rgba.replace('rgba(', '').replace(')', '').split(',').map((value) => parseFloat(value.trim()));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [r, g, b, a]:any = rgbaValues;

    const rWithoutAlpha = Math.round((1 - a) * 255 + a * r);
    const gWithoutAlpha = Math.round((1 - a) * 255 + a * g);
    const bWithoutAlpha = Math.round((1 - a) * 255 + a * b);

    const solidColor = `#${(rWithoutAlpha << 16 | gWithoutAlpha << 8 | bWithoutAlpha).toString(16).padStart(6, '0')}`;
    return solidColor;
  };
  