import type { IInputChange } from "types";

import { FC, useCallback, useState } from "react";

import { Input } from "@storybook";
import { IsShowMessage, useGetConfiguredNode, useSetConfigFieldNode } from "views";
import { isValidLink } from "utils";
import { useRecoilState } from "recoil";

interface ITextInputComponent {
  label: string;
  placeholder: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any;
}

export const TextInputComponent: FC<ITextInputComponent> = ({
  label,
  placeholder,
  name,
  defaultValue,
}) => {
  const [isError, setIsError] = useState(true);
  const { handleConfigureNode } = useSetConfigFieldNode();
  const { configuredNode } = useGetConfiguredNode();
  const [showMessage, setShowMessage] = useRecoilState(IsShowMessage);

  const handleChange = useCallback(
    (event: IInputChange): void => {
      const { value } = event.target;
      if (name === "url") {
         // eslint-disable-next-line @typescript-eslint/no-unused-expressions
         isValidLink(value) ? setIsError(false) : setIsError(true);         
      }
      handleConfigureNode(name, value);
    },
    [handleConfigureNode, name]
  );

  const value = configuredNode(name) ?? defaultValue;

  const handleFocus = useCallback(()=>{
    setShowMessage(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="config-text-input">
      <Input
        label={label}
        inputType={"text"}
        required = {true}
        placeholder={placeholder}
        handleChange={handleChange}
        value={value}
        isError={showMessage ? isError : !isError}
        handleBlur = {handleFocus}
        errorMessage={showMessage ? "Provide the valid link" : ""}
      />
    </div>
  );
};
