import { CSSProperties, FC, MutableRefObject } from 'react';

import { SigningNodes } from '../signing-nodes';
import { ITab } from '../store';

interface IProps {
	index: number;
	node: ITab;
	pageNumber: number;
	docId: string;
	pageId: string;
	nodeRefs: MutableRefObject<HTMLDivElement[]>;
	pageHeight: number;
	pageWidth: number;
	customStyles?: CSSProperties;
}

export const SignNode: FC<IProps> = ({
	index,
	node,
	pageNumber,
	docId,
	nodeRefs,
	pageId,
	pageHeight,
	pageWidth,
	customStyles = {},
}): JSX.Element => {
	const { metadata, position, _id } = node ?? {};

	// Initial values for CSS properties used in styles
	const xLeft = position?.xLeft ?? 0;
	const yTop = position?.yTop ?? 0;
	const fontFamily = metadata?.fontFamily ?? 'poppins';
	const fontSize = metadata?.fontSize ?? '8px';
	const fontColor = metadata?.fontColor ?? '#000000';

	const styles: CSSProperties = {
		position: 'absolute',
		left: `${xLeft * 100}%`,
		top: `${yTop * 100}%`,
		fontFamily: fontFamily as string,
		fontSize: fontSize as string,
		color: fontColor as string,
		...customStyles
	};

	return node.pageNumber === pageNumber && node.documentId === docId ? (
		<div key={`${_id}-${index}`}>
			<div
				id={`${_id}-${index}`}
				ref={(ref) => {
					if (ref) {
						return (nodeRefs.current[index] = ref);
					}
					return null;
				}}
				className="pdf-signer-container__signing-node"
				style={styles}
			>
				<SigningNodes node={node} index={index} pageId={pageId} pageHeight={pageHeight} pageWidth={pageWidth} />
			</div>
		</div>
	) : (
		<></>
	);
};
