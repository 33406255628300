import type { IButton } from ".";

import { FC } from "react";

export const Button: FC<IButton> = ({
  label,
  handleClick,
  loader,
  className,
  icon,
  height,
  disabled,
  buttonType,
  prefixIcon,
  minHeight,
  width,
  id,
}) => {
  return (
    <button
      id={id}
      className={`esign-button ${className} ${disabled ? "esign-button--disabled" : ""}`}
      onClick={handleClick}
      style={{ minHeight, height, width }}
      disabled={disabled}
      type={buttonType}
    >
      {prefixIcon && <i className={prefixIcon} />}
      <div className="esign-button-label-txt">{label}</div>
      {loader}
      {icon && <i className={icon} />}
    </button>
  );
};
