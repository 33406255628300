import { FC } from 'react';

import cn from 'classnames';

import './skeleton-loader.scss';

interface ISkeleton {
	width?: number;
	height?: number;
}

const skeletonTheme = cn({
	'skeleton-loader-light': true,
});
const skeletonHeader = cn('skeleton_Header_upper', {
	'skeleton-loader-light': true,
});
const skeletonHeaderLower = cn('skeleton_Header_lower', {
	'skeleton-loader-light': true,
});
const SkeletonLogoTheme = cn('skeleton_logo', {
	'skeleton-loader-light': true,
});

export const Skeleton: FC<ISkeleton> = ({ height, width }) => {
	return (
		<div className="skeleton_container" style={{ width: `${width}px` }}>
			<span className={skeletonTheme} style={{ height: `${height}px` }} />
		</div>
	);
};

export const SkeletonHeader = () => {
	return (
		<div className="skeleton_Header">
			<div className={skeletonHeader} />
			<div className={skeletonHeaderLower} />
		</div>
	);
};

export const SkeletonLogo = () => {
	return <div className={SkeletonLogoTheme} />;
};
