import type { ICheckbox } from "./types";

import { FC, useCallback } from "react";

export const Checkbox: FC<ICheckbox> = ({
  label,
  linkText,
  className="",
  link,
  checked,
  disabled = false,
  handleCheck,
  style = {},
  labelStyle
}) => {
  const handleOpen = useCallback(() => {
    window.open(link, "_blank");
  }, [link]);

  return (
    <div className="checkbox-storybook">
      <label htmlFor="checkbox-storybook" className="checkbox-storybook__label">
        <div className="checkbox-storybook__label--text" style={{...labelStyle}}>{label}</div>
        {linkText && (
          <div className="checkbox-storybook__label--link" onClick={handleOpen}>
            {linkText}
          </div>
        )}
      </label>
      <input
        style={{...style}}
        type="checkbox"
        id="checkbox-storybook"
        className={`checkbox-storybook__field ${className}`}
        onChange={handleCheck}
        disabled={disabled}
        checked={checked}
      />
    </div>
  );
};
