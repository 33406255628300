import { FC } from "react";

import { ITooltip, Tooltip as ReactTooltip } from "react-tooltip";

export const Tooltip: FC<ITooltip> = ({  
  id,
  place,
  content,
  style,
  variant
}) => {
  return (
    <ReactTooltip
      anchorId={id}
      place={place}
      content={content}
      variant={variant}
      style={{ zIndex: 1000, color: "#ffffff", fontSize: 12, ...style }}
    />
  );
};
