/* eslint-disable no-case-declarations */
import { DateTime } from "luxon";

export const getDate = (date: string | number | Date, format?: string) => {
  switch (typeof date) {
    case "number":
      return DateTime.fromMillis(date).toFormat(format ?? "MMM dd, yyyy");
    case "string":
      return DateTime.fromISO(date).toFormat(format ?? "MMM dd, yyyy");
    default:
      const formatToString = new Date(date).toISOString();
      return DateTime.fromISO(formatToString).toFormat(format ?? "yyyy-MM-dd");
  }
};

export const getDateWithTime = (
  date: string | number | Date,
  format?: string
) => {
  switch (typeof date) {
    case "number":
      return DateTime.fromMillis(date).toFormat(
        format ?? "MMM dd, yyyy hh:mm a"
      );
    case "string":
      return DateTime.fromISO(date).toFormat(format ?? "MMM dd, yyyy  hh:mm a");
    default:
      const formatToString = new Date(date).toISOString();
      return DateTime.fromISO(formatToString).toFormat(format ?? "yyyy-MM-dd");
  }
};

export const getTime = (date: string | number | Date, format?: string) => {
  switch (typeof date) {
    case "number":
      return DateTime.fromMillis(date).toFormat("hh:mm a");
    case "string":
      return DateTime.fromISO(date).toFormat("hh:mm a");
    default:
      const formatToString = new Date(date).toISOString();
      return DateTime.fromISO(formatToString).toFormat(format ?? "yyyy-MM-dd");
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDifferenceInDate = (date1: any, date2: any) => {
  const diffTime = Math.abs(date2 - date1);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

type DateFormat = 'yyyy/mm/dd' | 'mm/dd/yyyy' | 'dd/mm/yyyy';

export const formatDate = (inputDate: string | Date, format: DateFormat = 'yyyy/mm/dd'): string => {
  const date = typeof inputDate === 'string' ? new Date(inputDate) : inputDate;

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  switch (format) {
    case 'yyyy/mm/dd':
      return `${year}/${month}/${day}`;
    case 'mm/dd/yyyy':
      return `${month}/${day}/${year}`;
    case 'dd/mm/yyyy':
      return `${day}/${month}/${year}`;
    default:
      throw new Error('Invalid date format');
  }
};
