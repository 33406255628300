import { useCallback } from "react";

import { Button } from "@storybook";

import { OfflineSvg } from "./assets/offline";

export const ServerDisconnected = () => {
  const handleReconnect = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <div className="server-disconnected">

<div
				className="server-disconnected__logo"
				dangerouslySetInnerHTML={{
					__html: OfflineSvg,
				}}
			/>

      <div className="server-disconnected__message">
        <div className="server-disconnected__heading">You’re offline</div>
        <div className="server-disconnected__description">
        Your internet connection in unavailable. Please check your data or wifi
				connection.
        </div>
      </div>
      <Button
        label="Retry"
        handleClick={handleReconnect}
        className="esign-button__filled esign-button__filled--primary esign-button__large server-disconnected__btn"
        // prefixIcon="ri-refresh-line"
      />
    </div>
  );
};
