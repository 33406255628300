import { FC, useMemo, useState } from 'react';

import { IconBtn } from '@storybook';
import { useRecoilValue } from 'recoil';
import { GetQueryParams } from 'utils';
import { ASSETS } from 'constant';
import { templateTypeState } from 'views/prepare-dashboard';
import classNames from 'classnames';

import { CustomTabModal, ITabFields, MenuItem, TabConfigurationState, isSearchFocused } from '..';

interface IMenuItems {
	menu?: ITabFields;
	showAdd?: boolean;
	tabHeading?: string;
}

export const MenuItems: FC<IMenuItems> = ({
	menu = { heading: '', items: [] },
	showAdd = false,
	tabHeading,
}) => {
	const templateType = useRecoilValue(templateTypeState);
	const [showModal, setShowModal] = useState(false);
	const { heading, items } = menu;
	const isEnvelopeId = !!GetQueryParams('envelopeId');
	const { EMPTY_DATA_SVG } = ASSETS;
	const isFocus = useRecoilValue(isSearchFocused);
	const tabConfiguration = useRecoilValue(TabConfigurationState);
	const { questionnaire } = tabConfiguration;

	const renderMenuItems = useMemo(() => {
		return items.map((item, index) => (
			<MenuItem
				key={`${item.key}-${index}`}
				item={item}
				showHoveredItem={showAdd}
			/>
		));
	}, [items, showAdd]);

	const menuItemsWithQuestionaire = useMemo(() => classNames('menu-items__envelope-items', {
		'isquestionnaire': questionnaire && !isFocus,
	}), [isFocus, questionnaire]);

	const getClassName = useMemo(() => {
		if (isEnvelopeId) {
			return menuItemsWithQuestionaire;
		} else if (templateType === 'overlay') {
			return 'menu-items__template-items-overlay';
		} else {
			return 'menu-items__template-items';
		}
	}, [isEnvelopeId, menuItemsWithQuestionaire, templateType]);

	const getClassNameOnFocused = useMemo(() => {
		if (isFocus) {
			return 'menu-items__searchable';
		} else {
			return 'menu-items__notSearchable';
		}
	}, [isFocus]);

	return (
		<div className="menu-items">
			<div className="menu-items__heading">
				{heading.match(/custom fields/i) && showAdd ? (
					<div
						tabIndex={-1}
						id="custom-menu-tab-heading"
						className="menu-tab__custom-heading"
					>
						<div className="menu-tab__custom-heading-text">{heading}</div>

						<IconBtn
							btnClassName={'ri-add-line'}
							handleClickIcon={() => setShowModal(true)}
							id="custom-tab-add-item"
							toolTip="Add custom field"
						/>

						{showModal && (
							<CustomTabModal closeModal={() => setShowModal(false)} />
						)}
					</div>
				) : (
					tabHeading ?? heading
				)}
			</div>
			<div className="menu-items__wrap">
				<div
					className={`${getClassName} ${getClassNameOnFocused}`}
				>
					{!renderMenuItems.length ? (
						<div className="menu-items__empty-state">
							<img src={EMPTY_DATA_SVG} alt="" />
						</div>
					) : (
						renderMenuItems
					)}
				</div>
			</div>
		</div>
	);
};
