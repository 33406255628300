import {
	CSSProperties,
	KeyboardEventHandler,
	useCallback,
	useMemo,
} from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { FadeHexColor, SolidHexColor } from 'utils';
import {
	IDropedItems,
	IsConfigDrawerOpen,
	useGetRecipient,
	AllowedResizableNodes,
	useSetConfigDoc,
	VALIDATION_FILED_NAME,
	IConfigDocument,
} from 'views';
import { EnvelopePurposeState } from 'states';

import {
	ConfigDocumentState,
	DropItemsState,
	SelectedConfigNode,
	configDocTitleState,
} from '../state';


export const useDropItem = (item: IDropedItems, index: number) => {
	const setIsDrawerOpen = useSetRecoilState(IsConfigDrawerOpen);
	const setActiveNode = useSetRecoilState(SelectedConfigNode);
	const { handleDeleteNode } = useSetConfigDoc();
	const { getRecipientColor, activeRecipientId } = useGetRecipient();
	const { metadata } = item;
	const { fontSize, fontFamily, fontColor } = metadata || {};
	const purpose = useRecoilValue(EnvelopePurposeState);

	const fadeColor = getRecipientColor(item?.recipient ?? activeRecipientId);

	// paras : node color issue fixed in invite case.
	const darkColor = fadeColor ? FadeHexColor(`${fadeColor}`, 0.5) : '';

	const dropItemStyles: CSSProperties = {
		position: 'absolute',
		left: `${item?.position?.xLeft * 100}%`,
		top: `${item?.position?.yTop * 100}%`,
		cursor: 'pointer',
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		fontSize: (fontSize as any)?.value ?? '8px',
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		fontFamily: (fontFamily as any)?.value ?? 'inherit',
		color: fontColor ?? '#000000',
		background: (darkColor && SolidHexColor(`${darkColor}`)) || '#eae2be', // paras : node color issue fixed in invite case.
		userSelect: 'none',
	};
	// Do not delete this its very important

	// const numericFontSize = useMemo(() => {
	// 	if (!dropItemStyles?.fontSize) {
	// 		return 0;
	// 	}
	// 	const font =
	// 		typeof dropItemStyles.fontSize == 'string'
	// 			? Number(dropItemStyles?.fontSize?.replace('px', ''))
	// 			: dropItemStyles?.fontSize;
	// 	if (!Number.isNaN(font)) {
	// 		return font;
	// 	}
	// 	return 0;
	// }, [dropItemStyles.fontSize]);

	// const scaleFactor = numericFontSize / 10;
	// if (
	// 	!['text', 'link'].includes(item.key) &&
	// 	numericFontSize > 0 &&
	// 	numericFontSize < 10 &&
	// 	scaleFactor > 0 &&
	// 	scaleFactor < 1
	// ) {
	// 	dropItemStyles.transform = `scale(${scaleFactor})`;
	// 	dropItemStyles.transformOrigin = 'left top';
	// 	dropItemStyles.fontSize = '8px';
	// }

	// if (/address/gi.test(item.key)) {
	// 	dropItemStyles.width = '280px';
	// 	dropItemStyles.height = '40px';
	// 	dropItemStyles.overflowY = 'auto';
	// }
	const resizeHandlers = useMemo(
		() =>
			AllowedResizableNodes[item.fieldType]
				? {
					top: false,
					right: false,
					bottom: false,
					left: false,
					topRight: true,
					bottomRight: true,
					bottomLeft: true,
					topLeft: true,
				}
				: {
					top: false,
					right: false,
					bottom: false,
					left: false,
					topRight: false,
					bottomRight: false,
					bottomLeft: false,
					topLeft: false,
				},
		[item.fieldType]
	);

	const width = metadata?.width;
	const height = metadata?.height;

	const getCheckBoxSize = typeof dropItemStyles.fontSize == 'string' && Number(dropItemStyles?.fontSize?.replace('px', ''));

	const minHeight = useMemo(() => {
		switch (item.fieldType) {
			case 'checkbox':
				return getCheckBoxSize || metadata.height;
			case 'hyperlink':
				return metadata.height;
			case 'initial':
				return '30px';
			case 'signature':
				return '22px';
			default:
				return '10px';
		}
	}, [getCheckBoxSize, item.fieldType, metadata.height]);

	const maxHeight = useMemo(() => {
		switch (item.fieldType) {
			case 'checkbox':
				return getCheckBoxSize || metadata.height;
			case 'hyperlink':
				return metadata.height;
			case 'initial':
				return '80px';
			case 'signature':
				return '80px';
			case 'text':
				return '200px';
			default:
				return '50px';
		}
	}, [getCheckBoxSize, item.fieldType, metadata.height]);

	const minWidth = useMemo(() => {
		switch (item.fieldType) {
			case 'checkbox':
				return getCheckBoxSize || metadata.height;
			case 'hyperlink':
				return metadata.width;
			case 'initial':
				return '60px';
			case 'signature':
				return '45px';
			default:
				return '20px';
		}
	}, [getCheckBoxSize, item.fieldType, metadata.height, metadata.width]);

	const maxWidth = useMemo(() => {
		switch (item.fieldType) {
			case 'checkbox':
				return getCheckBoxSize || metadata.height;
			case 'hyperlink':
				return metadata.width;
			case 'initial':
				return '160px';
			case 'signature':
				return '320px';
			case 'text':
				return '600px';
			case 'date':
				return '300px';
			case 'title':
				return '300px';
			default:
				return '400px';
		}
	}, [getCheckBoxSize, item.fieldType, metadata.height, metadata.width]);

	const onlyNodeContainerStyles: CSSProperties = {
		...dropItemStyles,
		position: 'static',
		maxHeight,
		maxWidth: 'auto',
		overflowY: 'unset',
		minHeight,
		minWidth,
		width: dropItemStyles.width || metadata?.width || 'fit-content',
		height: dropItemStyles?.height || metadata?.height || 'fit-content',
	};
	const onlyNodeInnerStyles: CSSProperties = {
		maxHeight,
		overflowY: 'unset',
		maxWidth: 'auto',
		minHeight,
		minWidth,
		width: onlyNodeContainerStyles.width,
		height: onlyNodeContainerStyles.height,
	};
	const handleSelectNode = useCallback(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const { id, fieldType, isEditable, recipient, source, metadata } = item as any;
		setActiveNode({ id, fieldType, index, isEditable, recipient, source, validationType: metadata?.validation?.type });
		setIsDrawerOpen(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [index, item]);

	const itemProperties = useMemo(
		() => ({ maxHeight, maxWidth, minHeight, minWidth, width, height }),
		[height, maxHeight, maxWidth, minHeight, minWidth, width]
	);
	const handleDeleteDropItem = useCallback<
		KeyboardEventHandler<HTMLDivElement>
	>(
		(event) => {
			if (purpose !== 'selfsign') {
				if (event.key === 'Delete' || event.key === 'Backspace') {
					handleDeleteNode(item.id);
					setIsDrawerOpen(false);
					event.stopPropagation(); // Prevent the key event from propagating to the document
				}
			}
		},
		[handleDeleteNode, item.id, purpose, setIsDrawerOpen]
	);
	return {
		resizeHandlers,
		handleSelectNode,
		onlyNodeContainerStyles,
		onlyNodeInnerStyles,
		dropItemStyles,
		itemProperties,
		handleDeleteDropItem,
	};
};

export const useInvitePayload = ({
	envelopeId,
}: {
	envelopeId?: string | null;
}) => {

	//This function is returning the deleted page index and sending this in the patch api
	const getTheDeletedPages = (document: IConfigDocument) => {
		const { pages, images } = document;
		const imageIds = new Set(images?.map((image) => image._id));
		const deletedPages = pages?.filter((page) => !imageIds.has(page._id));
		return deletedPages?.map((page) => (page.pageIndex))
	};

	const { data: documents } = useRecoilValue(ConfigDocumentState);
	const dropItems = useRecoilValue(DropItemsState);
	const configDocTitle = useRecoilValue(configDocTitleState);
	const saveTemplatePayload = useMemo(() => {
		const docs = documents.map((document) => ({
			document: document._id,
			deletedPages: getTheDeletedPages(document),
			tabs: JSON.parse(JSON.stringify(dropItems))
				.sort((a: IDropedItems, b: IDropedItems) => {
					return (
						a.pageNumber - b.pageNumber ||
						a.position.yTop - b.position.yTop ||
						a.position.xLeft - b.position.xLeft
					);
				})
				.map(
					(
						{
							fieldType,
							icon,
							key,
							label,
							pageNumber,
							source,
							metadata,
							position,
							documentId,
							value,
							recipient,
						}: IDropedItems,
						index: number
					) => {
						if (documentId === document._id) {
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							const configObj: any = {
								name: key,
								label,
								type: fieldType,
								source,
								position,
								pageNumber,
								icon,
								value: value ?? '',
								index: index + 1,
							};
							if (envelopeId && recipient) {
								configObj.recipientId = recipient;
							}
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							const metadataPayload: any = {};
							let isValidationAvailable = false;

							Object.keys(metadata).forEach((key: string) => {
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								const metaKeyData = (metadata as any)[key];
								if (key == 'validation' || key == VALIDATION_FILED_NAME) {
									isValidationAvailable = true;
									if (metadata.validations) {
										metadataPayload.validations = metaKeyData;
									} else {
										metadataPayload.validations = {
											type: metaKeyData.value,
											value: ''
										};
									}
								} else {
									metadataPayload[key] = metaKeyData?.value ?? metaKeyData;
								}

								metadataPayload.width = parseInt(
									`${metadata.width}`.replace('px', '')
								);
								metadataPayload.height = parseInt(
									`${metadata.height}`.replace('px', '')
								);

								// here we are intentionally overriding the required flag because
								// readonly and required can not be at the same time
								if (metadata.readOnly === true && metadata.required === true) {
									metadataPayload.required = false;
								}
								// In common feilds tab readonly and required willbe false
								if (source === 'common') {
									metadataPayload.readOnly = false;
								}

							});
							if (isValidationAvailable) {
								configObj.type = 'text';
							}
							configObj.metadata = metadataPayload;
							return configObj;
						}
					}
				)
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				.filter((i: any) => i),
		}));
		return { documents: docs, name: configDocTitle.name };
	}, [configDocTitle.name, documents, dropItems, envelopeId]);

	return { saveTemplatePayload };
};