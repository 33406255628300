import UAParser from 'ua-parser-js';


export const isIOS = () => {
	return (
		[
			'iPad Simulator',
			'iPhone Simulator',
			'iPod Simulator',
			'iPad',
			'iPhone',
			'iPod',
		].includes(navigator.platform) ||
		// iPad on iOS 13 detection
		(navigator.userAgent.includes('Mac') && 'ontouchend' in document)
	);
};

// Function to parse version string and compare versions
export const isVersionGreaterThan = (targetVersion: string): boolean => {
	try {
	const uaParser = new UAParser(navigator.userAgent);
	const result = uaParser.getResult();
	const osVersion = result.os.version;

	if (!osVersion) {
		return false;
	}
    const parseVersion = (version: string): number[] => version.split('.').map(Number).filter(n => !isNaN(n));
    const [majorTarget, minorTarget, patchTarget] = parseVersion(targetVersion);
    const [majorCompare, minorCompare, patchCompare] = parseVersion(osVersion as string);
	

    // Check if any parts are undefined due to missing values in the version string
    if (majorCompare === undefined || majorTarget === undefined) return false;
    if (majorCompare > majorTarget) return true;
    if (majorCompare < majorTarget) return false;

    if (minorCompare === undefined || minorTarget === undefined) return minorCompare !== undefined;
    if (minorCompare > minorTarget) return true;
    if (minorCompare < minorTarget) return false;

    if (patchCompare === undefined || patchTarget === undefined) return patchCompare !== undefined;
    return patchCompare > patchTarget;
		
	} catch (error) {
		return false	
	}
	
}
