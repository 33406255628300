
import { useMemo } from 'react';

import { ASSETS } from 'constant';
import { useRecoilValue } from 'recoil';
import { organizationDetailState } from 'states';

export const UnderReviewScreen = () => {	
	const organization = useRecoilValue(organizationDetailState);

	const { VOIDED_ERROR_SVG, BRAND_LOGO } = ASSETS;

	const renderOrganizatonLogo = useMemo(() => {
		if (organization?.logo) {
			return organization?.logo;
		} else {
			return BRAND_LOGO;
		}
	}, [BRAND_LOGO, organization]);

	return (
		<>
			<img src={renderOrganizatonLogo} alt="" className="simplici-logo"/>
			<div className="under-review-screen">
				<img src={VOIDED_ERROR_SVG} alt="" />
				<div className="under-review-screen__details">
					<div className="under-review-screen__title">Document Under Review</div>
					<div className="under-review-screen__description">
                    Your document is being reviewed by an admin/compliance officer.
					</div>
					<div className="under-review-screen__support">
						<div className="under-review-screen__footer">
							<div>
								<i className="ri-information-line under-review-screen__footer_icon"></i>
							</div>
							<div className="under-review-screen__footer_text">
								<span className="under-review-screen__footer_text-note">Note: </span>
								<span>
                                  Once the document is approved by the admin/compliance officer, you will receive a URL to complete the document
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
