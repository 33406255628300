import { useCallback } from 'react';

import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { IsViewOnlyConfigState, webComponentEnvelopeIdState } from 'states';
import { GetQueryParams } from 'utils';

interface IUseParams {
	updateState: boolean;
}

export const useQueryParams = ({ updateState = true }: IUseParams) => {
	const setIsViewOnly = useSetRecoilState(IsViewOnlyConfigState);
	const setEnvelopeId = useSetRecoilState(webComponentEnvelopeIdState);
	const { id: paramId } = useParams();
	const envelopeIdFromQuerry = GetQueryParams("envelopeId");

	const getReadOnlyParams = useCallback(() => {
		const readOnly = GetQueryParams('view');
		if (updateState) {
			setIsViewOnly(readOnly === 'true' ? true : false);
		}
		return readOnly;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateState]);

	const getEnvelopeIdParams = useCallback((id?: string) => {
		const envelopeId = paramId || id || envelopeIdFromQuerry || "";
		if (updateState && envelopeId) {
			setEnvelopeId(envelopeId)
		}
		return envelopeId;
	}, [envelopeIdFromQuerry, paramId, setEnvelopeId, updateState]);

	return { getReadOnlyParams, getEnvelopeIdParams };
};
