import type { DragOffsetType, IDropedItems } from '../types';

import { useCallback } from 'react';

import { IPosition } from 'views/signing-dashboard';
import { useSetRecoilState } from 'recoil';

import { DropItemsState } from '../state';

export const useCoordinates = () => {
	const  setDropItems = useSetRecoilState(DropItemsState);
	const getNewXYCoordinates = useCallback(
		(
			container: DOMRect,
			itemWidth: number,
			itemHeight: number,
			clientX: number,
			clientY: number,
			dragOffset: DragOffsetType
		) => {
			const {
				left: containerLeft,
				top: containerTop,
				width: containerWidth,
				height: containerHeight,
				bottom: containerBottom,
				right: containerRight,
			} = container;

			let leftPosition = clientX - containerLeft - dragOffset.offsetX;
			//checking if element is before left of image
			if (leftPosition < 0) {
				leftPosition = 0;
			} else if (clientX + itemWidth > containerRight) {
				//checking if element is after right of image
				leftPosition = containerRight - itemWidth - containerLeft;
			}

			let topPosition = clientY - containerTop - dragOffset.offsetY;
			//checking if element is before top of image
			if (topPosition < 0) {
				topPosition = 0;
			} else if (clientY + itemHeight > containerBottom) {
				//checking if element is after bottom of image
				topPosition = containerBottom - itemHeight - containerTop;
			}

			//converting to percent
			const leftPositionPercent = leftPosition / containerWidth;
			const topPositionPercent = topPosition / containerHeight;

			return [leftPositionPercent, topPositionPercent];
		},
		[]
	);
	const handleResize = useCallback(
		(
			refToElement: HTMLElement,
			currentItem: IDropedItems,
			pageId: string
		) => {
			const target = document.getElementById(pageId);

			if (target) {
				const elementWidth =
					target.clientWidth < refToElement.clientWidth
						? target.clientWidth - 10 //to fix inside the container considering the padding and border
						: refToElement.clientWidth;
				const elementHeight =
					target.clientHeight < refToElement.clientHeight
						? 200 //max-height
						: refToElement.clientHeight;

				const containerRect = target?.getBoundingClientRect();

				const elementRect = refToElement.getBoundingClientRect();
				let { x, y } = elementRect;
				/**
				 * calculated actual right of the element if element has more width than container
				 * take the width of container to get the actual right of element once style is updated
				 */
				const actualRight =
					x + Math.min(containerRect.width, elementRect.width);

				if (containerRect.left > elementRect.left) {
					x = x + elementRect.left - containerRect.left;
				} else if (containerRect.right < actualRight) {
					x = x - (actualRight - containerRect.right);
				}
				if (elementRect.bottom > containerRect.bottom) {
					y = y - (elementRect.bottom - containerRect.bottom);
				}
				const modifiedItem: IDropedItems = JSON.parse(
					JSON.stringify(currentItem)
				);
				const { containerHeight, containerWidth } = modifiedItem;
				if (containerHeight && containerWidth) {
					modifiedItem.metadata = {
						...modifiedItem.metadata,
						height: `${elementHeight}px`,
						width: `${elementWidth}px`,
						// fontSize: { value: fontSize },
					};
					const xLeft = (x - containerRect.left) / containerRect.width;
					const yTop = (y - containerRect.top) / containerRect.height;

					// Calculate new positions based on container size
					const xRight =
						(xLeft * containerWidth + elementWidth) / containerWidth;
					const yBottom =
						(yTop * containerHeight + elementHeight) / containerHeight;

					modifiedItem.position = {
						...modifiedItem.position,
						xLeft,
						yTop,
						xRight,
						yBottom,
					} as IPosition;
					modifiedItem.isNewField = true;
				}
					setDropItems((prev) => {
					const prevState = structuredClone(prev);
					const modifiedItemIndex = prevState.findIndex(({id}: IDropedItems) => id === modifiedItem.id);
					prevState.splice(modifiedItemIndex, 1, modifiedItem);
					return prevState;
				});
			}
		},
		[setDropItems]
	);
	const getResizeBound = useCallback(
		(pageId: string) => document.getElementById(pageId) || 'parent',
		[]
	);
	return {
		getNewXYCoordinates,
		handleResize,
		getResizeBound,
	};
};
