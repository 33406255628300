import type { IDocumentFile, IRecipient, ISignature } from "./types";

import { selector } from "recoil";
import { GetQueryParams } from "utils";

import { SignDocumentState, SignWebComponentDataState, selectedNodeState } from "./state";

export const SignDocSelector = selector({
  key: "sign-document-selector-key",
  get: ({ get }) => {
    const {
      isLoaded: signDocLoaded,
      data,
      error: signDocError,
    } = get(SignDocumentState);
    const { node: selectedNode } = get(selectedNodeState);
    const {
      documents = [],
      recipients,
      _id: envelopeId,
      status,
      purpose,
      reviewSign,
      sender,
      name
    } = data;

    const currentRecipientId = get(SignWebComponentDataState).recipientId || GetQueryParams("recipient");

    const currentRecipientIndex = recipients?.findIndex(
      (recipient) => recipient._id === currentRecipientId
    );

    const currentRecipient = recipients?.[currentRecipientIndex];

    const signature = currentRecipient?.signature ?? ({} as ISignature);

    const { initialDetail, signDetail } = signature;

    const recipient = (currentRecipient ?? {}) as IRecipient;

    const userBioMetrics = {
      initial: initialDetail,
      signature: signDetail,
    };
    const compiledDocs: IDocumentFile[] = [];
    documents.forEach(({ document }) => {
      compiledDocs.push(document);
    });

    const { tooltip: selectedNodeTooltipMessage } =
      selectedNode?.metadata ?? {};
    return {
      signDocLoaded,
      signDocError,
      userBioMetrics,
      recipient,
      selectedNodeTooltipMessage,
      selectedNode,
      currentRecipientIndex,
      envelopeId,
      status,
      signature,
      compiledDocs,
      purpose,
      sender,
      reviewSign,
      name
    };
  },
});
