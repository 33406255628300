import type { IInputChange } from 'types';

import { useCallback, useMemo, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Input } from '@storybook';
import { MESSAGE, RECIPIENT_TYPE } from 'constant';
import { IsFontsLoadedState, TabConfigurationState, SignDocSelector } from 'views';

import { CreateSignState, useCreateSign } from '../store';

export const SignerName = () => {
	const [isError, setIsError] = useState({
		fullName: false,
		initials: false,
	});
	const setFontsLoaded = useSetRecoilState(IsFontsLoadedState);

	const { fullName, initials, isEdit } = useRecoilValue(CreateSignState);
	const { handleChangeCreateSign } = useCreateSign();
	const { recipient: currentRecipient } = useRecoilValue(SignDocSelector);
	const configData = useRecoilValue(TabConfigurationState);

	const handleBlur = useCallback((name: string, value: string) => {
		setIsError((prev) => ({
			...prev,
			[name]: !value.trim(),
		}));
	}, []);

	const handleOnChange = useCallback(
		(name: string, value: string) => {
			const isInitial = name === 'initials';
			if (isInitial && value.length > 3) {
				setIsError({ ...isError, initials: true });
				return;
			}
			setFontsLoaded(false);
			if (isError.initials) setIsError({ ...isError, initials: false });

			handleChangeCreateSign(name, isInitial ? value.toUpperCase() : value);
			setIsError((prev) => ({ ...prev, [name]: false }));
		},
		[handleChangeCreateSign, isError, setFontsLoaded]
	);

	const isInputDisabled = useMemo(() => {
		return (configData && configData.kyc && currentRecipient.type === RECIPIENT_TYPE.ONBOARDING)
	}, [configData, currentRecipient])

	const initialErrorMessage = useMemo(() => initials?.length == 0 ? MESSAGE.INITIAL_EMPTY_ERROR_MESSAGE : MESSAGE.INITIAL_MAX_LENGTH_ERROR_MESSAGE, [initials]);

	const isDisabled = useMemo(() => isInputDisabled || currentRecipient.externalUser || isEdit ,[isInputDisabled, currentRecipient, isEdit])

	return (
		<div className="create-sign-name">
			<div className="create-sign-name__full-name">
				<Input
					label="Full Name *"
					handleChange={(e: IInputChange) => {
						handleOnChange('fullName', e.target.value);
					}}
					placeholder="Enter full Name"
					isError={isError.fullName}
					inputType="text"
					errorMessage=""
					value={fullName}
					disabled={isDisabled}
					maxLength={20}
					handleBlur={() => handleBlur('fullName', fullName)}
				/>
			</div>
			<div className="create-sign-name__initials">
				<Input
					label="Initials *"
					handleChange={(e: IInputChange) => {
						handleOnChange('initials', e.target.value);
					}}
					value={initials.toUpperCase()}
					isError={isError.initials}
					errorMessage={initialErrorMessage}
					placeholder="Enter initials"
					inputType="text"
					handleBlur={() => handleBlur('initials', initials)}
					disabled={isDisabled}
				/>
			</div>
		</div>
	);
};
