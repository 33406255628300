import { FC, createRef, useCallback, useState } from 'react';

import { useOutsideClick } from 'hooks';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { CreateSignState, IsCreateSignModalOpen, selfSignNodeType, useSetCreateSign } from 'views/create-sign';
import { DropItemsState, IDropedItems } from 'views/editor-dashboard';
import { EditOptions } from 'views/signing-dashboard/components/signing-nodes';
import { IKeyPress } from 'types';
import { selectedNodeState } from 'views/signing-dashboard';

import { selfSignSelector, useSetSelfSign } from '../store';

interface IInitialField {
	value: string | undefined;
	item: IDropedItems;
	pageWidth: number;
	pageHeight: number;
	index: number;
}

export const InitialField: FC<IInitialField> = ({
	value,
	item,
	pageHeight,
	pageWidth,
	index,
}) => {
	const setCreateSignModalOpen = useSetRecoilState(IsCreateSignModalOpen);
	const [editMenuOpen, setEditMenuOpen] = useState(false);

	const { handleDeleteSignature } = useSetCreateSign();
	const { selfSigner } = useRecoilValue(selfSignSelector);
	const { removeSignature } = useSetSelfSign();
	const setDropItems = useSetRecoilState(DropItemsState);
	const setSignEdit = useSetRecoilState(CreateSignState)
	const setSelectedNode = useSetRecoilState(selectedNodeState);
	const setFieldType = useSetRecoilState(selfSignNodeType);

	const ref = createRef<HTMLDivElement>();

	useOutsideClick(ref, () => {
		setEditMenuOpen(false);
	});

	const { fieldType } = item;

	const handleCreateInitial = useCallback(() => {
		setCreateSignModalOpen(true);
		if (selfSigner?.signature) {
			setSignEdit((prev) => ({...prev, isEdit: true}));
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		setSelectedNode({index, node: item as any})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selfSigner?.signature]);

	const handleChangeInitial = useCallback(() => {
		setEditMenuOpen((prev) => !prev);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		setSelectedNode({index, node: item as any})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [index, item]);

	const handleSignatureDelete = useCallback(async () => {
		const status = await handleDeleteSignature(fieldType, selfSigner?._id);
		if (status) {
			removeSignature('initial');
			setDropItems((prev) => {
				const prevState = structuredClone(prev);
				const newState = prevState.map((item: IDropedItems) => {
					if (item.fieldType === "initial") {
						return { ...item, value: null };
					}
					return item;
				});
				return newState
			});
		}
		setEditMenuOpen(false);
	}, [
		fieldType,
		handleDeleteSignature,
		removeSignature,
		selfSigner?._id,
		setDropItems,
	]);

	const handleSignatureChange = useCallback(() => {
		setCreateSignModalOpen(true);
		setEditMenuOpen(false);
		setSignEdit((prev) => ({...prev, isEdit: true}))
		setFieldType(fieldType)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleKeyPress = useCallback((event: IKeyPress) => {
		if (event.key === 'Escape') {
			setEditMenuOpen(false);
		}
	}, []);

	return (
		<div
			onKeyDown={handleKeyPress}
			tabIndex={0}
			role="button"
			ref={ref}
			style={{ width: '100%', height: '100%' }}
		>
			{value ? (
				<div
					className="self-sign-node self-sign-node__signature"
					onClick={handleChangeInitial}
				>
					<img src={value} alt="" />
				</div>
			) : (
				<div
					className="self-sign-node__empty-sign"
					onClick={handleCreateInitial}
				>
					Initial
					<i className="ri-download-line" />
				</div>
			)}
			{editMenuOpen && (
				<EditOptions
					handleSignatureChange={handleSignatureChange}
					handleSignatureDelete={handleSignatureDelete}
					pageWidth={item.containerWidth ?? pageWidth}
					pageHeight={item.containerHeight ?? pageHeight}
					position={item.position}
					isDeleteOption={false}
				/>
			)}
		</div>
	);
};
