import type { IIconBtn } from "./type";

import { FC } from "react";

import { v4 } from "uuid";
import { Tooltip } from "@storybook";

import "./icon-btn.scss";

export const IconBtn: FC<IIconBtn> = ({
  btnClassName,
  handleClickIcon,
  id,
  className,
  isDisabled = false,
  toolTip = "",
}) => {
  const actualId = id ?? v4();
  return (
    <>
      <button
        className={`icon-btn ${className} icon-btn${
          isDisabled ? "--disabled" : ""
        }`}
        onClick={handleClickIcon}
        disabled={isDisabled}
        id={actualId}
      >
        <i
          className={`${btnClassName} icon ${isDisabled ? "--disabled" : ""}`}
        />
      </button>
      {toolTip && <Tooltip id={actualId} content={toolTip} />}
    </>
  );
};
