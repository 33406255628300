import { FC, useCallback } from "react";

import { TextArea } from "@storybook";
import { ITextAreaChange } from "types";
import { useGetConfiguredNode, useSetConfigFieldNode } from "views";

interface ITextAreaComponent {
  label: string;
  placeholder: string;
  name: string;
  row?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any;
}

export const TextAreaComponent: FC<ITextAreaComponent> = ({
  label,
  placeholder,
  name,
  row = 4,
  defaultValue,
}) => {
  const { handleConfigureNode } = useSetConfigFieldNode();
  const { configuredNode } = useGetConfiguredNode();

  const handleChange = useCallback(
    (event: ITextAreaChange) => {
      const { value } = event.target;
      handleConfigureNode(name, value);
    },
    [handleConfigureNode, name]
  );

  const value = configuredNode(name) ?? defaultValue;

  /*TODO identify default maximum length*/
  const characterLimit =
    label === "Edit text" ? configuredNode("characterLimit") : "4000";

  return (
    <div className="text-area">
      <TextArea
        label={label}
        placeholder={placeholder}
        value={value}
        handleChange={handleChange}
        row={row}
        maxLength={Number(characterLimit)}
      />
    </div>
  );
};
