import { useCallback, useEffect, useRef } from 'react';

import { IconBtn, Tooltip } from '@storybook';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useDeviceType, DeviceType } from 'views';

import {
	SignDocSelector,
	signingZoomState,
	zoomContentSelector,
	zoomContentSelectorParent,
} from '../store';

interface IconData {
	icon: string;
	toolTip: string;
}
const icon_labels = [
	{ icon: 'ri-zoom-in-line', toolTip: 'Zoom in' },
	{ icon: 'ri-zoom-out-line', toolTip: 'Zoom out' },
];

interface HeightWidth {
	width: number;
	height: number;
}
export const Header = () => {
	const { selectedNodeTooltipMessage } = useRecoilValue(SignDocSelector);
	const normalwidthAndHeight = useRef<HeightWidth[]>([]);
	const zoomInputRef = useRef<HTMLInputElement>(null);
	const { purpose } = useRecoilValue(SignDocSelector);
	const [currentZoomPercent, setCurrentZoom] = useRecoilState(signingZoomState);
	const { deviceType } = useDeviceType();

	const getDefaultSizes = () => {
		if (normalwidthAndHeight.current.length === 0) {
			const pdfWrapper = document.querySelectorAll(
				zoomContentSelector
			) as NodeListOf<HTMLElement>;
			if (normalwidthAndHeight.current.length === 0) {
				for (let index = 0; index < pdfWrapper.length; index++) {
					const element = pdfWrapper[index] as HTMLElement;
					normalwidthAndHeight.current = [
						...normalwidthAndHeight.current,
						{
							width: element.clientWidth,
							height: element.clientHeight,
						},
					];
				}
			}
		}
	};
	// const resizeToPercent = (actualZoom: number) => {
	// 	const pdfWrapper = document.querySelectorAll(
	// 		selector
	// 	) as NodeListOf<HTMLElement>;
	// 	for (let index = 0; index < pdfWrapper.length; index++) {
	// 		const element = pdfWrapper[index] as HTMLElement;
	// 		if (actualZoom === 100) {
	// 			element.style.removeProperty('transform');
	// 			element.style.removeProperty('transform-origin');
	// 		} else if (actualZoom < 100) {
	// 			element.style.transformOrigin = 'center top';
	// 			element.style.transform = `scale(${actualZoom / 100})`;
	// 		} else {
	// 			element.style.transformOrigin = 'left top';
	// 			element.style.transform = `scale(${actualZoom / 100})`;
	// 		}
	// 	}
	// };
	useEffect(() => {
		const pdfWrapper = document.querySelectorAll(
			zoomContentSelector
		) as NodeListOf<HTMLElement>;
		for (let index = 0; index < pdfWrapper.length; index++) {
			const element = pdfWrapper[index] as HTMLElement;
		
				
			element.style.transformOrigin = 'center top';
			element.style.transform = `scale(${currentZoomPercent / 100})`;
			const imageWrapper = document.querySelector<HTMLImageElement>(zoomContentSelectorParent)
			// calculating the width of the zoomed in container
			const imageWidth = element.offsetWidth * (currentZoomPercent / 100);
			if(imageWrapper) {
				// getting width of pdf container
				const containerWidth = imageWrapper.offsetWidth;
				// checcking difference container and pdf 
				const marginLeft = imageWidth - containerWidth;
				// if width of container is less than zoomed in pdf then we apply margin left
				if( marginLeft > 0) {
					element.style.marginLeft = `${marginLeft+50}px`;
					element.style.marginRight = `${50}px`;
				} else {
					element.style.removeProperty('margin-left');
					element.style.removeProperty('margin-right');

				}
				
			}
		}
	}, [currentZoomPercent]);

	const resizeImages = (percentageToChange = 0) => {
		setCurrentZoom((zoom) => {
			const actualZoom = zoom + percentageToChange;
			if (actualZoom <= 300 && actualZoom >= 50) {
				return actualZoom;
			}
			return zoom;
		});
	};
	const handleIconClick = (index: number) => {
		if (index === 0) {
			getDefaultSizes();
			resizeImages(+10);
		} else if (index === 1) {
			getDefaultSizes();
			resizeImages(-10);
		}
	};

	const renderToolkit = icon_labels.map((i: IconData, index: number) => {
		return (
			<div key={`${i.icon}-${i.toolTip}`}>
				<IconBtn
					key={i.icon}
					btnClassName={i.icon}
					className="toolkit_icon"
					id={`${i.icon}-${i.toolTip}`}
					handleClickIcon={() => handleIconClick(index)}
				/>
				{deviceType !== DeviceType.Mobile && (
					<Tooltip
						content={i.toolTip}
						id={`${i.icon}-${i.toolTip}`}
						place="bottom"
					/>
				)}
			</div>
		);
	});

	const setInputValue = useCallback(() => {
		if (
			zoomInputRef.current &&
			zoomInputRef.current.value !== currentZoomPercent.toString()
		) {
			zoomInputRef.current.value = currentZoomPercent.toString();
		}
	}, [currentZoomPercent]);

	const handleZoomChange = useCallback(
		(e: React.FocusEvent<HTMLInputElement> | number) => {
			getDefaultSizes();

			const value = typeof e === 'number' ? e : Number(e.target.value);
			if (value < 50) {
				setCurrentZoom(50);
			} else if (value > 300) {
				setCurrentZoom(300);
			} else {
				setCurrentZoom(value);
			}
			setInputValue();
		},
		[setCurrentZoom, setInputValue]
	);
	useEffect(() => {
		setInputValue();
	}, [setInputValue]);

	useEffect(() => {
		if (window.self !== window.top && purpose === 'workflow') {
			handleZoomChange(130);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="signing-screen__header">
			<div className="signing-screen__header-banner">
					{selectedNodeTooltipMessage || 'Please review the documents below.'}
			</div>
			<div className="signing-screen__control-panel">
				<span id="zoom-container">
					<input
						type="number"
						name="zoom-percent"
						id="zoom-percent"
						ref={zoomInputRef}
						onBlur={handleZoomChange}
						max={300}
						min={50}
						disabled
						maxLength={3}
						minLength={2}
					/>
					<span>%</span>
				</span>
				{renderToolkit}
				<button
					className="button button__small button__filled button__filled--secondary button__outline zoom-reset-button"
					id="reset-zoom-in-out-button"
					disabled={currentZoomPercent === 100}
					onClick={() => handleZoomChange(100)}
					style={{ visibility: currentZoomPercent === 100 ? 'hidden' : 'visible' }}
				>
					Reset
				</button>
				{deviceType !== DeviceType.Mobile && (
					<Tooltip
						content="Reset"
						id="reset-zoom-in-out-button"
						place="bottom"
					/>
				)}
			</div>
		</div>
	);
};
