import { useCallback, useMemo } from 'react';

import { useRecoilValue } from 'recoil';
import {
	DropItemsState,
	IDropedItems,
	useInvitePayload,
} from 'views/editor-dashboard';
import { useNetwork, useNotification } from 'hooks';
import { API_URL } from 'constant';
import { webComponentEnvelopeIdState } from 'states/web-component-state';

import { selfSignSelector } from './selector';

export const useCompleteSign = () => {
	const envelopeId = useRecoilValue(webComponentEnvelopeIdState);

	const dropItems = useRecoilValue(DropItemsState);
	const { saveTemplatePayload } = useInvitePayload({ envelopeId });
	const { selfSigner } = useRecoilValue(selfSignSelector);
	const { patch } = useNetwork({ updateState: false });
	const { successNotification, errorNotification } = useNotification();

	const createCompleteSelfSignPayload = useMemo(() => {
		const configDocPayload = saveTemplatePayload.documents.map((doc) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const docTabs = doc.tabs.map((tab: any) => {
				if (tab.type === 'date') {
					// Format date as dd-mm-yyyy
					return { ...tab, value: tab.value};

				} else {
					// For other types, maintain the existing logic
					return { ...tab, value: `${tab?.value?.id ?? tab?.value}` };
				}
			});
			return { ...doc, tabs: docTabs };
		});

		const fieldNodes = dropItems.map(({ id, value }) => {
			const data: { id: string; value: string; image?: string } = {
				id,
				value: value?.id ?? value,
			};
			return data;
		});

		const completeSignPayload = {
			fieldTabs: fieldNodes,
			currentRecipientId: selfSigner?._id,
		};
		return {
			configPayload: { ...saveTemplatePayload, documents: configDocPayload },
			completeSignPayload,
		};
	}, [dropItems, saveTemplatePayload, selfSigner?._id]);

	const isCompleteDisabled = useMemo(() => {
		if (!dropItems.length) {
			return true;
		}
		return !dropItems.every((item: IDropedItems) => {
			if (item.fieldType === 'hyperlink') {
				return !!item?.metadata?.url?.trim();
			}
			if (item.fieldType === 'checkbox') {
				return !!item?.value;
			}
			if (item.fieldType === 'initial' || item.fieldType === 'signature') {
				if(item?.metadata?.required){
					return !!item.value?.path;
				}else{
					return true;
				}
			}
			return !!item.value?.trim();
		});
	}, [dropItems]);

	const completeSign = useCallback(async (): Promise<boolean> => {
		const envelopePatchURL = `${API_URL.MULTI_SIGN}/${envelopeId}?sendInvite=true`;
		const { configPayload } = createCompleteSelfSignPayload;
		const resp = await patch(envelopePatchURL, configPayload);
		const { response, apiData } = resp ?? {};
		if (response?.status === 200) {
			const { documents } = apiData?.data ?? {};
			const allTabs: IDropedItems[] = [];
			documents.forEach(({ tabs }: { tabs: IDropedItems[] }) =>
				allTabs.push(...tabs)
			);
			const fieldNodes = allTabs.map((tab: IDropedItems & { _id?: string }) => {
				const data: { id: string; value: string; image?: string } = {
					id: tab._id as string,
					value: tab.value,
				};
				return data;
			});
			const completeSignPayload = {
				fieldTabs: fieldNodes,
				currentRecipientId: selfSigner?._id,
			};
			const resp = await patch(
				`${API_URL.ENVELOPE}/${envelopeId}`,
				completeSignPayload
			);
			const { response } = resp ?? {};
			if (response?.status === 200) {
				successNotification('Document signing completed.');
				return true;
			} else {
				errorNotification('Document Signing failed try again later');
				return false;
			}
		}
		return false;
		errorNotification('Document Signing failed try again later');
	}, [
		createCompleteSelfSignPayload,
		envelopeId,
		errorNotification,
		patch,
		selfSigner?._id,
		successNotification,
	]);

	return { completeSign, isCompleteDisabled };
};
