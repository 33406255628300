import { FC, MutableRefObject } from "react";

import { useRecoilState } from "recoil";
import { SideDrawer } from "@storybook";

import { IsConfigDrawerOpen, ConfigNodeBody } from ".";

interface IsConfigNodeDrawer {
  dropContainerDimension: MutableRefObject<{width: number, height: number}>
}

export const ConfigNodeDrawer:FC<IsConfigNodeDrawer> = ({dropContainerDimension}) => {
  const [isOpen, setIsOpen] = useRecoilState(IsConfigDrawerOpen);
  
  return (
    <SideDrawer
      body={<ConfigNodeBody dropContainerDimension={dropContainerDimension} />}
      setVisible={setIsOpen}
      visible={isOpen}
    />
  );
};
