import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { useRecoilState } from 'recoil';
import { Button } from '@storybook';
import { IsUndoModal } from 'views/prepare-dashboard';
import { UndoModalMessages, counterSecond } from 'views/reciepient-modal/store';
import './undo-modal.scss';

interface IUndoModal {
	width?: string;
	setLoading: (value: boolean) => void;
}

export const UndoModal: FC<IUndoModal> = ({ width, setLoading }) => {
	// global states
	const [isUndoModal, setIsUndoModal] = useRecoilState(IsUndoModal);

	// local states
	const [count, setCount] = useState(counterSecond);

	// constants
	const {header, footer} = UndoModalMessages;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const intervalRef = useRef<any>(null);

	// undo modal timer
	useEffect(() => {
		if (isUndoModal) {
			intervalRef.current = setInterval(() => {
				setCount((prevCount) => {
					if (prevCount > 1) {
						return prevCount - 1;
					} else {
						clearInterval(intervalRef.current);
						setIsUndoModal(false);
						return 0;
					}
				});
			}, 1000);
		} else {
			clearInterval(intervalRef.current);
			setCount(counterSecond)
		}

		return () => {
			clearInterval(intervalRef.current);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isUndoModal]);

	const handleUndoModal = useCallback((isProgress: boolean) => {		
		if (isProgress) {
			setIsUndoModal(!isProgress);
			setLoading(!isProgress);
		} else {
			setIsUndoModal(isProgress);
			setLoading(!isProgress);
		}
		setCount(counterSecond);
		return clearInterval(intervalRef.current);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!isUndoModal) {
		return null;
	}

	return (
		<div className="undo-modal__wrapper">
			<div
				className="undo-modal__container"
				style={{ maxWidth: width ?? '100%' }}
			>
				<div className="undo-modal__content">
					<div className="undo-modal__header">
						<span className="undo-modal__header_text">
							{header}
						</span>
						<i
							className="ri-close-line undo-modal__closeIcon"
							onClick={() => handleUndoModal(false)}
						/>
					</div>
					<div className="undo-modal__footer">
						<span>
							{footer}
							<span className="undo-modal__footer_timer">{count}</span> sec.
						</span>
						<Button
							label="Undo"
							handleClick={() => handleUndoModal(true)}
							className="esign-button__filled--secondary esign-button__small undo-modal__footer_undo-btn"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
