// import { useEffect } from "react";
// import { useSetConfigDoc } from "views/editor-dashboard";
import { ASSETS } from "constant";

export const ServerError = () => {
  // const { statusError } = useSetConfigDoc();

  // useEffect(() => {
  //   statusError("decline");
  // }, [statusError]);

  const {SETTINGS_SVG} = ASSETS;

  return (
    <div className="server-error">
      <div className="server-error__description">
        <img src={SETTINGS_SVG} alt="" className="server-error__logo" />
        <div className="server-error__message">Oops! Something went wrong.</div>
      </div>
    </div>
  );
};
