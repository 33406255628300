import { FC, useEffect, useMemo, useRef } from 'react';

import { ENVELOPE_PURPOSE, TAB_SOURCE, VIEW_SIGN_PROD_DATE } from 'constant';
import { useRecoilState, useRecoilValue } from 'recoil';
import { GetQueryParams, formatDate } from 'utils';
import {
	DropItem,
	DropItemsState,
	IDropedItems,
	PrepareDashboardState,
	RecipientsState,
	templateTypeState,
} from 'views';
import { SignNode } from 'views/signing-dashboard/components/sign-node';
import { EnvelopePurposeState } from 'states';

interface IDropItemProps {
	pageId: string;
	pageWidth: number;
	pageHeight: number;
	pageNumber: number;
	docId: string;
	showZIndex: boolean;
}

export const DropItems: FC<IDropItemProps> = ({
	pageWidth,
	pageHeight,
	pageId,
	pageNumber,
	docId,
	showZIndex
}) => {
	const [dropItems, setDropItems] = useRecoilState(DropItemsState);
	const recipients = useRecoilValue(RecipientsState);
	const templateType = useRecoilValue(templateTypeState);
	const envelope = useRecoilValue(PrepareDashboardState);
	const purpose = useRecoilValue(EnvelopePurposeState);
	
	const nodeRefs = useRef<HTMLDivElement[]>([]);
	const templateId = GetQueryParams('templateId');

	useEffect(() => {
		if (purpose === ENVELOPE_PURPOSE.SELFSIGN) {
			setDropItems((prev) => {
				const prevState = structuredClone(prev);
				const newState = prevState.map((item: IDropedItems) => {
					if (item.source === 'standard' && item.fieldType === "date" && !item.metadata.validation) {
						return { ...item, value: formatDate(new Date(), "mm/dd/yyyy")};
					}
					return item;
				});
				return newState
			});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [purpose]);

	/**
	 * Returns an array of remaining drop items based on the provided criteria.
	 *
	 * @param {Array} dropItems - An array of drop items to filter.
	 * @param {Array} recipients - An array of recipients associated with the drop items.
	 * @param {string} templateType - The type of template.
	 * @param {string} templateId - The ID of the template.
	 * @returns {Array} An array of remaining drop items.
	 */
	const remainingDropItems = useMemo(() => {
		return dropItems.filter((item) => {
			// Find the associated recipient
			const associatedRecipient = recipients.find(
				(recipient) => recipient._id === item.recipient
			);
			// If no associated recipient found, don't show the drop item
			if (!associatedRecipient) {
				if (item.source === "common") {
					return !item.value
				}
				return true;
			}
			// Check recipient status
			switch (associatedRecipient?.status) {
				case 'completed':
					return false; // Don't show if status is completed
				case 'pending':
					return true; // Show if status is pending
				case 'underReview':
				case 'approved':
					// Show if status is underReview or approved and node type is signature or initial
					return item.fieldType === 'signature' || item.fieldType === 'initial';
				default:
					// Show based on other conditions
					return (
						(templateType === 'basic' && templateId)
					);
			}
		});
	}, [dropItems, recipients, templateType, templateId]);

	/**
	 * Returns an array of completed recipients nodes based on the provided envelope and dropItems. to show on the pdf
	 * when someone views the document from sentbox/inbox when some of the users have completed the document signing
	 *
	 * @param {Object} envelope - The envelope object containing recipient information.
	 * @param {Array} dropItems - An array of items to filter and map into completed recipients nodes.
	 * @returns {Array} An array of completed recipients nodes.
	 */
	const completedRecipientsNodes = useMemo(() => {
		// Destructuring the recipients object from the envelope or assigning an empty object if envelope is falsy
		const { recipients, createdAt } = envelope || {};

		const oldPackets = new Date(createdAt as string) < VIEW_SIGN_PROD_DATE;
		if (oldPackets) {
			return [];
		}
		// Extract the IDs of completed recipients
		const completedRecipientsId = (recipients || [])
			.filter(
				(recipient) =>
					recipient.status === 'completed' ||
					recipient.status === 'underReview' ||
					recipient.status === 'approved'
			)
			.map((recipient) => recipient._id) as string[];

		// Filter and map dropItems into completed recipients nodes
		return dropItems
			.filter((item) => completedRecipientsId.includes(item.recipient) || (item.source === TAB_SOURCE.COMMON && !!item.value))
			.map((item) => {
				// Check if the item's fieldType is 'signature' or 'initial'
				if (item.fieldType === 'signature' || item.fieldType === 'initial') {
					// Extract signUrl and initialUrl from signDetail and initialDetail respectively
					const signUrl = item.signature?.signDetail?.document?.path;
					const initialUrl = item.signature?.initialDetail?.document?.path;

					// Return the item with additional properties for signature or initial field types
					return {
						...item,
						value: item.fieldType === 'initial' ? initialUrl : signUrl,
						recipientStatus: 'completed',
						type: item.fieldType,
						isShow: true,
					};
				}
				// Return the item with updated recipientStatus for non-signature and non-initial field types
				return { ...item, recipientStatus: 'completed', type: item.fieldType };
			});
	}, [dropItems, envelope]);

	return (
		<>
			{remainingDropItems.map((item: IDropedItems, index: number) => {
				return (
					item.pageNumber === pageNumber &&
					item.documentId === docId && (
						<DropItem
							key={`${item.id}-${index}`}
							item={item}
							index={index}
							pageId={pageId}
							pageWidth={pageWidth}
							pageHeight={pageHeight}
							customStyle={showZIndex ? {zIndex: 2} : {}}
						/>
					)
				);
			})}
			{completedRecipientsNodes.map(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(node: IDropedItems | any, index: number) => {
					return (
						<SignNode
							key={`${docId}-${index}`}
							index={index}
							node={node}
							nodeRefs={nodeRefs}
							docId={docId}
							pageNumber={pageNumber}
							pageId={pageId}
							pageHeight={pageHeight}
							pageWidth={pageWidth}							
							customStyles={{zIndex: 1}}
						/>
					);
				}
			)}
		</>
	);
};
