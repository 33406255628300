/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import * as ReactDOM from 'react-dom/client';
import reactToWebComponent from 'react-to-webcomponent';

import App from './App';

const SimpliciSignContainer = reactToWebComponent(
	App as any,
	React as any,
	ReactDOM as any,
	{
		props: {
			url: "string",
			env: "string",
			submit_esign: Function,
			hide_logo: "boolean",
			hide_user: "boolean",
		},
	}
);

customElements.define('simplici-sign', SimpliciSignContainer as any);
