/**
 * @Manish Import necessary types and dependencies
 * */ 
import type { IInputChange, ITextAreaChange } from 'types'; // Import custom types

import { Dispatch, FC, SetStateAction } from 'react'; // Import React's FC component

import { Accordion, TextArea } from '@storybook'; // Import Accordion and TextArea components from Storybook

import { IRecipientUser, useRecipient } from '../store'; // Import recipient-related data and custom hooks from a store

/**
 * @Manish Define the properties that the PrivateMessage component receives
 * */ 
interface IPrivateMessage {
	index: number; // The index of the recipient in the list
	recipient: IRecipientUser; // The recipient data
	handleChange: (
		event: IInputChange | ITextAreaChange,
		name: keyof IRecipientUser
	) => void; // A function to handle changes in input or textarea
	isMessageError: boolean; // A flag indicating whether there is an error in the message
	isOpen: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

/**
 * @Manish Define the PrivateMessage functional component
 * */ 
export const PrivateMessage: FC<IPrivateMessage> = ({
	index,
	recipient,
	handleChange,
    isMessageError,
	isOpen,
	setIsOpen
}) => {
	/**
     * @Manish Get the setRecipientsWithValue function from a custom hook
     * */ 
	const { setRecipientsWithValue } = useRecipient();

	/**
     * @Manish Render an Accordion component to contain the private message input
     * */ 
	return (
		<Accordion
			header={
				<>
					<i className="ri-message-line private-message-header-icon"></i>
					Private message added
				</>
			}
			actionIconButton="ri-delete-bin-5-fill add-recipient__remove-recipient"
			handleActionClick={() => {
				/**
                 * @Manish Clear the message and disable private messaging when the delete button is clicked
                 * */ 
				setRecipientsWithValue('message', undefined, index);
				setRecipientsWithValue('isPrivateMessageEnabled', false, index);
			}}
			isError={isMessageError} // Set the error state based on the isMessageError prop
			setIsOpen={setIsOpen}
			isOpen={isOpen}
		>
			<div className="private-message-text-box-container">
				{/* @Manish Render a TextArea for entering a private message */}
				<TextArea
					label={''}
					value={recipient?.message || ''}
					placeholder={'Type custom message here'}
					maxLength={1000}
					handleChange={(event) => handleChange(event, 'message')} // Handle changes in the private message
					isError={isMessageError} // Set error state for the TextArea
					id={`private-message-input-${recipient.id}`} // Unique ID for the input element
				/>
			</div>
		</Accordion>
	);
};
