import type { IFocusEvent, IInputChange, ITextAreaChange } from 'types';
import type { IMetadata, ITab } from '../store';

import { FC, useEffect } from 'react';

import { Tooltip } from '@storybook';
import { DeviceType, useDeviceType } from 'views';

interface ITextNode {
	name: string;
	metadata: IMetadata;
	label: string;
	handleChangeText: (e: ITextAreaChange & IInputChange & IFocusEvent) => void;
	nodeId: string;
	value?: string;
	isSelectedClass?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleSelectedNode?: (event: any) => void;
	node: ITab;
}

export const TextNode: FC<ITextNode> = ({
	name,
	metadata,
	label,
	handleChangeText,
	nodeId,
	value = '',
	isSelectedClass,
	handleSelectedNode,
	node,
}) => {
	const { deviceType } = useDeviceType();

	useEffect(() => {
		const textArea = document.getElementById(
			`${nodeId}-textarea`
		) as HTMLElement;
		if (textArea) {
			textArea.style.fontSize = `${metadata.fontSize}`;
			textArea.style.fontFamily = `${metadata.fontFamily}`;
		}
		if (isSelectedClass) {
			textArea?.focus();
			textArea.style.border = 'none !important';
			textArea.style.color = `${metadata.fontColor}`
		}
	}, [
		isSelectedClass,
		metadata.fontColor,
		metadata.fontFamily,
		metadata.fontSize,
		nodeId,
		value,
	]);

	return (
		<>
			<textarea
				id={`${nodeId}-textarea`}
				className={`signing-text-node signing-text-node__text-area ${isSelectedClass}`}
				onFocus={handleSelectedNode}
				onChange={handleChangeText}
				name={name}
				value={value}
				required
				rows={1}
				placeholder={(metadata.editText || label) as string}
				maxLength={Number(metadata.characterLimit ?? 100)}
				style={{
					minHeight: metadata.height,
					width: metadata.width,
				}}
			></textarea>
			{!metadata.readOnly && deviceType !== DeviceType.Mobile && (
				<Tooltip
					id={`${nodeId}-textarea`}
					variant="info"
					content={`${
						node.metadata.required === false
							? 'Optional field'
							: 'Required field'
					} ${label}`}
					className="pdf-signer-container__tooltip"
				/>
			)}
		</>
	);
};
