
import { v4 } from 'uuid';
import { atom } from 'recoil';

export const IsFacialModalOpen = atom<boolean>({
	key: 'is-Facial-modal-open',
	default: false,
});

export const IFacialBioMetricDoneState = atom<boolean>({
	key: 'facial-biometric-state-done',
	default: false,
});

export const FacialUserImageState = atom<string>({
	key: v4(),
	default: '',
});

export const ICameraPermissionONState = atom<boolean>({
	key: 'is-camera-persmission-on',
	default: true,
});

export const IsFaceMeshLoadedState = atom<boolean>({
	key: 'is-face-modal-setup-state-key',
	default: false,
});