import { FC, useCallback, useEffect, useMemo, useRef } from 'react';

import { GifLoader, Modal } from '@storybook';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useNotification } from 'hooks';

import {
  KYCPrepareUrl,
  KYCPrepareUrlLoadingState,
  UserKYCFailed,
  UserKYCModalState,
  useUserKyc
} from './store';

/**
 * Define the shape of props for UserKyc component
 * */ 
interface IUserKyc {
  envelopeId: string;
  recipientId: string;
}

/**
 * Component for handling the User KYC process
 * */ 
export const UserKyc: FC<IUserKyc> = ({ envelopeId, recipientId }) => {
  /**
   * Access custom hooks from the 'store' and 'hooks' for managing state and data fetching
   * */ 
  const { fetchKycSessionStatusInterval, fetchKycUrl } = useUserKyc();
  const [isOpenKycFrame, setIsOpenKycFrame] = useRecoilState(UserKYCModalState);
  const isLoaded = useRecoilValue(KYCPrepareUrlLoadingState);
  const prepareObj = useRecoilValue(KYCPrepareUrl);
  const setUserKycFailed = useSetRecoilState(UserKYCFailed);
  const { successNotification, errorNotification } = useNotification();
  
  /**
   * Maintain the reference for interval to manage the polling
   * Using any for flexibility
   * */ 
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   const intervalRef = useRef<any>(null); 
  /**
   * Callback for successful completion of KYC
   * */ 
  const successCallBack = useCallback(() => {
    setIsOpenKycFrame(false);
    successNotification('Kyc Completed successfully');
  }, [setIsOpenKycFrame, successNotification]);

  /**
   * Callback when KYC is rejected
   * */
  const kycRejectedCallBack = useCallback(() => {
    setIsOpenKycFrame(false);
    errorNotification("KYC rejected, re-initiating the kyc session.")
    const payload = { recipientId, envelopeId };
    setTimeout(() => {
      fetchKycUrl(payload).then(() => {
        setIsOpenKycFrame(true);
      });
    }, 1000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envelopeId, fetchKycUrl, recipientId]);

  /**
   * Callback for error during KYC process
   * */ 
  const errorCallBack = useCallback(() => {
    errorNotification("Failed to start Kyc frame");
    setIsOpenKycFrame(false);
    setUserKycFailed(true)
  }, [errorNotification, setIsOpenKycFrame, setUserKycFailed]);

  /**
   * Polling mechanism to check KYC status at regular intervals
   * */ 
  useEffect(() => {
    if (prepareObj?.link && prepareObj?.nodeId) {
      intervalRef.current = setInterval(() => {
        fetchKycSessionStatusInterval(
          prepareObj.nodeId ?? '',
          intervalRef.current,
          successCallBack,
          errorCallBack,
          kycRejectedCallBack
        );
      }, 3000);
      /**
	   * Clean up interval on component unmount or change in prepareObj
	   * */ 
      return () => {
        clearInterval(intervalRef.current);
      };
    }
    /**
	 * Clean up interval if prepareObj becomes null or undefined
	 * */ 
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [prepareObj, fetchKycSessionStatusInterval, successCallBack, errorCallBack, kycRejectedCallBack]);

  /**
   * Determine the content to display in the KYC modal
   * */ 
  const kycModalIframe = useMemo(() => {
    return !isLoaded || !prepareObj?.link ? (
      <div className="user-kyc__loader">
        <GifLoader dimension={60} type="gif" text="Fetching Kyc session" />
      </div>
    ) : (
      <iframe
        title="Reinvite"
        src={prepareObj?.link ?? ''}
        allow="camera; microphone; geolocation"
        className="user-kyc__iframe"
      />
    );
  }, [isLoaded, prepareObj?.link]);

  /**
   * Render the KYC modal with the determined content
   * */ 
  return (
    <Modal
      isOpen={isOpenKycFrame}
      modalName="user-kyc-modal"
      className="user-kyc__modal"
      showCloseBtn={false}
    >
      {kycModalIframe}
    </Modal>
  );
};
