import { createRef, FC } from 'react';

import { ColorResult, SketchPicker } from 'react-color';
import { useOutsideClick } from 'hooks';

interface ISketchColorPicker {
	handleOnChange: (color: ColorResult) => void;
	currentColor: string;
	closeOutside: () => void;
}

export const SketchColorPicker: FC<ISketchColorPicker> = ({
	handleOnChange,
	currentColor,
	closeOutside,
}) => {
	const ref = createRef<HTMLDivElement>();

	useOutsideClick(ref, () => {
		closeOutside();
	});

	return (
		<div ref={ref}>
			<SketchPicker color={currentColor} onChangeComplete={handleOnChange} />
		</div>
	);
};
