import { useCallback, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useNetwork } from 'hooks';
import { API_URL } from 'constant';
import { useRecoilValue } from 'recoil';
import { SignWebComponentDataState } from 'views/signing-dashboard';
import { EnvelopePurposeState, webComponentEnvelopeIdState } from 'states';

export const useTermAndConditions = () => {
	const [isLoaded, setIsLoaded] = useState(true);

	const { patch } = useNetwork();
	const { id: param } = useParams()
	const { envelopeId } = useRecoilValue(SignWebComponentDataState);
	const purpose = useRecoilValue(EnvelopePurposeState);
	const envId = useRecoilValue(webComponentEnvelopeIdState);

	const updateConsent = useCallback(
		async (reciepientId: string): Promise<boolean> => {
			setIsLoaded(false);
			const payload = {
				consent: true,
				envelopeId: purpose === "selfsign" ? (envId || param) : (envelopeId || param),
			};
			const resp = await patch(`${API_URL.CONSENT}/${reciepientId}`, payload);
			const { response } = resp;
			if (response?.status === 200) {
				setIsLoaded(true);
				return true;
			}
			setIsLoaded(true);
			return false;
		},
		[envId, envelopeId, param, patch, purpose]
	);

	return { updateConsent, isLoaded };
};
