import { useEffect, useState } from 'react';

import { useSetRecoilState } from 'recoil';

import { ICameraPermissionONState } from '../store';
import { CameraOFF } from '../assets';
import './camera-setting.scss';

export const CameraSettings = () => {
	const setCameraPermission = useSetRecoilState(ICameraPermissionONState);
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	//Arun Kumar : Update screen width
	const updateScreenWidth = () => {
		setScreenWidth(window.innerWidth);
	};

	useEffect(() => {
		//Arun Kumar : Add an event listener to update the screenWidth whenever the window is resized.
		window.addEventListener('resize', updateScreenWidth);

		//Arun Kumar : Clean up the event listener when the component unmounts.
		return () => {
			window.removeEventListener('resize', updateScreenWidth);
		};
	}, []);

	useEffect(() => {
		// Cast "camera" to PermissionName
		const permissionName = 'camera' as PermissionName;

		// Use the casted permissionName to query for camera permission
		navigator.permissions
			.query({ name: permissionName })
			.then((permissionStatus) => {
				permissionStatus.onchange = () => {
					setCameraPermission(true);
				};
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			style={{ width: screenWidth < 480 ? screenWidth : '640' }}
			className="Esign-camera-setting"
		>
			<img className="Esign-camera-setting__permission-img" src={CameraOFF} />
			<p className="Esign-camera-setting__permissions">
				Your camera Permission is OFF
			</p>
		</div>
	);
};
