import { FC } from 'react';

import { ReactSwitch } from '@storybook';
import { IMessageBanner, MessageBanner } from '@storybook/banner';

interface IAdditionalFlag {
	value: boolean;
	handleChange: (value: boolean) => void;
	heading: string;
	description: string;
    warning: Partial<IMessageBanner> | null;	
	isDisabled?: boolean;
}

export const AdditionalFlag: FC<IAdditionalFlag> = ({
	handleChange,
	value,
	heading,
	description,
	warning, 
	isDisabled = false,   
}) => {
	return (
		<div className='add-recipient__original-copy-wrapper' >
			<div className="add-recipient__original-copy">
				<div className="add-recipient__original-copy__toogle-wrapper">
					<span>{heading}</span>
					<ReactSwitch id={''} checked={value} handleChange={handleChange} isDisabled={isDisabled} />
				</div>
				<span className="add-recipient__original-copy__toogle-description">
					{description}
				</span>
			</div>
			{warning && (
				<MessageBanner
					message={warning?.message ?? ""}
					varient={warning?.varient ?? 'warning'}
				/>
			)}
		</div>
	);
};
