import { atom } from "recoil";

type IEnvState = "stage" | "preprod"| "beta" | "prod";

export const ENV_STATE: {[key in Uppercase<IEnvState>]: IEnvState } = {
    PREPROD: "preprod",
    PROD: "prod",
    STAGE: "stage",
    BETA: "beta",
}

export const EnvState = atom<IEnvState>({
    key: "esign-env-state-key",
    default: ENV_STATE.STAGE,
})