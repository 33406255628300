import type { IRecipientOption } from "./types"

import { CSSProperties, useCallback, useMemo } from "react";

import { IReactDropdown, ReactDropdown } from "@storybook";
import { useRecoilValue } from "recoil";
import { EditRecipientsButton, signerRecipientsState } from "views";

import "./select-recipient";

const menuListStyle: CSSProperties = {
  padding: 0,
};
const optionsStyle: CSSProperties = {
  padding: "8px 16px",
  display: "flex",
  alignItems: "center",
  borderBottom: "unset",
};

const valueContainerStyle: CSSProperties = {
  padding: "unset !important",
  paddingLeft: "8px !important",
};

export const SelectRecipientDropDown = ({
  handleSelectRecipient,
  selectedRecipientValue,
  customBtnHandler,
  commonFieldBtnHandler,
  customBtn,
  commonFieldBtn,
  controlStyle,
  menuStyle,
  defaultValue,
  isDisable
}: {
  handleSelectRecipient: (event: IRecipientOption) => void;
  selectedRecipientValue: IRecipientOption;
  commonFieldBtnHandler?: () => void;
  customBtnHandler?: () => void;
  customBtn?: string;
  commonFieldBtn?: string | JSX.Element;
  menuStyle: CSSProperties;
  controlStyle: CSSProperties;
  defaultValue: IRecipientOption;
  isDisable?: boolean
}) => {
  const recipients = useRecoilValue(signerRecipientsState);
  const options: IRecipientOption[] = useMemo(() => {
    return recipients.length
      ? recipients.map((recipient) => ({
        label: recipient.fullName || recipient.title || "",
        value: recipient.id || "",
        color: recipient.color,
      }))
      : [];
  }, [recipients]);
  
  const getOptionLabelForType = useCallback((option: IRecipientOption) => {
    return (
      <div className="select-options">
        <span style={{ backgroundColor: option.color }}></span>{" "}
        <p>{option.label}</p>
      </div>
    );
  }, []);
  const propsToPass: IReactDropdown = useMemo(() => {
    const temp: IReactDropdown = {
      handleChangeSelect: handleSelectRecipient,
      controlStyle: controlStyle,
      options: options,
      menuStyle: menuStyle,
      value: selectedRecipientValue,
      defaultValue: defaultValue,
      optionsStyle: optionsStyle,
      Isdisable: isDisable,
      //@ts-ignore
      getOptionLabel: getOptionLabelForType,
      menuListStyle: menuListStyle,
      valueContainerStyle: valueContainerStyle,
    };
    if (customBtn) {
      temp.customBtn = <EditRecipientsButton />;
      temp.customBtnHandler = customBtnHandler;
    }
    if (commonFieldBtn) {
      temp.commonFieldBtn = commonFieldBtn;
      temp.commonFieldBtnHandler = commonFieldBtnHandler;
    }
    return temp;
  }, [
    controlStyle,
    customBtn,
    customBtnHandler,
    defaultValue,
    getOptionLabelForType,
    handleSelectRecipient,
    menuStyle,
    options,
    selectedRecipientValue,
    commonFieldBtnHandler,
    commonFieldBtn,
    isDisable
  ]);
  return <ReactDropdown {...propsToPass} />;
};
