import type { IInputChange } from "types";

import { useCallback } from "react";

import { useRecoilValue } from "recoil";
import { Checkbox } from "@storybook";

import { CreateSignState, useCreateSign } from "../store";

import { SignerName, SignStepper } from ".";

export const CreateSign = () => {
  const { consent } = useRecoilValue(CreateSignState);
  const { handleChangeCreateSign } = useCreateSign();

  const handleCheck = useCallback(
    (e: IInputChange) => {
      const { checked } = e.target;
      handleChangeCreateSign("consent", checked);
    },
    [handleChangeCreateSign]
  );

  return (
    <div className="create-sign">
      <div className="create-sign__label">
        Confirm your name, initial and signature.
      </div>
      <div className="create-sign__name">
        <SignerName />
      </div>
      <div className="create-sign__step-wrapper">
        <SignStepper />
      </div>
      <div className="create-sign__consent">
        <div className="create-sign__consent-description">
          By signing this document with an electronic signature, I agree that
          such signature will be as valid as handwritten signatures to the
          extent allowed by local law.
        </div>
        <Checkbox
          checked={consent}
          handleCheck={handleCheck}
          label="By checking this box I Accept the esign Consent."
        />
        <div></div>
      </div>
    </div>
  );
};
