import { atom } from "recoil";

import { IRecipient } from "../components";

export const SenderDetailsState = atom<IRecipient>({
  key: "prepare-envelope-sender-details",
  default: undefined,
});

export const WebAuthCounter = atom({
  key: "web-auth-counter-state-key",
  default: 0,
})