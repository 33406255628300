import { ASSETS } from "constant";


export const LinkExpired = () => {
  const {LINK_EXPIRED_SVG} = ASSETS;
  
  return (
    <div className="link-expired">
      <div className="link-expired__description">
        <img src={LINK_EXPIRED_SVG} alt="" className="slink-expired__logo" />
        <div className="link-expired__message-heading">Oops! it looks like you have landed on a broken link</div>
        <div className="link-expired__message-desc">The link is expired. Contact the sender for a new link</div>
      </div>
    </div>
  );
};
