/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useState } from 'react';

import { REACT_APP_API_HOST } from 'envs';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { SignHostState } from 'views';
import { ENV_STATE, EnvState } from 'states';
interface IConfig {
	headers?: {
		Authorization?: string;
		'Content-Type'?: string;
		'Cache-Control'?: string;
	};
}
interface IProps {
	updateState?: boolean;
}

export const useNetwork = (
	{ updateState = true }: IProps = { updateState: true }
) => {
	const host = useRecoilValue(SignHostState);
	const API_HOST = useMemo(() => (host ? host : REACT_APP_API_HOST), [host]);

	const accessToken = '';
	const [data, setData] = useState<any>(null);
	const [error, setError] = useState<any>(null);
	const [isLoaded, setIsLoaded] = useState<boolean>(true);
	const [apiResponse, setApiResponse] = useState<any>(null);
	const setEnv = useSetRecoilState(EnvState);

	const config: IConfig = useMemo(() => ({}), []);
	const postConfig: IConfig = useMemo(() => ({}), []);

	if (accessToken) {
		config.headers = {
			Authorization: `Bearer ${accessToken}`,
		};
	}

	config.headers = {
		...(config.headers ?? {}),
		'Cache-Control': 'no-cache',
	};

	postConfig.headers = {
		'Content-Type': 'application/json',
		'Cache-Control': 'no-cache',
		...(config.headers ?? {}),
	};

	useEffect(() => {
		if (API_HOST) {
			const stage = /stage/gi.test(API_HOST);
			const preprod = /pp/gi.test(API_HOST);
			const beta = /beta/gi.test(API_HOST);
			const prod = /api.satschel.com/gi.test(API_HOST);
			if (stage) {
				setEnv(ENV_STATE.STAGE);
			} else if (preprod) {
				setEnv(ENV_STATE.PREPROD);
			} else if (beta) {
				setEnv(ENV_STATE.BETA);
			} else if (prod) {
				setEnv(ENV_STATE.PROD);
			} else {
				setEnv(ENV_STATE.STAGE);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [API_HOST]);

	const apiHostExists = useMemo(() => !!API_HOST, [API_HOST]);
	const get = useCallback(
		async (url: string, configHeader?: any): Promise<any> => {
			setIsLoaded(false);
			try {
				const response = await fetch(API_HOST + url, configHeader ?? config);
				const apiData = await response.json();
				if (updateState) {
					setIsLoaded(true);
					setData(apiData);
				}
				return { response, apiData };
			} catch (err: any) {
				if (updateState) {
					setError(err);
				}
				const response = { status: 500 };
				return { response };
			} finally {
				if (updateState) {
					setIsLoaded(true);
				}
			}
		},
		[API_HOST, config, updateState]
	);

	const post = useCallback(
		async (url: string, requestJSON: any) => {
			if (updateState) {
				setIsLoaded(false);
			}
			try {
				const response = await fetch(API_HOST + url, {
					method: 'POST',
					...postConfig,
					body: JSON.stringify(requestJSON),
				});
				if (response.status === 500) {
					if (updateState) {
						setError(response.type);
					}
				}
				const apiData = await response.json();
				if (url.includes('charts?businessId')) {
					if (updateState) {
						setData(apiData);
						setApiResponse(response);
					}
					return { response, apiData };
				}
				// const apiResponse = apiData.data ?? apiData;
				if (updateState) {
					setData(apiData);
					setApiResponse(response);
				}
				return { response, apiData };
			} catch (err: any) {
				if (updateState) {
					setError(err);
				}
				return null;
			} finally {
				if (updateState) {
					setIsLoaded(true);
				}
			}
		},
		[updateState, API_HOST, postConfig]
	);

	const formData = useCallback(
		async (url: string, requestJSON: any) => {
			setIsLoaded(false);
			try {
				const response = await fetch(API_HOST + url, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
					body: requestJSON,
				});
				const apiData = await response.json();
				if (updateState) {
					setData(apiData);
					setApiResponse(response);
				}
				return { response, apiData };
			} catch (err) {
				if (updateState) {
					setError(err);
				}
				const response = { status: 500 };
				return { response };
			} finally {
				if (updateState) {
					setIsLoaded(true);
				}
			}
		},
		[API_HOST, updateState]
	);

	const put = useCallback(
		async (url: string, requestJSON?: any) => {
			setIsLoaded(false);
			try {
				const response = await fetch(API_HOST + url, {
					method: 'PUT',
					...postConfig,
					body: JSON.stringify(requestJSON),
				});
				const apiData: any = await response.json();
				if (updateState) {
					setData(apiData.data);
					setApiResponse(response);
				}
				return { response, apiData };
			} catch (err: any) {
				if (updateState) {
					setError(err);
				}
				const response = { status: 500 };
				return { response };
			} finally {
				if (updateState) {
					setIsLoaded(true);
				}
			}
		},
		[API_HOST, postConfig, updateState]
	);

	const remove = useCallback(
		async (url: string, requestJSON?: any) => {
			if (updateState) {
				setIsLoaded(false);
			}
			try {
				const response = await fetch(API_HOST + url, {
					method: 'DELETE',
					...postConfig,
					body: JSON.stringify(requestJSON),
				});
				const apiData = await response.json();
				if (updateState) {
					setData(apiData.data);
					setApiResponse(response);
				}
				return { response, apiData };
			} catch (err: any) {
				if (updateState) {
					setError(err);
				}
				const response = { status: 500 };
				return { response };
			} finally {
				if (updateState) {
					setIsLoaded(true);
				}
			}
		},
		[API_HOST, postConfig, updateState]
	);

	const patch = useCallback(
		async (url: string, requestJSON?: any) => {
			if (updateState) {
				setIsLoaded(false);
			}
			try {
				const response = await fetch(API_HOST + url, {
					method: 'PATCH',
					...postConfig,
					body: JSON.stringify(requestJSON),
				});
				const apiData = await response.json();
				if (updateState) {
					setData(apiData);
					setApiResponse(response);
				}
				return { response, apiData };
			} catch (err: any) {
				if (updateState) {
					setError(err);
				}
				const errorResponse = { status: 500 };
				return { errorResponse };
			} finally {
				if (updateState) {
					setIsLoaded(true);
				}
			}
		},
		[updateState, API_HOST, postConfig]
	);

	return {
		get,
		post,
		formData,
		remove,
		put,
		patch,
		data,
		error,
		isLoaded,
		apiResponse,
		apiHostExists
	};
};
