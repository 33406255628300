export const isValidMobileNumber = (mobileNumber: string) => {
  // eslint-disable-next-line no-useless-escape
  const mob_regex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;
  const isValid = mob_regex.test(mobileNumber);
  if (isValid) {
    return true;
  } else {
    return false;
  }
};

export const isNumber = (number: string) => {
  const number_regex = /^[0-9]*$/;
  const isValid = number_regex.test(number);
  if (isValid) {
    return true;
  } else {
    return false;
  }
};

export const isValidLink = (link: string) => {
  try {
    new URL(link);
    return true;
  } catch (error) {
    return false;
  }
};

export const isValidEmail = (email: string) => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
};


export const documentTypeRegex = /^application\/(pdf|msword|vnd\.openxmlformats-officedocument\.wordprocessingml\.document)$/;