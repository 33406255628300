import type { IConfirmationModal } from "./type";

import { FC } from "react";

import { Button } from "@storybook";
import { ASSETS } from "constant";

export const Confirmation: FC<IConfirmationModal> = ({
  visible,
  title,
  description,
  handleModal,
  boldDescription = "",
  label,
  type = "primary",
}) => {
  if (!visible) {
    return null;
  }

  const {BANNER_SVG} = ASSETS;

  return (
    <div
      className="esign-modal-main"
      tabIndex={0}
      role="button"
      onKeyDown={() => ({})}
      onClick={() => handleModal(false, false)}
    >
      <div
        tabIndex={0}
        role="button"
        onKeyDown={() => ({})}
        className="esign-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="esign-confirmation-header">
          <img src={BANNER_SVG} alt="" className="esign-confirmation-image" />
          <div className="esign-modal-title">{title}</div>
        </div>
        <div>
          <div className="esign-modal-des">
            <span>
              {description}
              {boldDescription && <strong>{boldDescription}</strong>}
            </span>
          </div>
          <div className="esign-modal-footer">
            <Button
              label="Cancel"
              handleClick={() => handleModal(false, false)}
              className="esign-button__filled esign-button__filled--secondary esign-button__large"
            />
            <Button
              label={label}
              handleClick={() => handleModal(false, true)}
              className={`esign-button__filled esign-button__filled--${type} esign-button__large`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
