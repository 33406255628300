import { useCallback, useState } from 'react';

import { useSetRecoilState } from 'recoil';
import { API_URL, MESSAGE } from 'constant';
import { useNetwork, useNotification } from 'hooks';
import {
	ConfigDocumentState,
	configDocTitleState,
	useSetConfigDoc,
} from 'views/editor-dashboard';
import {
	IsEnableMerchantFlow,
	RecipientLocalState,
	RecipientsState,
	getColor,
	isSigningOrderEnabledState
} from 'views/reciepient-modal';
import { v4 } from 'uuid';
import { IRecipient, newRecipient } from 'views';
import { organizationDetailState } from 'states';

import {
	IEnvelopeListDetails,
	ITemplateType,
	IUploadDoc,
	TemplateSourceState,
	UploadedEnvelopeDocsState,
	templateTypeState,
} from '.';

const { FILE_UPLOADED, UPLOAD_FAILED } = MESSAGE;

export const useUploadMultiDoc = () => {
	const setEnvelopeDocs = useSetRecoilState(UploadedEnvelopeDocsState);
	const [isLoaded, setIsLoaded] = useState(true);

	const { successNotification, errorNotification } = useNotification();
	const { post, remove, patch } = useNetwork();

	const uploadDocuments = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async (payload: IUploadDoc): Promise<any> => {
			const resp = await post(`${API_URL.UPLOAD}`, payload);
			return resp;
		},
		[post]
	);

	const uploadDoc = useCallback(
		async (payload: IUploadDoc): Promise<void> => {
			setEnvelopeDocs((prev) => ({
				...prev,
				isLoading: true,
			}));
			const resp = await uploadDocuments(payload);
			const { apiData } = resp ?? {};
			if (apiData?.data) {
				const { data } = apiData.data;
				const { _id: id, name: documentName = '' } = data ?? {};
				const uploadDoc: IEnvelopeListDetails = {
					id,
					documentName,
					tabsCount: '0',
					pagesCount: data?.pages?.length ?? 0,
					tabs: [],
					newUpload: true,
				};
				setEnvelopeDocs((prev) => ({
					...prev,
					isLoading: false,
					data: [...prev.data, uploadDoc],
				}));
				successNotification(FILE_UPLOADED);
				return;
			}
			errorNotification(apiData.message === "Encrypted Document" ? apiData.message : UPLOAD_FAILED);
			setEnvelopeDocs((prev) => ({
				...prev,
				isLoading: false,
			}));
			return;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[uploadDocuments]
	);

	const swapDoc = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async (payload: any, docId: string, index: number) => {
			setIsLoaded(false);
			const uploadResponse = await uploadDocuments(payload);
			const { apiData: uploadedDoc, response: uploadedDocResponse } =
				uploadResponse;
			if (uploadedDocResponse.status === 200) {
				const { _id } = uploadedDoc.data.data;
				const swapResp = await patch(`${API_URL.DOCUMENTS}/${docId}`, { _id });
				const { apiData, response } = swapResp;
				if (response?.status === 200) {
					const { name } = apiData.data;
					setEnvelopeDocs((prev) => {
						const prevState = structuredClone(prev.data);
						const foundDoc = prevState[index];
						if (foundDoc) {
							prevState.splice(index, 1, {
								...foundDoc,
								documentName: name ?? '',
							});
						}
						return { isLoading: prev.isLoading, data: prevState };
					});
					successNotification('Document swapped successfully.');
				} else {
					errorNotification(
						apiData.message ?? 'Failed to swap document. try again later.'
					);
				}
			} else {
				errorNotification(
					uploadedDocResponse?.message ??
						'Failed to swap document. try again later'
				);
			}
			setIsLoaded(true);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[uploadDocuments]
	);

	const deleteDoc = useCallback(
		async (id: string): Promise<void> => {
			await remove(`${API_URL.DOCUMENTS}/${id}`);
			return;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[remove, successNotification]
	);

	return {
		uploadDoc,
		deleteDoc,
		swapDoc,
		isLoaded,
	};
};

export const usePrepareOverlay = () => {
	const setEnvelopeDocs = useSetRecoilState(UploadedEnvelopeDocsState);
	const { get, patch } = useNetwork({ updateState: false });
	const setConfigDocTitle = useSetRecoilState(configDocTitleState);
	const setRecipients = useSetRecoilState(RecipientsState);
	const setDocuments = useSetRecoilState(ConfigDocumentState);
	const setTemplateType = useSetRecoilState(templateTypeState);
	const setOrganization = useSetRecoilState(organizationDetailState);
	const setLocalRecipients = useSetRecoilState(RecipientLocalState);
	const setTemplateSource = useSetRecoilState(TemplateSourceState)
	const { multiDocHandler } = useSetConfigDoc();
	const  setIsMerchantFlow = useSetRecoilState(IsEnableMerchantFlow);
	const setSignOrder = useSetRecoilState(isSigningOrderEnabledState);
	const { errorNotification } = useNotification();

	const fetchOverlayTemplate = useCallback(
		async (templateId: string) => {
			setDocuments((prev) => ({
				...prev,
				isLoaded: false,
				isLoading: true,
			}));
			// fetch overlay template
			const resp = await get(`${API_URL.TEMPLATE}/${templateId}`);
			const { apiData, response } = resp;
			if (response?.status === 200) {
				const {
					recipients,
					documents,
					name = 'Untitled',
					createdAt,
					type,
					source,
					whiteLabelInfo,
					reviewSign,
					signOrder,					
				} = apiData.data;
				setConfigDocTitle({ name, createdAt });
				const allRecipients = recipients.map((recipient: IRecipient) => ({
					...recipient,
					color: recipient.colorCode,
				}));
				setIsMerchantFlow(reviewSign);
				setSignOrder(signOrder);
				setTemplateSource(source);
				setRecipients(allRecipients);
				const { multiDocDataArray, envelopeList } = multiDocHandler(documents);
				setDocuments((prev) => ({
					...prev,
					isLoaded: true,
					isLoading: false,
					data: [...multiDocDataArray],
				}));
				const firstEmptyForm = {
					...newRecipient,
					color: getColor(),
					id: 'temp' + v4(),
				};
				// set white label data
				if (whiteLabelInfo?.whitelabel) {
					setOrganization(whiteLabelInfo);
				}
				setTemplateType(type as ITemplateType);
				setEnvelopeDocs({ isLoading: false, data: envelopeList });
				setLocalRecipients(allRecipients.length ? allRecipients : [firstEmptyForm]);
				return;
			}
			setDocuments((prev) => ({
				...prev,
				isLoaded: true,
				isLoading: false,
			}));
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[ multiDocHandler]
	);

	const patchOverlaytemplate = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async (payload: any, templateId: string) => {
			const resp = await patch(`${API_URL.TEMPLATE}/${templateId}`, payload);
			const { apiData, response } = resp;
			const { recipients } = apiData.data;
			const allRecipients = recipients.map((recipient: IRecipient) => ({
				...recipient,
				color: recipient?.colorCode,
				id:recipient?._id
			}));
			setRecipients(allRecipients);
			setLocalRecipients(allRecipients);
			if (response?.status === 200) {
				return true
			} else {
				errorNotification(apiData?.message ?? "Failed to start template configuration")
				return false
			}
		},
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return { fetchOverlayTemplate, patchOverlaytemplate };
};
