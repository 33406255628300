import { BrowseFile, UploadedFiles } from "views";

export const UploadDocuments = () => {
  return (
    <>
      <div className="upload-documents">
        <div className="upload-documents__header">Add documents</div>
        <BrowseFile />
        <UploadedFiles />
      </div>
    </>
  );
};
