import type { ITemplateSource } from 'types';
import type { IEnvelopeState, IPrepareType, ITemplateType, IWorkflow } from './types';

import { IActiveTab } from 'components';
import { TEMPLATE_SOURCE } from 'constant';
import { atom } from 'recoil';

export const UploadedEnvelopeDocsState = atom<IEnvelopeState>({
	key: 'uploaded-envelope-docs-state-key',
	default: {
		isLoading: false,
		data: [],
	},
});

export const templateTypeState = atom<ITemplateType>({
	key: 'overlay-state-key',
	default: 'basic',
});

export const TemplateSourceState = atom<ITemplateSource>({
	key: "template-source-state-key",
	default: TEMPLATE_SOURCE.DEFAULT
})

export const prepareTypeState = atom<IPrepareType>({
	key: 'prepare-type-state-key',
	default: 'envelope',
});


export const IsDraggingEnabled = atom({
	key: 'dragging-enabled-state-key',
	default: true,
});

export const newUploadFile = atom<string[]>({
	key: 'new-upload-file',
	default: [],
})

// Paras: add state for undo-modal
export const IsUndoModal = atom<boolean>({
	key: 'is-undo-modal',
	default: false,
})

export const ActiveRecipientTabState = atom<IActiveTab>({
	key: "active-recipient-tab-state-key",
	default: "workflow",
});


// new master state for prepare dashboard for refactoring as old will have to remove parallel
export const PrepareDashboardState = atom<Partial<IWorkflow>>({
	key: "prepare-dashboard-state-key",
	default: {}
})