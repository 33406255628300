// TODO: @Manish PLease remove any type from here
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertFileToBase64 = (file: any) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;
	});
};

export const formatBase64 = (base64String: string) => {
	return base64String.replace(/^data:[^;]+;base64,/, '');
};

export const b64ImageFromString = (base64String: string) => {
	return `data:image/png;base64,${base64String}`;
};

export const checkBase64ValidImage = (base64String: string) => {
	return base64String.startsWith('data:image');
}

/**
 * append data:image/png;base64, if the string passed does not start with data:image
 * @param image image string that needs to be checked and appended
 * @returns base64 image string
 */
export const checkAndAppendBase64Property = (image: string) => {
	if (checkBase64ValidImage(image)) {
		return image
	}
	return b64ImageFromString(image);
}