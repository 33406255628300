import { useCallback } from 'react';

import { useNetwork, useNotification } from 'hooks';
import { API_URL } from 'constant';
import { useSetRecoilState } from 'recoil';

import {
	KYCPrepareUrl,
	KYCPrepareUrlLoadingState,
	UserKYCModalState,
} from './state';

/**
 * Custom hook for User KYC functionalities
 * */
export const useUserKyc = () => {
	/**
	 * Hooks for network requests, state management, and notifications
	 * */
	const { post, get } = useNetwork({ updateState: false });
	const setIsLoaded = useSetRecoilState(KYCPrepareUrlLoadingState);
	const setOpenKycModal = useSetRecoilState(UserKYCModalState);
	const { successNotification } = useNotification();

	/**
	 * Recoil state setter for KYC URL preparation
	 * */
	const setPrepareUrl = useSetRecoilState(KYCPrepareUrl);

	/**
	 * Function to fetch KYC URL for a user
	 * */
	const fetchKycUrl = useCallback(
		async (payload: { recipientId: string; envelopeId: string }) => {
			setOpenKycModal(true); /**Show KYC modal*/

			setIsLoaded(false); /**Set loading state*/

			const resp = await post(
				API_URL.SESSIONS,
				payload
			); /**POST request to fetch KYC session*/
			const { apiData, response } = resp ?? {};
			if (response?.status === 200) {
				const { link } = apiData.data;
				if (link === '') {
					successNotification(
						'Kyc session fetched successfully, please go ahead with document signing'
					);

					setOpenKycModal(false); /**Hide KYC modal if no link is provided*/
				} else {
					setPrepareUrl(apiData.data); /**Set prepared URL for KYC*/
				}
			}

			setIsLoaded(true); /**Set loading state after completion*/
		},
		[post, setIsLoaded, setOpenKycModal, setPrepareUrl, successNotification]
	);

	/**
	 * Function to periodically check KYC session status
	 * */
	const fetchKycSessionStatusInterval = useCallback(
		async (
			nodeId: string,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			interval: any /**Reference to interval for clearing later*/,
			successCb: () => void,
			errorCb: () => void,
			reStartCb: () => void
		): Promise<void> => {
			const resp = await get(
				`${API_URL.SESSIONS}?nodeId=${nodeId}`
			); /**GET request to check session status*/
			const { response, apiData } = resp;
			if (response?.status === 200) {
				const { sessionStatus, stepStatus } = apiData?.data?.[0] ?? {};
				if (sessionStatus === 'completed' && stepStatus === 'completed') {
					successCb(); /**Callback on successful completion*/

					clearInterval(interval); /**Clear interval on completion*/
				} else if (sessionStatus === 'completed' && stepStatus === 'rejected') {
					clearInterval(interval); /**Clear interval if rejected*/

					reStartCb(); /**Callback to restart process*/
				}
			} else {
				errorCb(); /** Callback on error*/

				clearInterval(interval); /**Clear interval on error*/
			}
		},
		[get]
	);

	return { fetchKycUrl, fetchKycSessionStatusInterval };
};
