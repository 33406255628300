import { useCallback, useEffect, useMemo } from 'react';

import { useRecoilValue } from 'recoil';
import { GifLoader } from '@storybook';
import {
	Header,
	PdfSigner,
	IsShowTandCState,
	TermsCondition,
	useSetSignDoc,
	SignDocSelector,
	ServerError,
	docStatus,
	SuccessScreen,
	SignWebComponentDataState,
	VoidScreen,
	IAuthUserAuthType,
	UserKyc,
	useUserKyc,
	UserKYCFailed,
} from 'views';
import { GetInitials, GetQueryParams } from 'utils';
import { useNetwork } from 'hooks';
import { FacialBiometricModal } from 'views/facial-biometric/facial-biometric';
import { UnderReviewScreen } from 'views/under-review';
import { IsFacialModalOpen } from 'views/facial-biometric/store';
import { RECIPIENT_STATUS } from 'constant';

import {Footer} from "./components";

export const SigningDashboard = ({
	envelopeId,
	recipientId,
}: {
	envelopeId?: string;
	recipientId?: string;
}) => {
	const isToggle = useRecoilValue(IsShowTandCState);
	const { signDocLoaded, signDocError, recipient, status, envelopeId: signingEnvelopeId } =
		useRecoilValue(SignDocSelector);
	const authUserAuthType = useRecoilValue(IAuthUserAuthType);
	const isModalOpen = useRecoilValue(IsFacialModalOpen);
	const userKycFailed = useRecoilValue(UserKYCFailed);
	const { signStatus, isWorkFlow } = useRecoilValue(SignWebComponentDataState);

	const { apiHostExists } = useNetwork();
	const { fetchSignDoc, fetchSignFonts } = useSetSignDoc(
		envelopeId,
		recipientId
	);
	const {fetchKycUrl} = useUserKyc();
	const { fullName, action, status: recipientSignStatus } = recipient;
	const currentRecipientId = recipientId || GetQueryParams('recipient');

	const fetchSigningServices = useCallback(async() => {
		const signingEnvelope = await fetchSignDoc();
		const {notary, _id, } = signingEnvelope ?? {}
		if (notary) {
			const payload = {
				envelopeId: _id as string,
				recipientId: currentRecipientId as string
			}
			fetchKycUrl(payload)
		}
		
	},[currentRecipientId, fetchKycUrl, fetchSignDoc])

	useEffect(() => {
		if (apiHostExists) {
			fetchSigningServices()
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiHostExists]);

	useEffect(() => {
		if (fullName) {
			fetchSignFonts(fullName, GetInitials(fullName));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fullName]);

	const signingDashboardWrapper = useMemo(() => {
		return (
			<div className="signing-dashboard-wrapper">
				<Header />
				<PdfSigner />
				<Footer />
			</div>
		);
	}, []);

	const showConcentScreen = useMemo((): boolean => {
		const isSigner = !/^Receives a copy$/.test(action);
		const isSigningComplete = /complete/gi.test(recipientSignStatus);
		if (isToggle && !isSigningComplete && isSigner) {
			return true
		}
		return false
	},[action, isToggle, recipientSignStatus])

	const renderSignScreen = useMemo(() => {
		if (!signDocLoaded) {
			return (
				<div className="Esign-signing-dashboard__loader">
					<GifLoader />
				</div>
			);
		}
		if (signDocError || userKycFailed) {
			return <ServerError />;
		}
		if (status === "voided") {
			return <VoidScreen />
		}
		if (recipientSignStatus === RECIPIENT_STATUS.UNDERREVIEW) {
			return <UnderReviewScreen/>
		}
		if (status == docStatus[1] || signStatus) {
			return (
				<SuccessScreen
					message={'Document signing completed'}
					description="You will receive a copy once everyone has signed."
					showLoader={!isWorkFlow}
				/>
			);
		}
		return (
			<div className="esign-signing-screen__container">
				<UserKyc recipientId={currentRecipientId ?? ""} envelopeId={signingEnvelopeId ?? ""} />
				{showConcentScreen && (
					<div className="esign-signing-screen__tnc-container">
						<TermsCondition />
					</div>
				)}
				<div className="esign-signing-screen__dashboard-wrapper-container">
					{signingDashboardWrapper}
				</div>

				{authUserAuthType === 'facial' && isModalOpen && <FacialBiometricModal />}
			</div>
		);
	}, [signDocLoaded, signDocError, userKycFailed, status, recipientSignStatus, signStatus, currentRecipientId, signingEnvelopeId, showConcentScreen, signingDashboardWrapper, authUserAuthType, isModalOpen, isWorkFlow]);

	return <>{renderSignScreen}</>;
};
