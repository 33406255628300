import { useEffect, useMemo, useState } from "react";

import { typeWindowSize, DeviceType } from "./";

export const useDeviceType = () => {
  const [windowSize, setWindowSize] = useState<typeWindowSize>({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  const deviceType = useMemo(() => {
    if (windowSize.width && windowSize.width <= 450) {
      return DeviceType.Mobile;
    } else if (windowSize.width && windowSize.width <= 1024) {
      return DeviceType.Tablet;
    } else if (windowSize.width && windowSize.width > 1024) {
      return DeviceType.Desktop;
    }
    return;
  }, [windowSize]);
  return { windowSize, deviceType };
};
