import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import { useConnection } from 'hooks';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BackendFactory } from 'dnd-core';

import { AllRoutes, ServerDisconnected } from './views';

import 'react-tooltip/dist/react-tooltip.css';
import 'react-toastify/dist/ReactToastify.css';
import 'remixicon/fonts/remixicon.css';
import './index.scss';
import './app.scss';

interface IApp {
	url: string;
	env: string;
	submit_esign: (success: boolean, purpose: string) => void;
	hide_user: boolean;
	hide_logo: boolean;
}

declare const window: Window &
    typeof globalThis & {
		__instance: BackendFactory | null
    }

export const getHTML5Backend = () => {
	if (!window.__instance) {
		window.__instance = HTML5Backend;
	}
	return window.__instance;
};

const App = ({ url, env, submit_esign, hide_user, hide_logo }: IApp) => {
	const { IsServerConnected } = useConnection();

	const props = {
		url,
		env,
		submit_esign,
		hide_user,
		hide_logo
	};

	return (
		<RecoilRoot>
			<BrowserRouter>
				<DndProvider backend={getHTML5Backend()} context={window}>
					<div className="esign-app">
						{IsServerConnected ? (
							<AllRoutes {...props} />
						) : (
							<ServerDisconnected />
						)}
					</div>
				</DndProvider>

				<ToastContainer
					position="top-center"
					autoClose={2000}
					style={{ zIndex: 2765347625347623 }}
					hideProgressBar
					closeOnClick
					pauseOnHover
					transition={Slide}
					theme="dark"
					limit={1}
				/>
			</BrowserRouter>
		</RecoilRoot>
	);
};

export default App;
