import { FC, useEffect, useMemo, useRef } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';
import { XYCoord, useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { DragTypes } from 'views/editor-dashboard';
import {
	AddRecipientForm,
	IRecipientUser,
	IsDraggingEnabled,
	prepareDashboardSelector,
	prepareTypeState,
	templateTypeState,
} from 'views';
import { EnvelopePurposeState } from 'states';
import { ENVELOPE_PURPOSE } from 'constant';

export interface CardProps {
	id: string;
	recipient: IRecipientUser;
	index: number;
	moveRecipients: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
	index: number;
	id: string;
	type: string;
}
export const RecipientsDraggable: FC<CardProps> = ({
	id,
	recipient,
	index,
	moveRecipients,
}) => {
	const ref = useRef<HTMLDivElement>(null);
	const prepareType = useRecoilValue(prepareTypeState);
	const templateType = useRecoilValue(templateTypeState);
	const [isDraggingEnabled, setIsDraggingEnabled] = useRecoilState(IsDraggingEnabled);
	const purpose = useRecoilValue(EnvelopePurposeState);
	
	const { isEditable } = useRecoilValue(prepareDashboardSelector);

	const [, drop] = useDrop<DragItem>({
		accept: DragTypes.Recipient,
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		hover(item: DragItem, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}

			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();

			// Get vertical middle
			const hoverMiddleY =
				(hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();

			// Get pixels to the top
			const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%

			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}

			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			// Time to actually perform the action
			moveRecipients(dragIndex, hoverIndex);

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex;
		},
	});

	const [{ isDragging }, drag, previewRef] = useDrag({
		type: DragTypes.Recipient,
		item: () => {
			return { id, index, recipient };
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
		canDrag: () => isDraggingEnabled
	});

	/**
	 * This checks if the particular recipient is editable or not
	 * */ 
	const RecipientEditable = useMemo(() => {
		return recipient.status === "completed" && isEditable
	},[isEditable, recipient])

	useEffect(() => {
		previewRef(getEmptyImage());
	}, [previewRef]);

	if (!(prepareType === 'envelope' && templateType === 'overlay' && purpose !== ENVELOPE_PURPOSE.MULTISIGNAGREEMENT)) {
		drag(drop(ref));
	}

	return (
		<div
			ref={ref}
			style={
				RecipientEditable
					? { opacity: 0.5, pointerEvents: 'none' }
					: { opacity: isDragging ? 0 : 1, cursor: '-webkit-grab' }
			}
			onMouseOver={(e) => {
				// Using any because i have to get the dragging target tagname
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				if (/input/gi.test((e.target as any).tagName)) {
					setIsDraggingEnabled(false);
					return;
				}
				setIsDraggingEnabled(true);
			}}
		>
			<div className="add-recipient__recipient-list_draggable">
				<AddRecipientForm recipient={recipient} key={index} index={index} />
			</div>
		</div>
	);
};
